@charset "UTF-8";
:root {
  --bs-blue: #5a8dee;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #ff5b5c;
  --bs-orange: #fd7e14;
  --bs-yellow: #fdac41;
  --bs-green: #39da8a;
  --bs-teal: #20c997;
  --bs-cyan: #00cfdd;
  --bs-white: #fff;
  --bs-gray: rgba(0, 0, 0, 0.1);
  --bs-gray-dark: rgba(0, 0, 0, 0.45);
  --bs-gray-25: rgba(255, 255, 255, 0.015);
  --bs-gray-50: rgba(255, 255, 255, 0.03);
  --bs-primary: #5a8dee;
  --bs-secondary: #69809a;
  --bs-success: #39da8a;
  --bs-info: #00cfdd;
  --bs-warning: #fdac41;
  --bs-danger: #ff5b5c;
  --bs-light: #434c5f;
  --bs-dark: #717784;
  --bs-gray: rgba(255, 255, 255, 0.75);
  --bs-primary-rgb: 90, 141, 238;
  --bs-secondary-rgb: 105, 128, 154;
  --bs-success-rgb: 57, 218, 138;
  --bs-info-rgb: 0, 207, 221;
  --bs-warning-rgb: 253, 172, 65;
  --bs-danger-rgb: 255, 91, 92;
  --bs-light-rgb: 67, 76, 95;
  --bs-dark-rgb: 113, 119, 132;
  --bs-gray-rgb: 255, 255, 255;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 161, 176, 203;
  --bs-body-bg-rgb: 28, 34, 47;
  --bs-font-sans-serif: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --bs-font-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-root-font-size: 16px;
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.9375rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.47;
  --bs-body-color: #a1b0cb;
  --bs-body-bg: #1c222f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--bs-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: #36445d;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Rubik", "Times New Roman", serif;
  font-weight: 500;
  line-height: 1.1;
  color: #d8deea;
}

h1, .h1 {
  font-size: calc(1.3625rem + 1.35vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.375rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.2875rem + 0.45vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.625rem;
  }
}

h4, .h4 {
  font-size: calc(1.2625rem + 0.15vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.375rem;
  }
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 0.9375rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 900;
}

small, .small {
  font-size: 85%;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #5a8dee;
  text-decoration: none;
}
a:hover {
  color: #517fd6;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 85%;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 85%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 85%;
  color: #717784;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0.1875rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  color: #8295ba;
  text-align: left;
}

th {
  font-weight: 600;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.0546875rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 500;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 500;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 500;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 500;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 500;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 500;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 85%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.171875rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 85%;
  color: rgba(0, 0, 0, 0.1);
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0;
  background-color: transparent;
  border: 0px solid rgba(255, 255, 255, 0.4);
  border-radius: 0px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 85%;
  color: #8295ba;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1.875rem);
  padding-left: var(--bs-gutter-x, 1.875rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1440px;
  }
}
.row {
  --bs-gutter-x: 1.625rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #a1b0cb;
  --bs-table-striped-bg: rgba(99, 123, 169, 0.06);
  --bs-table-active-color: #a1b0cb;
  --bs-table-active-bg: rgba(99, 123, 169, 0.08);
  --bs-table-hover-color: #a1b0cb;
  --bs-table-hover-bg: rgba(99, 123, 169, 0.08);
  width: 100%;
  margin-bottom: 1rem;
  color: #a1b0cb;
  vertical-align: middle;
  border-color: #36445d;
}
.table > :not(caption) > * > * {
  padding: 0.625rem 1.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid #36445d;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.3125rem 0.625rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #dee8fc;
  --bs-table-striped-bg: #d1daed;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ccd5e8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ccd5e8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b2baca;
}

.table-secondary {
  --bs-table-bg: #e1e6eb;
  --bs-table-striped-bg: #d4d8dd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cfd4d8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cfd4d8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b4b8bc;
}

.table-success {
  --bs-table-bg: #d7f8e8;
  --bs-table-striped-bg: #cae9da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c6e4d5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6e4d5;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #acc6ba;
}

.table-info {
  --bs-table-bg: #ccf5f8;
  --bs-table-striped-bg: #c0e6e9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bce1e4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bce1e4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #a3c4c6;
}

.table-warning {
  --bs-table-bg: #ffeed9;
  --bs-table-striped-bg: #f0e0cc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ebdbc8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebdbc8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #ccbeae;
}

.table-danger {
  --bs-table-bg: #ffdede;
  --bs-table-striped-bg: #f0d1d1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ebcccc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebcccc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #ccb2b2;
}

.table-light {
  --bs-table-bg: #717784;
  --bs-table-striped-bg: #7a7f8b;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #7c828e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #7c828e;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #8d929d;
}

.table-dark {
  --bs-table-bg: #fff;
  --bs-table-striped-bg: #f0f0f0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ebebeb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebebeb;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cccccc;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  font-weight: 400;
}

.col-form-label {
  padding-top: calc(0.469rem + 1px);
  padding-bottom: calc(0.469rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 400;
  line-height: 1.4;
}

.col-form-label-lg {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  font-size: 1.063rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.813rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 85%;
  color: #637ba9;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.469rem 0.735rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.4;
  color: #a1b0cb;
  background-color: #283144;
  background-clip: padding-box;
  border: 1px solid #546990;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #a1b0cb;
  background-color: #283144;
  border-color: white;
  outline: 0;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(99, 123, 169, 0.18);
}
.form-control::-webkit-date-and-time-value {
  height: 1.4em;
}
.form-control::placeholder {
  color: #8295ba;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #36445d;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.469rem 0.735rem;
  margin: -0.469rem -0.735rem;
  margin-inline-end: 0.735rem;
  color: #a1b0cb;
  background-color: #283144;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #262f41;
}
.form-control::-webkit-file-upload-button {
  padding: 0.469rem 0.735rem;
  margin: -0.469rem -0.735rem;
  margin-inline-end: 0.735rem;
  color: #a1b0cb;
  background-color: #283144;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #262f41;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.469rem 0;
  margin-bottom: 0;
  line-height: 1.4;
  color: #a1b0cb;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.4em + 0.5rem + 2px);
  padding: 0.25rem 0.6rem;
  font-size: 0.813rem;
  border-radius: 0.1875rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.6rem;
  margin: -0.25rem -0.6rem;
  margin-inline-end: 0.6rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.6rem;
  margin: -0.25rem -0.6rem;
  margin-inline-end: 0.6rem;
}

.form-control-lg {
  min-height: calc(1.4em + 1.5rem + 2px);
  padding: 0.75rem 0.85rem;
  font-size: 1.063rem;
  border-radius: 0.3125rem;
}
.form-control-lg::file-selector-button {
  padding: 0.75rem 0.85rem;
  margin: -0.75rem -0.85rem;
  margin-inline-end: 0.85rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.75rem 0.85rem;
  margin: -0.75rem -0.85rem;
  margin-inline-end: 0.85rem;
}

textarea.form-control {
  min-height: calc(1.4em + 0.938rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.4em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.4em + 1.5rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.469rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.4em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.4em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.469rem 1.735rem 0.469rem 0.735rem;
  -moz-padding-start: calc(0.735rem - 3px);
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.4;
  color: #a1b0cb;
  background-color: #283144;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23a1b0cb' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.735rem center;
  background-size: 17px 12px;
  border: 1px solid #546990;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: white;
  outline: 0;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(99, 123, 169, 0.18);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.735rem;
  background-image: none;
}
.form-select:disabled {
  color: #a1b0cb;
  background-color: #36445d;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #a1b0cb;
}

.form-select-sm {
  padding-top: 0.187rem;
  padding-bottom: 0.187rem;
  padding-left: 0.6rem;
  font-size: 0.813rem;
  border-radius: 0.1875rem;
}

.form-select-lg {
  padding-top: 0.74rem;
  padding-bottom: 0.74rem;
  padding-left: 0.85rem;
  font-size: 1.063rem;
  border-radius: 0.3125rem;
}

.form-check {
  display: block;
  min-height: 1.378125rem;
  padding-left: 1.7em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.7em;
}

.form-check-input {
  width: 1.2em;
  height: 1.2em;
  margin-top: 0.135em;
  vertical-align: top;
  background-color: #283144;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #546990;
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: white;
  outline: 0;
  box-shadow: 0 0 0 0.12rem rgba(99, 123, 169, 0.3);
}
.form-check-input:checked {
  background-color: #fff;
  border-color: #fff;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='1.5' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #fff;
  border-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23546990'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 8px 0px rgba(255, 255, 255, 0.6);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 8px 0px rgba(255, 255, 255, 0.6);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 0.875rem;
  height: 0.875rem;
  margin-top: -0.25rem;
  background-color: #c1cadd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c1cadd;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.375rem;
  color: transparent;
  cursor: pointer;
  background-color: #36445d;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 0.875rem;
  height: 0.875rem;
  background-color: #c1cadd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c1cadd;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.375rem;
  color: transparent;
  cursor: pointer;
  background-color: #36445d;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #596f98;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #596f98;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.735rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.735rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.75;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.75;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.469rem 0.735rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.4;
  color: #a1b0cb;
  text-align: center;
  white-space: nowrap;
  background-color: #283144;
  border: 1px solid #546990;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.75rem 0.85rem;
  font-size: 1.063rem;
  border-radius: 0.3125rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.6rem;
  font-size: 0.813rem;
  border-radius: 0.1875rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 2.47rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.4;
  color: #a1b0cb;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.469rem 1.375rem;
  font-size: 0.9375rem;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #a1b0cb;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: none;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-link {
  font-weight: 400;
  color: #5a8dee;
  text-decoration: none;
}
.btn-link:hover {
  color: #517fd6;
}
.btn-link:disabled, .btn-link.disabled {
  color: rgba(0, 0, 0, 0.1);
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.74rem 2.1875rem;
  font-size: 1.063rem;
  border-radius: 0.3125rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.187rem 1rem;
  font-size: 0.813rem;
  border-radius: 0.1875rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.75em;
  vertical-align: middle;
  content: "";
  margin-top: -0.45em;
  width: 0.45em;
  height: 0.45em;
  border: 1.5px solid;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.3125rem 0;
  margin: 0;
  font-size: 0.9375rem;
  color: #a1b0cb;
  text-align: left;
  list-style: none;
  background-color: #283144;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0.3125rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.75em;
  vertical-align: middle;
  content: "";
  margin-top: 0;
  width: 0.45em;
  height: 0.45em;
  border: 1.5px solid;
  border-bottom: 0;
  border-left: 0;
  transform: rotate(-45deg);
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.75em;
  vertical-align: middle;
  content: "";
  border-top: 0.45em solid transparent;
  border-right: 0;
  border-bottom: 0.45em solid transparent;
  border-left: 0.45em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.75em;
  vertical-align: middle;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.75em;
  vertical-align: middle;
  content: "";
  border-top: 0.45em solid transparent;
  border-right: 0.45em solid;
  border-bottom: 0.45em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.3125rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #a1b0cb;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #a1b0cb;
  background-color: rgba(99, 123, 169, 0.08);
}
.dropdown-item.active, .dropdown-item:active {
  color: #5a8dee;
  text-decoration: none;
  background-color: rgba(90, 141, 238, 0.08);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #8295ba;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.3125rem 1rem;
  margin-bottom: 0;
  font-size: 0.813rem;
  color: #d8deea;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1rem;
  color: #a1b0cb;
}

.dropdown-menu-dark {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.45);
  border-color: transparent;
}
.dropdown-menu-dark .dropdown-item {
  color: rgba(255, 255, 255, 0.4);
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #5a8dee;
  background-color: rgba(90, 141, 238, 0.08);
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #637ba9;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(255, 255, 255, 0.09);
}
.dropdown-menu-dark .dropdown-item-text {
  color: rgba(255, 255, 255, 0.4);
}
.dropdown-menu-dark .dropdown-header {
  color: #637ba9;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.03125rem;
  padding-left: 1.03125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.640625rem;
  padding-left: 1.640625rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.469rem 1.375rem;
  color: #d8deea;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #517fd6;
}
.nav-link.disabled {
  color: #8295ba;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #36445d;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #36445d;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #8295ba;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #d8deea;
  background-color: #283144;
  border-color: #36445d;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: transparent;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3767575rem;
  padding-bottom: 0.3767575rem;
  margin-right: 1rem;
  font-size: 1.063rem;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.469rem;
  padding-bottom: 0.469rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.5rem 0.7rem;
  font-size: 0.625rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.0625rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: #4e5155;
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #4e5155;
}
.navbar-light .navbar-nav .nav-link {
  color: #637ba9;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #4e5155;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.2);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #4e5155;
}
.navbar-light .navbar-toggler {
  color: #637ba9;
  border-color: rgba(0, 0, 0, 0.06);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23637ba9' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: #637ba9;
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #4e5155;
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.4);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.8%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.8);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #283144;
  background-clip: border-box;
  border: 0 solid #36445d;
  border-radius: 0.3125rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.375rem 1.375rem;
}

.card-title {
  margin-bottom: 1.375rem;
}

.card-subtitle {
  margin-top: -0.6875rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1.375rem;
}

.card-header {
  padding: 1.375rem 1.375rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid #36445d;
}
.card-header:first-child {
  border-radius: 0.3125rem 0.3125rem 0 0;
}

.card-footer {
  padding: 1.375rem 1.375rem;
  background-color: transparent;
  border-top: 0 solid #36445d;
}
.card-footer:last-child {
  border-radius: 0 0 0.3125rem 0.3125rem;
}

.card-header-tabs {
  margin-right: -0.6875rem;
  margin-bottom: -1.375rem;
  margin-left: -0.6875rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.6875rem;
  margin-left: -0.6875rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.5rem;
  border-radius: 0.3125rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.card-group > .card {
  margin-bottom: 0.8125rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.78rem 1.1rem;
  font-size: 0.9375rem;
  color: #d8deea;
  text-align: left;
  background-color: #283144;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: all 0.2s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #d8deea;
  background-color: #283144;
  box-shadow: inset 0 0 0 #36445d;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%23d8deea' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
  transform: rotate(90deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 0.75rem;
  height: 0.75rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%23d8deea' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 0.75rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: white;
  outline: 0;
  box-shadow: none;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #283144;
  border: 0 solid #36445d;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.accordion-body {
  padding: 0.78rem 1.1rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #637ba9;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "\\") */;
}
.breadcrumb-item.active {
  color: #a1b0cb;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #a1b0cb;
  background-color: rgba(99, 123, 169, 0.08);
  border: 0px solid rgba(255, 255, 255, 0.09);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #a1b0cb;
  background-color: rgba(99, 123, 169, 0.16);
  border-color: rgba(255, 255, 255, 0.4);
}
.page-link:focus {
  z-index: 3;
  color: #a1b0cb;
  background-color: rgba(99, 123, 169, 0.16);
  outline: 0;
  box-shadow: none;
}

.page-item:not(:first-child) .page-link {
  margin-left: 0px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
.page-item.disabled .page-link {
  color: #8295ba;
  pointer-events: none;
  background-color: rgba(99, 123, 169, 0.06);
  border-color: rgba(255, 255, 255, 0.4);
}

.page-link {
  padding: 0.535rem 0.3125rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.66rem 0.5rem;
  font-size: 1.063rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.pagination-sm .page-link {
  padding: 0.41rem 0.25rem;
  font-size: 0.813rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.badge {
  display: inline-block;
  padding: 0.444em 0.75em;
  font-size: 0.813em;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.1875rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.9375rem 1.25rem;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.75rem;
  }
}
.progress {
  display: flex;
  height: 0.75rem;
  overflow: hidden;
  font-size: 0.625rem;
  background-color: #36445d;
  border-radius: 10rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #5a8dee;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.75rem 0.75rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.3125rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #fff;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #a1b0cb;
  text-decoration: none;
  background-color: rgba(99, 123, 169, 0.08);
}
.list-group-item-action:active {
  color: #a1b0cb;
  background-color: rgba(255, 255, 255, 0.03);
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.625rem 1rem;
  color: #a1b0cb;
  background-color: transparent;
  border: 1px solid #36445d;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #8295ba;
  pointer-events: none;
  background-color: transparent;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.3125rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.3125rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.btn-close {
  box-sizing: content-box;
  width: 0.8em;
  height: 0.8em;
  padding: 0.25em 0.25em;
  color: #8295ba;
  background: transparent url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate%28-225.000000, -250.000000%29'%3E%3Cg id='Icon-Color' transform='translate%28225.000000, 250.500000%29'%3E%3Cuse fill='%238295ba' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%238295ba' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") center/0.8em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.95;
}
.btn-close:hover {
  color: #8295ba;
  text-decoration: none;
  opacity: 0.95;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 0.95;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 22.5rem;
  max-width: 100%;
  font-size: 0.9375rem;
  color: #a1b0cb;
  pointer-events: auto;
  background-color: rgba(40, 49, 68, 0.85);
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
  border-radius: 0.3125rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 1rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.625rem 1rem;
  color: #a1b0cb;
  background-color: #283144;
  background-clip: padding-box;
  border-bottom: 0px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.3125rem - 0px);
  border-top-right-radius: calc(0.3125rem - 0px);
}
.toast-header .btn-close {
  margin-right: -0.5rem;
  margin-left: 1rem;
}

.toast-body {
  padding: 1rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1090;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 1.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.15s ease-out;
  transform: translateY(-100px) scale(0.8);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: translateY(0) scale(1);
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 3rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 3rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #283144;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1089;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1.375rem 1.5rem 0;
  border-bottom: 0px solid #36445d;
  border-top-left-radius: calc(0.3125rem - 0px);
  border-top-right-radius: calc(0.3125rem - 0px);
}
.modal-header .btn-close {
  padding: 0.6875rem 0.75rem;
  margin: -0.6875rem -0.75rem -0.6875rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.47;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 1.25rem;
  border-top: 0px solid #36445d;
  border-bottom-right-radius: calc(0.3125rem - 0px);
  border-bottom-left-radius: calc(0.3125rem - 0px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 35rem;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 22.5rem;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 50rem;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1099;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.47;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.9375rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #717784;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #717784;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #717784;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #717784;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.7rem;
  color: #fff;
  text-align: center;
  background-color: #717784;
  border-radius: 0.1875rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1091;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.47;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.9375rem;
  word-wrap: break-word;
  background-color: #283144;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 0px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #283144;
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 0px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #283144;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 0px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #283144;
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 0px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #283144;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 0px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #283144;
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 0px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #283144;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 0px solid transparent;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 0px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #283144;
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 0px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #283144;
}

.popover-header {
  padding: 0 1rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: #d8deea;
  background-color: transparent;
  border-bottom: 0px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3125rem - 0px);
  border-top-right-radius: calc(0.3125rem - 0px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #a1b0cb;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 1;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 1;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style='fill: %23fff;transform: ;msFilter:;'%3E%3Cpath d='M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z'%3E%3C/path%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style='fill: %23fff;transform: ;msFilter:;'%3E%3Cpath d='M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z'%3E%3C/path%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 14%;
  margin-bottom: 1rem;
  margin-left: 14%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1090;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #283144;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1089;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
}
.offcanvas-header .btn-close {
  padding: 0.75rem 0.75rem;
  margin-top: -0.75rem;
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.47;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1.5rem 1.5rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 0px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 0px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #5a8dee;
}
.link-primary:hover, .link-primary:focus {
  color: #517fd6;
}

.link-secondary {
  color: #69809a;
}
.link-secondary:hover, .link-secondary:focus {
  color: #5f738b;
}

.link-success {
  color: #39da8a;
}
.link-success:hover, .link-success:focus {
  color: #33c47c;
}

.link-info {
  color: #00cfdd;
}
.link-info:hover, .link-info:focus {
  color: #00bac7;
}

.link-warning {
  color: #fdac41;
}
.link-warning:hover, .link-warning:focus {
  color: #e49b3b;
}

.link-danger {
  color: #ff5b5c;
}
.link-danger:hover, .link-danger:focus {
  color: #e65253;
}

.link-light {
  color: #434c5f;
}
.link-light:hover, .link-light:focus {
  color: #3c4456;
}

.link-dark {
  color: #717784;
}
.link-dark:hover, .link-dark:focus {
  color: #666b77;
}

.link-gray {
  color: rgba(255, 255, 255, 0.75);
}
.link-gray:hover, .link-gray:focus {
  color: rgba(255, 255, 255, 0.775);
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 1;
}

:root {
  color-scheme: dark;
}

b,
strong {
  font-weight: 700;
}

[dir=rtl] caption {
  text-align: right;
}
[dir=rtl] dd {
  margin-right: 0;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-internal-autofill-selected {
  background-clip: text !important;
  -webkit-background-clip: text !important;
}

[dir=rtl] .list-inline,
[dir=rtl] .list-unstyled {
  padding-right: 0;
}
[dir=rtl] .list-inline-item:not(:last-child) {
  margin-right: 0;
  margin-left: 0.5rem;
}

.row-bordered {
  overflow: hidden;
}
.row-bordered > .col,
.row-bordered > [class^=col-],
.row-bordered > [class*=" col-"],
.row-bordered > [class^="col "],
.row-bordered > [class*=" col "],
.row-bordered > [class$=" col"],
.row-bordered > [class=col] {
  position: relative;
  padding-top: 1px;
}
.row-bordered > .col::before,
.row-bordered > [class^=col-]::before,
.row-bordered > [class*=" col-"]::before,
.row-bordered > [class^="col "]::before,
.row-bordered > [class*=" col "]::before,
.row-bordered > [class$=" col"]::before,
.row-bordered > [class=col]::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -1px;
  left: 0;
  display: block;
  height: 0;
  border-top: 1px solid #36445d;
}
.row-bordered > .col::after,
.row-bordered > [class^=col-]::after,
.row-bordered > [class*=" col-"]::after,
.row-bordered > [class^="col "]::after,
.row-bordered > [class*=" col "]::after,
.row-bordered > [class$=" col"]::after,
.row-bordered > [class=col]::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  display: block;
  width: 0;
  border-left: 1px solid #36445d;
}
.row-bordered.row-border-light > .col::before, .row-bordered.row-border-light > .col::after,
.row-bordered.row-border-light > [class^=col-]::before,
.row-bordered.row-border-light > [class^=col-]::after,
.row-bordered.row-border-light > [class*=" col-"]::before,
.row-bordered.row-border-light > [class*=" col-"]::after,
.row-bordered.row-border-light > [class^="col "]::before,
.row-bordered.row-border-light > [class^="col "]::after,
.row-bordered.row-border-light > [class*=" col "]::before,
.row-bordered.row-border-light > [class*=" col "]::after,
.row-bordered.row-border-light > [class$=" col"]::before,
.row-bordered.row-border-light > [class$=" col"]::after,
.row-bordered.row-border-light > [class=col]::before,
.row-bordered.row-border-light > [class=col]::after {
  border-color: rgba(255, 255, 255, 0.75);
}

[dir=rtl] .row-bordered > .col::after,
[dir=rtl] .row-bordered > [class^=col-]::after,
[dir=rtl] .row-bordered > [class*=" col-"]::after,
[dir=rtl] .row-bordered > [class^="col "]::after,
[dir=rtl] .row-bordered > [class*=" col "]::after,
[dir=rtl] .row-bordered > [class$=" col"]::after,
[dir=rtl] .row-bordered > [class=col]::after {
  left: auto;
  right: -1px;
}

.bg-label-secondary {
  background-color: #323e52 !important;
  color: #69809a !important;
}

.bg-label-success {
  background-color: #2b4c4f !important;
  color: #39da8a !important;
}

.bg-label-info {
  background-color: #224a5c !important;
  color: #00cfdd !important;
}

.bg-label-warning {
  background-color: #4a4544 !important;
  color: #fdac41 !important;
}

.bg-label-danger {
  background-color: #4a3848 !important;
  color: #ff5b5c !important;
}

.bg-label-light {
  background-color: #2c3548 !important;
  color: #434c5f !important;
}

.bg-label-dark {
  background-color: #343c4e !important;
  color: #717784 !important;
}

.bg-label-gray {
  background-color: rgba(62, 70, 87, 0.96) !important;
  color: rgba(255, 255, 255, 0.75) !important;
}

a.bg-dark:hover, a.bg-dark:focus {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

a.bg-light:hover, a.bg-light:focus {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

a.bg-lighter:hover, a.bg-lighter:focus {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

a.bg-lightest:hover, a.bg-lightest:focus {
  background-color: rgba(255, 255, 255, 0.03) !important;
}

.text-muted[href]:hover, .text-muted[href]:focus {
  color: #fff !important;
}

.text-light {
  color: #637ba9 !important;
}
.text-light[href]:hover, .text-light[href]:focus {
  color: #fff !important;
}

.text-lighter {
  color: #8295ba !important;
}
.text-lighter[href]:hover, .text-lighter[href]:focus {
  color: #fff !important;
}

.text-lightest {
  color: #a1b0cb !important;
}
.text-lightest[href]:hover, .text-lightest[href]:focus {
  color: #fff !important;
}

.invert-text-white {
  color: #1c222f !important;
}

.invert-text-white[href]:hover:hover, .invert-text-white[href]:hover:focus {
  color: #1c222f !important;
}

.invert-text-dark {
  color: #fff !important;
}

.invert-text-dark[href]:hover:hover, .invert-text-dark[href]:hover:focus {
  color: #fff !important;
}

.invert-bg-white {
  background-color: #1c222f !important;
}

a.invert-bg-white:hover, a.invert-bg-white:focus {
  background-color: #1c222f !important;
}

.invert-bg-dark {
  background-color: #fff !important;
}

a.invert-bg-dark:hover, a.invert-bg-dark:focus {
  background-color: #fff !important;
}

.invert-border-dark {
  border-color: #fff !important;
}

.invert-border-white {
  border-color: #1c222f !important;
}

.container-p-x {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
@media (min-width: 992px) {
  .container-p-x {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
}

.container-m-nx {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
@media (min-width: 992px) {
  .container-m-nx {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
}

.container-p-y:not([class^=pt-]):not([class*=" pt-"]) {
  padding-top: 1.875rem !important;
}
.container-p-y:not([class^=pb-]):not([class*=" pb-"]) {
  padding-bottom: 1.875rem !important;
}

.container-m-ny:not([class^=mt-]):not([class*=" mt-"]) {
  margin-top: -1.875rem !important;
}
.container-m-ny:not([class^=mb-]):not([class*=" mb-"]) {
  margin-bottom: -1.875rem !important;
}

.cell-fit {
  width: 0.1%;
  white-space: nowrap;
}

.table-secondary {
  --bs-table-bg: #e1e6eb;
  --bs-table-striped-bg: #d4d8dd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cfd4d8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cfd4d8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b4b8bc;
}
.table-secondary .btn-icon {
  color: #000;
}

.table-success {
  --bs-table-bg: #d7f8e8;
  --bs-table-striped-bg: #cae9da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c6e4d5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6e4d5;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #acc6ba;
}
.table-success .btn-icon {
  color: #000;
}

.table-info {
  --bs-table-bg: #ccf5f8;
  --bs-table-striped-bg: #c0e6e9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bce1e4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bce1e4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #a3c4c6;
}
.table-info .btn-icon {
  color: #000;
}

.table-warning {
  --bs-table-bg: #ffeed9;
  --bs-table-striped-bg: #f0e0cc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ebdbc8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebdbc8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #ccbeae;
}
.table-warning .btn-icon {
  color: #000;
}

.table-danger {
  --bs-table-bg: #ffdede;
  --bs-table-striped-bg: #f0d1d1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ebcccc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebcccc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #ccb2b2;
}
.table-danger .btn-icon {
  color: #000;
}

.table-light {
  --bs-table-bg: #717784;
  --bs-table-striped-bg: #7a7f8b;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #7c828e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #7c828e;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #8d929d;
}
.table-light .btn-icon {
  color: #fff;
}

.table-dark {
  --bs-table-bg: #fff;
  --bs-table-striped-bg: #f0f0f0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ebebeb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebebeb;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cccccc;
}
.table-dark .btn-icon {
  color: #000;
}

.card .table {
  margin-bottom: 0;
}

@supports (-moz-appearance: none) {
  .table .dropdown-menu.show {
    display: inline-table;
  }
}
.table th {
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  font-weight: bold;
}
.table:not(.table-dark) thead:not(.table-dark) th {
  color: #d8deea;
}

.table-border-bottom-0 tr:last-child td,
.table-border-bottom-0 tr:last-child th {
  border-bottom-width: 0;
}

.table.table-dark .btn.btn-icon {
  color: #36445d;
}

.table.table-flush-spacing thead tr > td:first-child,
.table.table-flush-spacing tbody tr > td:first-child {
  padding-left: 0;
}
.table.table-flush-spacing thead tr > td:last-child,
.table.table-flush-spacing tbody tr > td:last-child {
  padding-right: 0;
}

.nav-align-top .table:not(.table-dark),
.nav-align-top .table:not(.table-dark) thead:not(.table-dark) th,
.nav-align-top .table:not(.table-dark) tfoot:not(.table-dark) th,
.nav-align-top .table:not(.table-dark) td,
.nav-align-right .table:not(.table-dark),
.nav-align-right .table:not(.table-dark) thead:not(.table-dark) th,
.nav-align-right .table:not(.table-dark) tfoot:not(.table-dark) th,
.nav-align-right .table:not(.table-dark) td,
.nav-align-bottom .table:not(.table-dark),
.nav-align-bottom .table:not(.table-dark) thead:not(.table-dark) th,
.nav-align-bottom .table:not(.table-dark) tfoot:not(.table-dark) th,
.nav-align-bottom .table:not(.table-dark) td,
.nav-align-left .table:not(.table-dark),
.nav-align-left .table:not(.table-dark) thead:not(.table-dark) th,
.nav-align-left .table:not(.table-dark) tfoot:not(.table-dark) th,
.nav-align-left .table:not(.table-dark) td {
  border-color: rgba(255, 255, 255, 0.09);
}

.btn {
  cursor: pointer;
}
.btn.disabled, .btn:disabled {
  cursor: default;
}

.btn .badge {
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn .badge {
    transition: none;
  }
}

label.btn {
  margin-bottom: 0;
}

.btn-xl, .btn-group-xl > .btn {
  padding: 0.875rem 2.125rem;
  font-size: 1.25rem;
  border-radius: 0.375rem;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 0.1rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.125rem;
}

.btn-secondary {
  color: #fff;
  background-color: #69809a;
  border-color: #69809a;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-secondary:hover {
  color: #fff;
  background-color: #8799ae;
  border-color: #8799ae;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #596d83;
  border-color: #596d83;
  box-shadow: none;
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #596d83;
  border-color: #596d83;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #69809a;
  border-color: #69809a;
  box-shadow: none !important;
}

.btn-label-secondary {
  color: #69809a;
  border-color: transparent;
  background: #323e52;
}
.btn-label-secondary:hover {
  border-color: transparent;
  background: #69809a;
  color: #fff;
}
.btn-label-secondary:focus, .btn-label-secondary.focus {
  color: #fff;
  background: #596d83;
  box-shadow: none !important;
}
.btn-label-secondary:active, .btn-label-secondary.active, .show > .btn-label-secondary.dropdown-toggle {
  color: #fff;
  background-color: #596d83;
  border-color: transparent;
}
.btn-label-secondary:active:focus, .btn-label-secondary.active:focus, .show > .btn-label-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-secondary.disabled, .btn-label-secondary:disabled {
  color: #69809a;
  border-color: transparent;
  background: #323e52;
  box-shadow: none;
}

.btn-outline-secondary {
  color: #69809a;
  border-color: #69809a;
  background: transparent;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #69809a;
  border-color: #69809a;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  color: #fff;
  background-color: #596d83;
  border-color: #596d83;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #596d83;
  border-color: #596d83;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #69809a;
  background-color: transparent;
}

.btn-outline-secondary .badge {
  background: #69809a;
  border-color: #69809a;
  color: #fff;
}

.btn-outline-secondary:hover .badge,
.btn-outline-secondary:focus:hover .badge,
.btn-outline-secondary:active .badge,
.btn-outline-secondary.active .badge,
.show > .btn-outline-secondary.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #69809a;
}

.btn-success {
  color: #fff;
  background-color: #39da8a;
  border-color: #39da8a;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-success:hover {
  color: #fff;
  background-color: #61e1a1;
  border-color: #61e1a1;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-success, .btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #30b975;
  border-color: #30b975;
  box-shadow: none;
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #30b975;
  border-color: #30b975;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #39da8a;
  border-color: #39da8a;
  box-shadow: none !important;
}

.btn-label-success {
  color: #39da8a;
  border-color: transparent;
  background: #2b4c4f;
}
.btn-label-success:hover {
  border-color: transparent;
  background: #39da8a;
  color: #fff;
}
.btn-label-success:focus, .btn-label-success.focus {
  color: #fff;
  background: #30b975;
  box-shadow: none !important;
}
.btn-label-success:active, .btn-label-success.active, .show > .btn-label-success.dropdown-toggle {
  color: #fff;
  background-color: #30b975;
  border-color: transparent;
}
.btn-label-success:active:focus, .btn-label-success.active:focus, .show > .btn-label-success.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-success.disabled, .btn-label-success:disabled {
  color: #39da8a;
  border-color: transparent;
  background: #2b4c4f;
  box-shadow: none;
}

.btn-outline-success {
  color: #39da8a;
  border-color: #39da8a;
  background: transparent;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #39da8a;
  border-color: #39da8a;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  color: #fff;
  background-color: #30b975;
  border-color: #30b975;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #30b975;
  border-color: #30b975;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #39da8a;
  background-color: transparent;
}

.btn-outline-success .badge {
  background: #39da8a;
  border-color: #39da8a;
  color: #fff;
}

.btn-outline-success:hover .badge,
.btn-outline-success:focus:hover .badge,
.btn-outline-success:active .badge,
.btn-outline-success.active .badge,
.show > .btn-outline-success.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #39da8a;
}

.btn-info {
  color: #fff;
  background-color: #00cfdd;
  border-color: #00cfdd;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-info:hover {
  color: #fff;
  background-color: #33d9e4;
  border-color: #33d9e4;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-info, .btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #00b0bc;
  border-color: #00b0bc;
  box-shadow: none;
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00b0bc;
  border-color: #00b0bc;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #00cfdd;
  border-color: #00cfdd;
  box-shadow: none !important;
}

.btn-label-info {
  color: #00cfdd;
  border-color: transparent;
  background: #224a5c;
}
.btn-label-info:hover {
  border-color: transparent;
  background: #00cfdd;
  color: #fff;
}
.btn-label-info:focus, .btn-label-info.focus {
  color: #fff;
  background: #00b0bc;
  box-shadow: none !important;
}
.btn-label-info:active, .btn-label-info.active, .show > .btn-label-info.dropdown-toggle {
  color: #fff;
  background-color: #00b0bc;
  border-color: transparent;
}
.btn-label-info:active:focus, .btn-label-info.active:focus, .show > .btn-label-info.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-info.disabled, .btn-label-info:disabled {
  color: #00cfdd;
  border-color: transparent;
  background: #224a5c;
  box-shadow: none;
}

.btn-outline-info {
  color: #00cfdd;
  border-color: #00cfdd;
  background: transparent;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #00cfdd;
  border-color: #00cfdd;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  color: #fff;
  background-color: #00b0bc;
  border-color: #00b0bc;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #00b0bc;
  border-color: #00b0bc;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #00cfdd;
  background-color: transparent;
}

.btn-outline-info .badge {
  background: #00cfdd;
  border-color: #00cfdd;
  color: #fff;
}

.btn-outline-info:hover .badge,
.btn-outline-info:focus:hover .badge,
.btn-outline-info:active .badge,
.btn-outline-info.active .badge,
.show > .btn-outline-info.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #00cfdd;
}

.btn-warning {
  color: #fff;
  background-color: #fdac41;
  border-color: #fdac41;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-warning:hover {
  color: #fff;
  background-color: #fdbd67;
  border-color: #fdbd67;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-warning, .btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #d79237;
  border-color: #d79237;
  box-shadow: none;
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #d79237;
  border-color: #d79237;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #fdac41;
  border-color: #fdac41;
  box-shadow: none !important;
}

.btn-label-warning {
  color: #fdac41;
  border-color: transparent;
  background: #4a4544;
}
.btn-label-warning:hover {
  border-color: transparent;
  background: #fdac41;
  color: #fff;
}
.btn-label-warning:focus, .btn-label-warning.focus {
  color: #fff;
  background: #d79237;
  box-shadow: none !important;
}
.btn-label-warning:active, .btn-label-warning.active, .show > .btn-label-warning.dropdown-toggle {
  color: #fff;
  background-color: #d79237;
  border-color: transparent;
}
.btn-label-warning:active:focus, .btn-label-warning.active:focus, .show > .btn-label-warning.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-warning.disabled, .btn-label-warning:disabled {
  color: #fdac41;
  border-color: transparent;
  background: #4a4544;
  box-shadow: none;
}

.btn-outline-warning {
  color: #fdac41;
  border-color: #fdac41;
  background: transparent;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #fdac41;
  border-color: #fdac41;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  color: #fff;
  background-color: #d79237;
  border-color: #d79237;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #fff;
  background-color: #d79237;
  border-color: #d79237;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fdac41;
  background-color: transparent;
}

.btn-outline-warning .badge {
  background: #fdac41;
  border-color: #fdac41;
  color: #fff;
}

.btn-outline-warning:hover .badge,
.btn-outline-warning:focus:hover .badge,
.btn-outline-warning:active .badge,
.btn-outline-warning.active .badge,
.show > .btn-outline-warning.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #fdac41;
}

.btn-danger {
  color: #fff;
  background-color: #ff5b5c;
  border-color: #ff5b5c;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-danger:hover {
  color: #fff;
  background-color: #ff7c7d;
  border-color: #ff7c7d;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-danger, .btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #d94d4e;
  border-color: #d94d4e;
  box-shadow: none;
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d94d4e;
  border-color: #d94d4e;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #ff5b5c;
  border-color: #ff5b5c;
  box-shadow: none !important;
}

.btn-label-danger {
  color: #ff5b5c;
  border-color: transparent;
  background: #4a3848;
}
.btn-label-danger:hover {
  border-color: transparent;
  background: #ff5b5c;
  color: #fff;
}
.btn-label-danger:focus, .btn-label-danger.focus {
  color: #fff;
  background: #d94d4e;
  box-shadow: none !important;
}
.btn-label-danger:active, .btn-label-danger.active, .show > .btn-label-danger.dropdown-toggle {
  color: #fff;
  background-color: #d94d4e;
  border-color: transparent;
}
.btn-label-danger:active:focus, .btn-label-danger.active:focus, .show > .btn-label-danger.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-danger.disabled, .btn-label-danger:disabled {
  color: #ff5b5c;
  border-color: transparent;
  background: #4a3848;
  box-shadow: none;
}

.btn-outline-danger {
  color: #ff5b5c;
  border-color: #ff5b5c;
  background: transparent;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff5b5c;
  border-color: #ff5b5c;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  color: #fff;
  background-color: #d94d4e;
  border-color: #d94d4e;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #d94d4e;
  border-color: #d94d4e;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ff5b5c;
  background-color: transparent;
}

.btn-outline-danger .badge {
  background: #ff5b5c;
  border-color: #ff5b5c;
  color: #fff;
}

.btn-outline-danger:hover .badge,
.btn-outline-danger:focus:hover .badge,
.btn-outline-danger:active .badge,
.btn-outline-danger.active .badge,
.show > .btn-outline-danger.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #ff5b5c;
}

.btn-light {
  color: #fff;
  background-color: #434c5f;
  border-color: #434c5f;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-light:hover {
  color: #fff;
  background-color: #69707f;
  border-color: #69707f;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-light, .btn-light:focus, .btn-light.focus {
  color: #fff;
  background-color: #394151;
  border-color: #394151;
  box-shadow: none;
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #394151;
  border-color: #394151;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-light.disabled, .btn-light:disabled {
  color: #fff;
  background-color: #434c5f;
  border-color: #434c5f;
  box-shadow: none !important;
}

.btn-label-light {
  color: #434c5f;
  border-color: transparent;
  background: #2c3548;
}
.btn-label-light:hover {
  border-color: transparent;
  background: #434c5f;
  color: #fff;
}
.btn-label-light:focus, .btn-label-light.focus {
  color: #fff;
  background: #394151;
  box-shadow: none !important;
}
.btn-label-light:active, .btn-label-light.active, .show > .btn-label-light.dropdown-toggle {
  color: #fff;
  background-color: #394151;
  border-color: transparent;
}
.btn-label-light:active:focus, .btn-label-light.active:focus, .show > .btn-label-light.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-light.disabled, .btn-label-light:disabled {
  color: #434c5f;
  border-color: transparent;
  background: #2c3548;
  box-shadow: none;
}

.btn-outline-light {
  color: #434c5f;
  border-color: #434c5f;
  background: transparent;
}
.btn-outline-light:hover {
  color: #fff;
  background-color: #434c5f;
  border-color: #434c5f;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  color: #fff;
  background-color: #394151;
  border-color: #394151;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #fff;
  background-color: #394151;
  border-color: #394151;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #434c5f;
  background-color: transparent;
}

.btn-outline-light .badge {
  background: #434c5f;
  border-color: #434c5f;
  color: #fff;
}

.btn-outline-light:hover .badge,
.btn-outline-light:focus:hover .badge,
.btn-outline-light:active .badge,
.btn-outline-light.active .badge,
.show > .btn-outline-light.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #434c5f;
}

.btn-dark {
  color: #fff;
  background-color: #717784;
  border-color: #717784;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-dark:hover {
  color: #fff;
  background-color: #8d929d;
  border-color: #8d929d;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-dark, .btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #606570;
  border-color: #606570;
  box-shadow: none;
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #606570;
  border-color: #606570;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #717784;
  border-color: #717784;
  box-shadow: none !important;
}

.btn-label-dark {
  color: #717784;
  border-color: transparent;
  background: #343c4e;
}
.btn-label-dark:hover {
  border-color: transparent;
  background: #717784;
  color: #fff;
}
.btn-label-dark:focus, .btn-label-dark.focus {
  color: #fff;
  background: #606570;
  box-shadow: none !important;
}
.btn-label-dark:active, .btn-label-dark.active, .show > .btn-label-dark.dropdown-toggle {
  color: #fff;
  background-color: #606570;
  border-color: transparent;
}
.btn-label-dark:active:focus, .btn-label-dark.active:focus, .show > .btn-label-dark.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-dark.disabled, .btn-label-dark:disabled {
  color: #717784;
  border-color: transparent;
  background: #343c4e;
  box-shadow: none;
}

.btn-outline-dark {
  color: #717784;
  border-color: #717784;
  background: transparent;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #717784;
  border-color: #717784;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  color: #fff;
  background-color: #606570;
  border-color: #606570;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #606570;
  border-color: #606570;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #717784;
  background-color: transparent;
}

.btn-outline-dark .badge {
  background: #717784;
  border-color: #717784;
  color: #fff;
}

.btn-outline-dark:hover .badge,
.btn-outline-dark:focus:hover .badge,
.btn-outline-dark:active .badge,
.btn-outline-dark.active .badge,
.show > .btn-outline-dark.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #717784;
}

.btn-gray {
  color: #000;
  background-color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-gray:hover {
  color: #fff;
  background-color: rgba(180, 180, 180, 0.8);
  border-color: rgba(180, 180, 180, 0.8);
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-gray, .btn-gray:focus, .btn-gray.focus {
  color: #000;
  background-color: rgba(255, 255, 255, 0.7875);
  border-color: rgba(255, 255, 255, 0.7875);
  box-shadow: none;
}
.btn-check:checked + .btn-gray, .btn-check:active + .btn-gray, .btn-gray:active, .btn-gray.active, .show > .btn-gray.dropdown-toggle {
  color: #000;
  background-color: rgba(255, 255, 255, 0.7875);
  border-color: rgba(255, 255, 255, 0.7875);
}
.btn-check:checked + .btn-gray:focus, .btn-check:active + .btn-gray:focus, .btn-gray:active:focus, .btn-gray.active:focus, .show > .btn-gray.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-gray.disabled, .btn-gray:disabled {
  color: #000;
  background-color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.75);
  box-shadow: none !important;
}

.btn-label-gray {
  color: rgba(255, 255, 255, 0.75);
  border-color: transparent;
  background: rgba(62, 70, 87, 0.96);
}
.btn-label-gray:hover {
  border-color: transparent;
  background: rgba(255, 255, 255, 0.75);
  color: #000;
}
.btn-label-gray:focus, .btn-label-gray.focus {
  color: #000;
  background: rgba(255, 255, 255, 0.7875);
  box-shadow: none !important;
}
.btn-label-gray:active, .btn-label-gray.active, .show > .btn-label-gray.dropdown-toggle {
  color: #000;
  background-color: rgba(255, 255, 255, 0.7875);
  border-color: transparent;
}
.btn-label-gray:active:focus, .btn-label-gray.active:focus, .show > .btn-label-gray.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-gray.disabled, .btn-label-gray:disabled {
  color: rgba(255, 255, 255, 0.75);
  border-color: transparent;
  background: rgba(62, 70, 87, 0.96);
  box-shadow: none;
}

.btn-outline-gray {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.75);
  background: transparent;
}
.btn-outline-gray:hover {
  color: #000;
  background-color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.75);
}
.btn-check:focus + .btn-outline-gray, .btn-outline-gray:focus {
  color: #fff;
  background-color: rgba(197, 197, 197, 0.7875);
  border-color: rgba(197, 197, 197, 0.7875);
  box-shadow: none;
}
.btn-check:checked + .btn-outline-gray, .btn-check:active + .btn-outline-gray, .btn-outline-gray:active, .btn-outline-gray.active, .btn-outline-gray.dropdown-toggle.show {
  color: #fff;
  background-color: rgba(197, 197, 197, 0.7875);
  border-color: rgba(197, 197, 197, 0.7875);
}
.btn-check:checked + .btn-outline-gray:focus, .btn-check:active + .btn-outline-gray:focus, .btn-outline-gray:active:focus, .btn-outline-gray.active:focus, .btn-outline-gray.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-gray.disabled, .btn-outline-gray:disabled {
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;
}

.btn-outline-gray .badge {
  background: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.75);
  color: #000;
}

.btn-outline-gray:hover .badge,
.btn-outline-gray:focus:hover .badge,
.btn-outline-gray:active .badge,
.btn-outline-gray.active .badge,
.show > .btn-outline-gray.dropdown-toggle .badge {
  background: #000;
  border-color: #000;
  color: #fff;
}

.btn-icon {
  padding: 0;
  width: calc(2.2505rem + 2px);
  height: calc(2.2505rem + 2px);
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.btn-icon.btn-xl, .btn-group-xl > .btn-icon.btn {
  width: calc(3.625rem + 2px);
  height: calc(3.625rem + 2px);
}
.btn-icon.btn-xl > span, .btn-group-xl > .btn-icon.btn > span {
  font-size: 1.25rem;
}
.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  width: calc(3.0745rem + 2px);
  height: calc(3.0745rem + 2px);
  font-size: 1.063rem;
}
.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  width: calc(1.3496rem + 2px);
  height: calc(1.3496rem + 2px);
  font-size: 0.813rem;
}
.btn-icon.btn-xs, .btn-group-xs > .btn-icon.btn {
  width: calc(0.95rem + 2px);
  height: calc(0.95rem + 2px);
  font-size: 0.75rem;
}

.btn.borderless:not(.active):not(:active):not(:hover):not(:focus), :not(.show) > .btn.borderless.dropdown-toggle:not(:hover):not(:focus) {
  border-color: transparent;
  box-shadow: none;
}

.btn.btn-link {
  font-size: inherit;
}

.btn-pinned {
  position: absolute;
  top: 0.75rem;
}
html:not([dir=rtl]) .btn-pinned {
  right: 0.75rem;
}
[dir=rtl] .btn-pinned {
  left: 0.75rem;
}

button:focus {
  outline: none;
}

.dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.input-group-lg .btn + .dropdown-toggle-split,
.btn-xl + .dropdown-toggle-split,
.btn-group-xl > .btn + .dropdown-toggle-split {
  padding-right: 0.7em;
  padding-left: 0.7em;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.input-group-sm .btn + .dropdown-toggle-split {
  padding-right: 0.6em;
  padding-left: 0.6em;
}

.btn-xs + .dropdown-toggle-split,
.btn-group-xs > .btn + .dropdown-toggle-split {
  padding-right: 0.5em;
  padding-left: 0.5em;
}

html:not([dir=rtl]) .btn-group > .btn-group:first-child > .btn:not([class*=btn-outline-]):first-child,
html:not([dir=rtl]) .input-group > .btn:not([class*=btn-outline-]):first-child,
html:not([dir=rtl]) :not(.btn-group):not(.input-group) > .btn-group > .btn:not([class*=btn-outline-]):first-child,
html:not([dir=rtl]) .input-group > .btn-group:first-child > .btn:not([class*=btn-outline-]):first-child {
  border-left-color: transparent;
}
[dir=rtl] .btn-group > .btn-group:first-child > .btn:not([class*=btn-outline-]):first-child,
[dir=rtl] .input-group > .btn:not([class*=btn-outline-]):first-child,
[dir=rtl] :not(.btn-group):not(.input-group) > .btn-group > .btn:not([class*=btn-outline-]):first-child,
[dir=rtl] .input-group > .btn-group:first-child > .btn:not([class*=btn-outline-]):first-child {
  border-right-color: transparent;
}

html:not([dir=rtl]) .btn-group > .btn-group:last-child > .btn:not([class*=btn-outline-]):last-of-type,
html:not([dir=rtl]) .input-group > .btn:not([class*=btn-outline-]):last-of-type,
html:not([dir=rtl]) :not(.btn-group):not(.input-group) > .btn-group > .btn:not([class*=btn-outline-]):last-of-type,
html:not([dir=rtl]) .input-group > .btn-group:last-child > .btn:not([class*=btn-outline-]):last-of-type {
  border-right-color: transparent;
}
[dir=rtl] .btn-group > .btn-group:last-child > .btn:not([class*=btn-outline-]):last-of-type,
[dir=rtl] .input-group > .btn:not([class*=btn-outline-]):last-of-type,
[dir=rtl] :not(.btn-group):not(.input-group) > .btn-group > .btn:not([class*=btn-outline-]):last-of-type,
[dir=rtl] .input-group > .btn-group:last-child > .btn:not([class*=btn-outline-]):last-of-type {
  border-left-color: transparent;
}

[dir=rtl] .btn-group .btn[class] {
  border-radius: 0.25rem;
}
[dir=rtl] .btn-group .btn-xs[class],
[dir=rtl] .btn-group-xs .btn[class] {
  border-radius: 0.125rem;
}
[dir=rtl] .btn-group .btn-sm[class],
[dir=rtl] .btn-group-sm .btn[class] {
  border-radius: 0.1875rem;
}
[dir=rtl] .btn-group .btn-lg[class],
[dir=rtl] .btn-group-lg .btn[class] {
  border-radius: 0.3125rem;
}
[dir=rtl] .btn-group .btn-xl[class],
[dir=rtl] .btn-group-xl .btn[class] {
  border-radius: 0.375rem;
}
[dir=rtl] .btn-group > .btn:not(:first-child),
[dir=rtl] .btn-group > .btn-group:not(:first-child) {
  margin-left: 0;
  margin-right: -1px;
}
[dir=rtl] .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
[dir=rtl] .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
[dir=rtl] .btn-group > .btn:nth-child(n+3),
[dir=rtl] .btn-group > :not(.btn-check) + .btn,
[dir=rtl] .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[dir=rtl] .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
[dir=rtl] .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
[dir=rtl] .btn-group-vertical > .btn ~ .btn,
[dir=rtl] .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.badge {
  text-transform: uppercase;
  line-height: 0.92;
}
.badge i {
  line-height: 0.92;
}

.badge-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.813em;
}
.badge-center i {
  font-size: 0.8rem;
}

.badge.badge-dot {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  vertical-align: middle;
}

.badge.badge-notifications {
  position: absolute;
  top: auto;
  display: inline-block;
  margin: 0;
  transform: translate(-50%, -30%);
}
[dir=rtl] .badge.badge-notifications {
  transform: translate(50%, -30%);
}
.badge.badge-notifications:not(.badge-dot) {
  padding: 0.05rem 0.2rem;
  font-size: 0.582rem;
  line-height: 0.75rem;
}

[data-trigger=hover] {
  outline: 0;
}

.dropdown-menu {
  margin: 0.125rem 0;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
  animation: dropdownAnimation 0.1s;
}
.mega-dropdown > .dropdown-menu {
  left: 0 !important;
  right: 0 !important;
}
.dropdown-menu .badge[class^=float-],
.dropdown-menu .badge[class*=" float-"] {
  position: relative;
  top: 0.071em;
}
.dropdown-menu .list-group-item {
  border-color: #3b4455;
}
[dir=rtl] .dropdown-menu {
  text-align: right;
}

.dropdown-item {
  line-height: 1.54;
}

.dropdown-header {
  font-size: 0.9375rem;
}

.dropdown-toggle.hide-arrow::before, .dropdown-toggle.hide-arrow::after,
.dropdown-toggle-hide-arrow > .dropdown-toggle::before,
.dropdown-toggle-hide-arrow > .dropdown-toggle::after {
  display: none;
}

.dropdown-toggle::after {
  margin-top: -0.45em;
  width: 0.45em;
  height: 0.45em;
  border: 1.5px solid;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
}

.dropend .dropdown-toggle::after {
  margin-top: -0.45em;
  width: 0.45em;
  height: 0.45em;
  border: 1.5px solid;
  border-top: 0;
  border-left: 0;
  transform: rotate(-45deg);
}
[dir=rtl] .dropend .dropdown-toggle::after {
  margin-left: 0 !important;
  margin-right: 0.75em !important;
  transform: rotate(135deg);
}

.dropstart .dropdown-toggle::before {
  margin-top: -0.45em;
  width: 0.45em;
  height: 0.45em;
  border: 1.5px solid;
  border-top: 0;
  border-right: 0;
  transform: rotate(45deg);
}
[dir=rtl] .dropstart .dropdown-toggle::before {
  margin-right: 0 !important;
  margin-left: 0.75em !important;
  transform: rotate(225deg);
}

.dropup .dropdown-toggle::after {
  margin-top: 0;
  width: 0.45em;
  height: 0.45em;
  border: 1.5px solid;
  border-bottom: 0;
  border-left: 0;
  transform: rotate(-45deg);
}

.dropstart .dropdown-toggle::before,
.dropend .dropdown-toggle::after {
  vertical-align: middle;
}

[dir=rtl] .dropdown-toggle:not(.dropdown-toggle-split)::after {
  margin-left: 0;
  margin-right: 0.75em;
}

[dir=rtl] .dropdown-menu-start {
  --bs-position: start;
}
[dir=rtl] .dropdown-menu-start[data-bs-popper] {
  left: auto;
  right: 0;
}
[dir=rtl] .dropdown-menu-end {
  --bs-position: end;
}
[dir=rtl] .dropdown-menu-end[data-bs-popper] {
  left: 0;
  right: auto;
}
@media (min-width: 576px) {
  [dir=rtl] .dropdown-menu-sm-start {
    --bs-position: start;
  }
  [dir=rtl] .dropdown-menu-sm-start[data-bs-popper] {
    left: auto;
    right: 0;
  }
  [dir=rtl] .dropdown-menu-sm-end {
    --bs-position: end;
  }
  [dir=rtl] .dropdown-menu-sm-end[data-bs-popper] {
    left: 0;
    right: auto;
  }
}
@media (min-width: 768px) {
  [dir=rtl] .dropdown-menu-md-start {
    --bs-position: start;
  }
  [dir=rtl] .dropdown-menu-md-start[data-bs-popper] {
    left: auto;
    right: 0;
  }
  [dir=rtl] .dropdown-menu-md-end {
    --bs-position: end;
  }
  [dir=rtl] .dropdown-menu-md-end[data-bs-popper] {
    left: 0;
    right: auto;
  }
}
@media (min-width: 992px) {
  [dir=rtl] .dropdown-menu-lg-start {
    --bs-position: start;
  }
  [dir=rtl] .dropdown-menu-lg-start[data-bs-popper] {
    left: auto;
    right: 0;
  }
  [dir=rtl] .dropdown-menu-lg-end {
    --bs-position: end;
  }
  [dir=rtl] .dropdown-menu-lg-end[data-bs-popper] {
    left: 0;
    right: auto;
  }
}
@media (min-width: 1200px) {
  [dir=rtl] .dropdown-menu-xl-start {
    --bs-position: start;
  }
  [dir=rtl] .dropdown-menu-xl-start[data-bs-popper] {
    left: auto;
    right: 0;
  }
  [dir=rtl] .dropdown-menu-xl-end {
    --bs-position: end;
  }
  [dir=rtl] .dropdown-menu-xl-end[data-bs-popper] {
    left: 0;
    right: auto;
  }
}
@media (min-width: 1400px) {
  [dir=rtl] .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  [dir=rtl] .dropdown-menu-xxl-start[data-bs-popper] {
    left: auto;
    right: 0;
  }
  [dir=rtl] .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  [dir=rtl] .dropdown-menu-xxl-end[data-bs-popper] {
    left: 0;
    right: auto;
  }
}

.nav .nav-item,
.nav .nav-link,
.tab-pane,
.tab-pane .card-body {
  outline: none !important;
}

.nav-tabs .nav-link:not(.active):hover, .nav-tabs .nav-link:not(.active):focus,
.nav-pills .nav-link:not(.active):hover,
.nav-pills .nav-link:not(.active):focus {
  color: #d8deea;
}
.nav-tabs:not(.nav-fill):not(.nav-justified) .nav-link,
.nav-pills:not(.nav-fill):not(.nav-justified) .nav-link {
  margin-right: 0.125rem;
  width: 100%;
}
[dir=rtl] .nav-tabs:not(.nav-fill):not(.nav-justified) .nav-link,
[dir=rtl] .nav-pills:not(.nav-fill):not(.nav-justified) .nav-link {
  margin-left: 0.125rem;
  margin-right: 0;
}

.tab-content {
  padding: 1.375rem;
  border-radius: 0.25rem;
}

.nav-scrollable {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  width: 100%;
  overflow-y: auto;
  flex-wrap: nowrap;
}

.nav-tabs .nav-link {
  background-clip: padding-box;
}
.nav-tabs .nav-link.active {
  border-bottom-color: #283144;
}
.nav-tabs .nav-link.active:hover, .nav-tabs .nav-link.active:focus {
  border-bottom-color: #283144;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-bottom-color: transparent;
}

.nav-sm > .nav .nav-link, .nav-sm.nav .nav-link {
  padding: 0.3125rem 0.875rem;
  font-size: 0.813rem;
  line-height: 1.2;
}

.nav-lg > .nav .nav-link, .nav-lg.nav .nav-link {
  padding: 0.875rem 1.3125rem;
  font-size: 1.063rem;
  line-height: 1.5;
}

.nav-align-top,
.nav-align-right,
.nav-align-bottom,
.nav-align-left {
  display: flex;
}
.nav-align-top > .nav,
.nav-align-top > div > .nav,
.nav-align-right > .nav,
.nav-align-right > div > .nav,
.nav-align-bottom > .nav,
.nav-align-bottom > div > .nav,
.nav-align-left > .nav,
.nav-align-left > div > .nav {
  border: 0;
  z-index: 1;
  position: relative;
}
.nav-align-top > .nav .nav-link.active,
.nav-align-top > div > .nav .nav-link.active,
.nav-align-right > .nav .nav-link.active,
.nav-align-right > div > .nav .nav-link.active,
.nav-align-bottom > .nav .nav-link.active,
.nav-align-bottom > div > .nav .nav-link.active,
.nav-align-left > .nav .nav-link.active,
.nav-align-left > div > .nav .nav-link.active {
  border-color: #36445d;
}
.nav-align-top > .nav .nav-link:not(.active):hover, .nav-align-top > .nav .nav-link:not(.active):focus,
.nav-align-top > div > .nav .nav-link:not(.active):hover,
.nav-align-top > div > .nav .nav-link:not(.active):focus,
.nav-align-right > .nav .nav-link:not(.active):hover,
.nav-align-right > .nav .nav-link:not(.active):focus,
.nav-align-right > div > .nav .nav-link:not(.active):hover,
.nav-align-right > div > .nav .nav-link:not(.active):focus,
.nav-align-bottom > .nav .nav-link:not(.active):hover,
.nav-align-bottom > .nav .nav-link:not(.active):focus,
.nav-align-bottom > div > .nav .nav-link:not(.active):hover,
.nav-align-bottom > div > .nav .nav-link:not(.active):focus,
.nav-align-left > .nav .nav-link:not(.active):hover,
.nav-align-left > .nav .nav-link:not(.active):focus,
.nav-align-left > div > .nav .nav-link:not(.active):hover,
.nav-align-left > div > .nav .nav-link:not(.active):focus {
  border-color: #36445d;
}
.nav-align-top .row-bordered > [class^=col-]::before, .nav-align-top .row-bordered > [class^=col-]::after,
.nav-align-top .row-bordered > [class*=" col-"]::before,
.nav-align-top .row-bordered > [class*=" col-"]::after,
.nav-align-top .row-bordered > [class^="col "]::before,
.nav-align-top .row-bordered > [class^="col "]::after,
.nav-align-top .row-bordered > [class*=" col "]::before,
.nav-align-top .row-bordered > [class*=" col "]::after,
.nav-align-top .row-bordered > [class$=" col"]::before,
.nav-align-top .row-bordered > [class$=" col"]::after,
.nav-align-top .row-bordered > [class=col]::before,
.nav-align-top .row-bordered > [class=col]::after,
.nav-align-right .row-bordered > [class^=col-]::before,
.nav-align-right .row-bordered > [class^=col-]::after,
.nav-align-right .row-bordered > [class*=" col-"]::before,
.nav-align-right .row-bordered > [class*=" col-"]::after,
.nav-align-right .row-bordered > [class^="col "]::before,
.nav-align-right .row-bordered > [class^="col "]::after,
.nav-align-right .row-bordered > [class*=" col "]::before,
.nav-align-right .row-bordered > [class*=" col "]::after,
.nav-align-right .row-bordered > [class$=" col"]::before,
.nav-align-right .row-bordered > [class$=" col"]::after,
.nav-align-right .row-bordered > [class=col]::before,
.nav-align-right .row-bordered > [class=col]::after,
.nav-align-bottom .row-bordered > [class^=col-]::before,
.nav-align-bottom .row-bordered > [class^=col-]::after,
.nav-align-bottom .row-bordered > [class*=" col-"]::before,
.nav-align-bottom .row-bordered > [class*=" col-"]::after,
.nav-align-bottom .row-bordered > [class^="col "]::before,
.nav-align-bottom .row-bordered > [class^="col "]::after,
.nav-align-bottom .row-bordered > [class*=" col "]::before,
.nav-align-bottom .row-bordered > [class*=" col "]::after,
.nav-align-bottom .row-bordered > [class$=" col"]::before,
.nav-align-bottom .row-bordered > [class$=" col"]::after,
.nav-align-bottom .row-bordered > [class=col]::before,
.nav-align-bottom .row-bordered > [class=col]::after,
.nav-align-left .row-bordered > [class^=col-]::before,
.nav-align-left .row-bordered > [class^=col-]::after,
.nav-align-left .row-bordered > [class*=" col-"]::before,
.nav-align-left .row-bordered > [class*=" col-"]::after,
.nav-align-left .row-bordered > [class^="col "]::before,
.nav-align-left .row-bordered > [class^="col "]::after,
.nav-align-left .row-bordered > [class*=" col "]::before,
.nav-align-left .row-bordered > [class*=" col "]::after,
.nav-align-left .row-bordered > [class$=" col"]::before,
.nav-align-left .row-bordered > [class$=" col"]::after,
.nav-align-left .row-bordered > [class=col]::before,
.nav-align-left .row-bordered > [class=col]::after {
  border-color: #36445d;
}

.nav-align-right,
.nav-align-left {
  align-items: stretch;
}
.nav-align-right > .nav,
.nav-align-right > div > .nav,
.nav-align-left > .nav,
.nav-align-left > div > .nav {
  flex-grow: 0;
  flex-direction: column;
}
.nav-align-right > .nav .nav-link,
.nav-align-right > div > .nav .nav-link,
.nav-align-left > .nav .nav-link,
.nav-align-left > div > .nav .nav-link {
  margin: 0 0 0.125rem 0 !important;
}
.nav-align-right > .tab-content,
.nav-align-left > .tab-content {
  flex-grow: 1;
}

.nav-align-top {
  flex-direction: column;
}
.nav-align-top > .nav .nav-link:hover, .nav-align-top > .nav .nav-link:focus,
.nav-align-top > div > .nav .nav-link:hover,
.nav-align-top > div > .nav .nav-link:focus {
  border-bottom-color: transparent;
}
.nav-align-top > .nav .nav-link.active,
.nav-align-top > div > .nav .nav-link.active {
  border-bottom-color: #283144;
}

.nav-align-right {
  flex-direction: row-reverse;
}
.nav-align-right > .nav .nav-item,
.nav-align-right > div > .nav .nav-item {
  margin-left: -1px;
  margin-bottom: 0;
}
[dir=rtl] .nav-align-right > .nav .nav-item,
[dir=rtl] .nav-align-right > div > .nav .nav-item {
  margin-left: 0;
  margin-right: -1px;
}
.nav-align-right .nav-link {
  text-align: right;
}
.nav-align-right > .nav:not(.nav-pills) .nav-link,
.nav-align-right > div > .nav:not(.nav-pills) .nav-link {
  border-radius: 0 0.25rem 0.25rem 0;
}
[dir=rtl] .nav-align-right > .nav:not(.nav-pills) .nav-link,
[dir=rtl] .nav-align-right > div > .nav:not(.nav-pills) .nav-link {
  border-radius: 0.25rem 0 0 0.25rem;
}
html:not([dir=rtl]) .nav-align-right > .nav:not(.nav-pills) .nav-link.active,
html:not([dir=rtl]) .nav-align-right > div > .nav:not(.nav-pills) .nav-link.active {
  border-left-color: #283144;
}
[dir=rtl] .nav-align-right > .nav:not(.nav-pills) .nav-link.active,
[dir=rtl] .nav-align-right > div > .nav:not(.nav-pills) .nav-link.active {
  border-right-color: #283144;
}
html:not([dir=rtl]) .nav-align-right > .nav:not(.nav-pills) .nav-link:hover, html:not([dir=rtl]) .nav-align-right > .nav:not(.nav-pills) .nav-link:focus,
html:not([dir=rtl]) .nav-align-right > div > .nav:not(.nav-pills) .nav-link:hover,
html:not([dir=rtl]) .nav-align-right > div > .nav:not(.nav-pills) .nav-link:focus {
  border-left-color: #283144;
}
[dir=rtl] .nav-align-right > .nav:not(.nav-pills) .nav-link:hover, [dir=rtl] .nav-align-right > .nav:not(.nav-pills) .nav-link:focus,
[dir=rtl] .nav-align-right > div > .nav:not(.nav-pills) .nav-link:hover,
[dir=rtl] .nav-align-right > div > .nav:not(.nav-pills) .nav-link:focus {
  border-right-color: #283144;
}

.nav-align-bottom {
  flex-direction: column-reverse;
}
.nav-align-bottom > .nav .nav-item,
.nav-align-bottom > div > .nav .nav-item {
  margin-bottom: 0;
  margin-top: -1px;
}
.nav-align-bottom > .nav:not(.nav-pills) .nav-link,
.nav-align-bottom > div > .nav:not(.nav-pills) .nav-link {
  border-radius: 0 0 0.25rem 0.25rem;
}
.nav-align-bottom > .nav:not(.nav-pills) .nav-link.active,
.nav-align-bottom > div > .nav:not(.nav-pills) .nav-link.active {
  border-top-color: #283144;
}
.nav-align-bottom > .nav:not(.nav-pills) .nav-link:hover, .nav-align-bottom > .nav:not(.nav-pills) .nav-link:focus,
.nav-align-bottom > div > .nav:not(.nav-pills) .nav-link:hover,
.nav-align-bottom > div > .nav:not(.nav-pills) .nav-link:focus {
  border-top-color: #283144;
}

.nav-align-left > .nav .nav-item,
.nav-align-left > div > .nav .nav-item {
  margin-right: -1px;
  margin-bottom: 0;
}
[dir=rtl] .nav-align-left > .nav .nav-item,
[dir=rtl] .nav-align-left > div > .nav .nav-item {
  margin-right: 0;
  margin-left: -1px;
}
.nav-align-left .nav-link {
  text-align: left;
}
.nav-align-left > .nav:not(.nav-pills) .nav-link,
.nav-align-left > div > .nav:not(.nav-pills) .nav-link {
  border-radius: 0.25rem 0 0 0.25rem;
}
[dir=rtl] .nav-align-left > .nav:not(.nav-pills) .nav-link,
[dir=rtl] .nav-align-left > div > .nav:not(.nav-pills) .nav-link {
  border-radius: 0 0.25rem 0.25rem 0;
}
html:not([dir=rtl]) .nav-align-left > .nav:not(.nav-pills) .nav-link.active,
html:not([dir=rtl]) .nav-align-left > div > .nav:not(.nav-pills) .nav-link.active {
  border-right-color: #283144;
}
[dir=rtl] .nav-align-left > .nav:not(.nav-pills) .nav-link.active,
[dir=rtl] .nav-align-left > div > .nav:not(.nav-pills) .nav-link.active {
  border-left-color: #283144;
}
html:not([dir=rtl]) .nav-align-left > .nav:not(.nav-pills) .nav-link:hover, html:not([dir=rtl]) .nav-align-left > .nav:not(.nav-pills) .nav-link:focus,
html:not([dir=rtl]) .nav-align-left > div > .nav:not(.nav-pills) .nav-link:hover,
html:not([dir=rtl]) .nav-align-left > div > .nav:not(.nav-pills) .nav-link:focus {
  border-right-color: #283144;
}
[dir=rtl] .nav-align-left > .nav:not(.nav-pills) .nav-link:hover, [dir=rtl] .nav-align-left > .nav:not(.nav-pills) .nav-link:focus,
[dir=rtl] .nav-align-left > div > .nav:not(.nav-pills) .nav-link:hover,
[dir=rtl] .nav-align-left > div > .nav:not(.nav-pills) .nav-link:focus {
  border-left-color: #283144;
}

.nav-align-top > .tab-content,
.nav-align-right > .tab-content,
.nav-align-bottom > .tab-content,
.nav-align-left > .tab-content {
  flex-shrink: 1;
  border: 1px solid #36445d;
  background-clip: padding-box;
  background: #283144;
}

.nav-align-top .nav-pills + .tab-content,
.nav-align-top .tabs-line + .tab-content,
.nav-align-right .nav-pills + .tab-content,
.nav-align-right .tabs-line + .tab-content,
.nav-align-bottom .nav-pills + .tab-content,
.nav-align-bottom .tabs-line + .tab-content,
.nav-align-left .nav-pills + .tab-content,
.nav-align-left .tabs-line + .tab-content {
  box-shadow: 0 0.125rem 0.875rem 0 rgba(0, 0, 0, 0.16);
  border-width: 0;
}

.nav-pills .nav-link.active {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}

.nav-align-top :not(.nav-pills) ~ .tab-content {
  border-radius: 0 0 0.25rem 0.25rem;
}

.nav-align-right :not(.nav-pills) ~ .tab-content {
  border-radius: 0.25rem 0 0 0.25rem;
}
[dir=rtl] .nav-align-right :not(.nav-pills) ~ .tab-content {
  border-radius: 0 0.25rem 0.25rem 0;
}

.nav-align-bottom :not(.nav-pills) ~ .tab-content {
  border-radius: 0.25rem 0.25rem 0 0;
}

.nav-align-left :not(.nav-pills) ~ .tab-content {
  border-radius: 0 0.25rem 0.25rem 0;
}
[dir=rtl] .nav-align-left :not(.nav-pills) ~ .tab-content {
  border-radius: 0.25rem 0 0 0.25rem;
}

.nav-tabs.tabs-line .nav-link, .nav-tabs.tabs-line .nav-link.active,
.tabs-line > .nav-tabs .nav-link,
.tabs-line > .nav-tabs .nav-link.active {
  background-color: transparent;
  border-width: 0;
}

.nav-tabs .nav-link.active {
  border-color: #36445d;
  border-bottom-color: #283144;
}

.nav-align-top .nav-tabs .nav-link.active {
  border-bottom-color: #283144 !important;
}

.nav-align-bottom .nav-tabs .nav-link.active {
  border-top-color: #283144 !important;
}

[dir=rtl] .nav {
  padding-right: 0;
}
[dir=rtl] .nav-align-left .nav-link {
  text-align: right;
}
[dir=rtl] .nav-align-right .nav-link {
  text-align: left;
}

.pagination-secondary .page-item.active .page-link, .pagination-secondary .page-item.active .page-link:hover, .pagination-secondary .page-item.active .page-link:focus,
.pagination-secondary.pagination li.active > a:not(.page-link),
.pagination-secondary.pagination li.active > a:not(.page-link):hover,
.pagination-secondary.pagination li.active > a:not(.page-link):focus {
  border-color: #69809a;
  background-color: #69809a;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}

.pagination-success .page-item.active .page-link, .pagination-success .page-item.active .page-link:hover, .pagination-success .page-item.active .page-link:focus,
.pagination-success.pagination li.active > a:not(.page-link),
.pagination-success.pagination li.active > a:not(.page-link):hover,
.pagination-success.pagination li.active > a:not(.page-link):focus {
  border-color: #39da8a;
  background-color: #39da8a;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}

.pagination-info .page-item.active .page-link, .pagination-info .page-item.active .page-link:hover, .pagination-info .page-item.active .page-link:focus,
.pagination-info.pagination li.active > a:not(.page-link),
.pagination-info.pagination li.active > a:not(.page-link):hover,
.pagination-info.pagination li.active > a:not(.page-link):focus {
  border-color: #00cfdd;
  background-color: #00cfdd;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}

.pagination-warning .page-item.active .page-link, .pagination-warning .page-item.active .page-link:hover, .pagination-warning .page-item.active .page-link:focus,
.pagination-warning.pagination li.active > a:not(.page-link),
.pagination-warning.pagination li.active > a:not(.page-link):hover,
.pagination-warning.pagination li.active > a:not(.page-link):focus {
  border-color: #fdac41;
  background-color: #fdac41;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}

.pagination-danger .page-item.active .page-link, .pagination-danger .page-item.active .page-link:hover, .pagination-danger .page-item.active .page-link:focus,
.pagination-danger.pagination li.active > a:not(.page-link),
.pagination-danger.pagination li.active > a:not(.page-link):hover,
.pagination-danger.pagination li.active > a:not(.page-link):focus {
  border-color: #ff5b5c;
  background-color: #ff5b5c;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}

.pagination-dark .page-item.active .page-link, .pagination-dark .page-item.active .page-link:hover, .pagination-dark .page-item.active .page-link:focus,
.pagination-dark.pagination li.active > a:not(.page-link),
.pagination-dark.pagination li.active > a:not(.page-link):hover,
.pagination-dark.pagination li.active > a:not(.page-link):focus {
  border-color: #717784;
  background-color: #717784;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}

.pagination-gray .page-item.active .page-link, .pagination-gray .page-item.active .page-link:hover, .pagination-gray .page-item.active .page-link:focus,
.pagination-gray.pagination li.active > a:not(.page-link),
.pagination-gray.pagination li.active > a:not(.page-link):hover,
.pagination-gray.pagination li.active > a:not(.page-link):focus {
  border-color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.75);
  color: #000;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}

.page-item:not(.first, .last, .next, .prev, .previous) {
  background-color: rgba(99, 123, 169, 0.08);
}
.page-item:not(.first, .last, .next, .prev, .previous):not(.active, :hover, :focus) .page-link {
  background-color: transparent;
}
.page-item.first .page-link, .page-item.last .page-link, .page-item.next .page-link, .page-item.prev .page-link, .page-item.previous .page-link {
  padding-top: 0.43rem;
  padding-bottom: 0.43rem;
}
.page-item.disabled .page-link {
  border-color: rgba(255, 255, 255, 0.09);
}

.pagination .page-item.first .page-link,
.pagination .page-item.prev .page-link,
.pagination .page-item.previous .page-link {
  border-radius: 0.25rem;
}
html:not([dir=rtl]) .pagination .page-item.first .page-link,
html:not([dir=rtl]) .pagination .page-item.prev .page-link,
html:not([dir=rtl]) .pagination .page-item.previous .page-link {
  margin-right: 0.3125rem;
}
[dir=rtl] .pagination .page-item.first .page-link,
[dir=rtl] .pagination .page-item.prev .page-link,
[dir=rtl] .pagination .page-item.previous .page-link {
  margin-left: 0.3125rem;
}
.pagination .page-item.last .page-link,
.pagination .page-item.next .page-link {
  border-radius: 0.25rem;
}
html:not([dir=rtl]) .pagination .page-item.last .page-link,
html:not([dir=rtl]) .pagination .page-item.next .page-link {
  margin-left: 0.3125rem;
}
[dir=rtl] .pagination .page-item.last .page-link,
[dir=rtl] .pagination .page-item.next .page-link {
  margin-right: 0.3125rem;
}
.pagination .page-item.active .page-link {
  border-radius: 0.25rem;
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
  padding-top: 0.66rem !important;
  padding-bottom: 0.66rem !important;
  min-width: calc(
            2.2575rem + 0px
          ) !important;
}
.pagination .page-item .page-link:hover, .pagination .page-item .page-link:focus, .pagination .page-item .page-link:active {
  border-radius: 0.25rem;
}
html:not([dir=rtl]) .pagination .page-item.prev + .page-item,
html:not([dir=rtl]) .pagination .page-item.prev + .page-item .page-link,
html:not([dir=rtl]) .pagination .page-item.previous + .page-item,
html:not([dir=rtl]) .pagination .page-item.previous + .page-item .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
[dir=rtl] .pagination .page-item.prev + .page-item,
[dir=rtl] .pagination .page-item.prev + .page-item .page-link,
[dir=rtl] .pagination .page-item.previous + .page-item,
[dir=rtl] .pagination .page-item.previous + .page-item .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html:not([dir=rtl]) .pagination .page-item.first ~ .page-item:nth-last-child(3),
html:not([dir=rtl]) .pagination .page-item.first ~ .page-item:nth-last-child(3) .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[dir=rtl] .pagination .page-item.first ~ .page-item:nth-last-child(3),
[dir=rtl] .pagination .page-item.first ~ .page-item:nth-last-child(3) .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
html:not([dir=rtl]) .pagination .page-item:not(.first) ~ .page-item:nth-last-child(2),
html:not([dir=rtl]) .pagination .page-item:not(.first) ~ .page-item:nth-last-child(2) .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[dir=rtl] .pagination .page-item:not(.first) ~ .page-item:nth-last-child(2),
[dir=rtl] .pagination .page-item:not(.first) ~ .page-item:nth-last-child(2) .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination.pagination-sm .page-item.first .page-link,
.pagination.pagination-sm .page-item.prev .page-link,
.pagination.pagination-sm .page-item.previous .page-link {
  border-radius: 0.1875rem;
}
html:not([dir=rtl]) .pagination.pagination-sm .page-item.first .page-link,
html:not([dir=rtl]) .pagination.pagination-sm .page-item.prev .page-link,
html:not([dir=rtl]) .pagination.pagination-sm .page-item.previous .page-link {
  margin-right: 0.25rem;
}
[dir=rtl] .pagination.pagination-sm .page-item.first .page-link,
[dir=rtl] .pagination.pagination-sm .page-item.prev .page-link,
[dir=rtl] .pagination.pagination-sm .page-item.previous .page-link {
  margin-left: 0.25rem;
}
.pagination.pagination-sm .page-item.last .page-link,
.pagination.pagination-sm .page-item.next .page-link {
  border-radius: 0.1875rem;
}
html:not([dir=rtl]) .pagination.pagination-sm .page-item.last .page-link,
html:not([dir=rtl]) .pagination.pagination-sm .page-item.next .page-link {
  margin-left: 0.25rem;
}
[dir=rtl] .pagination.pagination-sm .page-item.last .page-link,
[dir=rtl] .pagination.pagination-sm .page-item.next .page-link {
  margin-right: 0.25rem;
}
.pagination.pagination-sm .page-item.active .page-link {
  border-radius: 0.1875rem;
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
  padding-top: 0.535rem !important;
  padding-bottom: 0.535rem !important;
  min-width: calc(
            1.883rem + 0px
          ) !important;
}
.pagination.pagination-sm .page-item .page-link:hover, .pagination.pagination-sm .page-item .page-link:focus, .pagination.pagination-sm .page-item .page-link:active {
  border-radius: 0.1875rem;
}
html:not([dir=rtl]) .pagination.pagination-sm .page-item.prev + .page-item,
html:not([dir=rtl]) .pagination.pagination-sm .page-item.prev + .page-item .page-link,
html:not([dir=rtl]) .pagination.pagination-sm .page-item.previous + .page-item,
html:not([dir=rtl]) .pagination.pagination-sm .page-item.previous + .page-item .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}
[dir=rtl] .pagination.pagination-sm .page-item.prev + .page-item,
[dir=rtl] .pagination.pagination-sm .page-item.prev + .page-item .page-link,
[dir=rtl] .pagination.pagination-sm .page-item.previous + .page-item,
[dir=rtl] .pagination.pagination-sm .page-item.previous + .page-item .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}
html:not([dir=rtl]) .pagination.pagination-sm .page-item.first ~ .page-item:nth-last-child(3),
html:not([dir=rtl]) .pagination.pagination-sm .page-item.first ~ .page-item:nth-last-child(3) .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}
[dir=rtl] .pagination.pagination-sm .page-item.first ~ .page-item:nth-last-child(3),
[dir=rtl] .pagination.pagination-sm .page-item.first ~ .page-item:nth-last-child(3) .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}
html:not([dir=rtl]) .pagination.pagination-sm .page-item:not(.first) ~ .page-item:nth-last-child(2),
html:not([dir=rtl]) .pagination.pagination-sm .page-item:not(.first) ~ .page-item:nth-last-child(2) .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}
[dir=rtl] .pagination.pagination-sm .page-item:not(.first) ~ .page-item:nth-last-child(2),
[dir=rtl] .pagination.pagination-sm .page-item:not(.first) ~ .page-item:nth-last-child(2) .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.pagination.pagination-lg .page-item.first .page-link,
.pagination.pagination-lg .page-item.prev .page-link,
.pagination.pagination-lg .page-item.previous .page-link {
  border-radius: 0.3125rem;
}
html:not([dir=rtl]) .pagination.pagination-lg .page-item.first .page-link,
html:not([dir=rtl]) .pagination.pagination-lg .page-item.prev .page-link,
html:not([dir=rtl]) .pagination.pagination-lg .page-item.previous .page-link {
  margin-right: 0.375rem;
}
[dir=rtl] .pagination.pagination-lg .page-item.first .page-link,
[dir=rtl] .pagination.pagination-lg .page-item.prev .page-link,
[dir=rtl] .pagination.pagination-lg .page-item.previous .page-link {
  margin-left: 0.375rem;
}
.pagination.pagination-lg .page-item.last .page-link,
.pagination.pagination-lg .page-item.next .page-link {
  border-radius: 0.3125rem;
}
html:not([dir=rtl]) .pagination.pagination-lg .page-item.last .page-link,
html:not([dir=rtl]) .pagination.pagination-lg .page-item.next .page-link {
  margin-left: 0.375rem;
}
[dir=rtl] .pagination.pagination-lg .page-item.last .page-link,
[dir=rtl] .pagination.pagination-lg .page-item.next .page-link {
  margin-right: 0.375rem;
}
.pagination.pagination-lg .page-item.active .page-link {
  border-radius: 0.3125rem;
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
  padding-top: 0.785rem !important;
  padding-bottom: 0.785rem !important;
  min-width: calc(
            2.633rem + 0px
          ) !important;
}
.pagination.pagination-lg .page-item .page-link:hover, .pagination.pagination-lg .page-item .page-link:focus, .pagination.pagination-lg .page-item .page-link:active {
  border-radius: 0.3125rem;
}
html:not([dir=rtl]) .pagination.pagination-lg .page-item.prev + .page-item,
html:not([dir=rtl]) .pagination.pagination-lg .page-item.prev + .page-item .page-link,
html:not([dir=rtl]) .pagination.pagination-lg .page-item.previous + .page-item,
html:not([dir=rtl]) .pagination.pagination-lg .page-item.previous + .page-item .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
[dir=rtl] .pagination.pagination-lg .page-item.prev + .page-item,
[dir=rtl] .pagination.pagination-lg .page-item.prev + .page-item .page-link,
[dir=rtl] .pagination.pagination-lg .page-item.previous + .page-item,
[dir=rtl] .pagination.pagination-lg .page-item.previous + .page-item .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
html:not([dir=rtl]) .pagination.pagination-lg .page-item.first ~ .page-item:nth-last-child(3),
html:not([dir=rtl]) .pagination.pagination-lg .page-item.first ~ .page-item:nth-last-child(3) .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
[dir=rtl] .pagination.pagination-lg .page-item.first ~ .page-item:nth-last-child(3),
[dir=rtl] .pagination.pagination-lg .page-item.first ~ .page-item:nth-last-child(3) .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
html:not([dir=rtl]) .pagination.pagination-lg .page-item:not(.first) ~ .page-item:nth-last-child(2),
html:not([dir=rtl]) .pagination.pagination-lg .page-item:not(.first) ~ .page-item:nth-last-child(2) .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
[dir=rtl] .pagination.pagination-lg .page-item:not(.first) ~ .page-item:nth-last-child(2),
[dir=rtl] .pagination.pagination-lg .page-item:not(.first) ~ .page-item:nth-last-child(2) .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.page-link,
.page-link > a {
  line-height: 1;
  text-align: center;
  min-width: calc(
    2.0075rem + 0px
  );
}
.page-link:focus,
.page-link > a:focus {
  color: #a1b0cb;
}

.page-link.btn-primary {
  box-shadow: none !important;
}

.pagination-lg .page-link,
.pagination-lg > li > a:not(.page-link) {
  min-width: calc(
    2.383rem + 0px
  );
}

.pagination-lg > .page-item.first .page-link, .pagination-lg > .page-item.last .page-link, .pagination-lg > .page-item.next .page-link, .pagination-lg > .page-item.prev .page-link, .pagination-lg > .page-item.previous .page-link {
  padding-top: 0.585rem;
  padding-bottom: 0.585rem;
}

.pagination-sm .page-link,
.pagination-sm > li > a:not(.page-link) {
  min-width: calc(
    1.633rem + 0px
  );
}
.pagination-sm .page-link .tf-icon,
.pagination-sm > li > a:not(.page-link) .tf-icon {
  font-size: 0.9375rem;
}

.pagination-sm > .page-item.first .page-link, .pagination-sm > .page-item.last .page-link, .pagination-sm > .page-item.next .page-link, .pagination-sm > .page-item.prev .page-link, .pagination-sm > .page-item.previous .page-link {
  padding-top: 0.345rem;
  padding-bottom: 0.345rem;
}

[dir=rtl] .pagination {
  padding-right: 0;
}
[dir=rtl] .page-item.first .page-link i, [dir=rtl] .page-item.last .page-link i, [dir=rtl] .page-item.next .page-link i, [dir=rtl] .page-item.prev .page-link i, [dir=rtl] .page-item.previous .page-link i {
  transform: rotate(180deg);
}

.alert-secondary {
  background-color: #323e52;
  border-color: #3c495e;
  color: #69809a;
}
.alert-secondary .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%2369809a' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%2369809a' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-secondary .alert-link {
  color: #69809a;
}

.card .alert-secondary hr {
  background-color: #69809a !important;
}

.alert-solid-secondary {
  background-color: #69809a;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.alert-solid-secondary .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-solid-secondary .alert-link {
  color: #fff;
}

.card .alert-solid-secondary hr {
  background-color: #fff !important;
}

.alert-success {
  background-color: #2b4c4f;
  border-color: #2d6459;
  color: #39da8a;
}
.alert-success .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%2339da8a' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%2339da8a' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-success .alert-link {
  color: #39da8a;
}

.card .alert-success hr {
  background-color: #39da8a !important;
}

.alert-solid-success {
  background-color: #39da8a;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.alert-solid-success .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-solid-success .alert-link {
  color: #fff;
}

.card .alert-solid-success hr {
  background-color: #fff !important;
}

.alert-info {
  background-color: #224a5c;
  border-color: #1c6072;
  color: #00cfdd;
}
.alert-info .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%2300cfdd' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%2300cfdd' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-info .alert-link {
  color: #00cfdd;
}

.card .alert-info hr {
  background-color: #00cfdd !important;
}

.alert-solid-info {
  background-color: #00cfdd;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.alert-solid-info .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-solid-info .alert-link {
  color: #fff;
}

.card .alert-solid-info hr {
  background-color: #fff !important;
}

.alert-warning {
  background-color: #4a4544;
  border-color: #685643;
  color: #fdac41;
}
.alert-warning .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fdac41' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23fdac41' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-warning .alert-link {
  color: #fdac41;
}

.card .alert-warning hr {
  background-color: #fdac41 !important;
}

.alert-solid-warning {
  background-color: #fdac41;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.alert-solid-warning .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-solid-warning .alert-link {
  color: #fff;
}

.card .alert-solid-warning hr {
  background-color: #fff !important;
}

.alert-danger {
  background-color: #4a3848;
  border-color: #693e4b;
  color: #ff5b5c;
}
.alert-danger .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23ff5b5c' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23ff5b5c' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-danger .alert-link {
  color: #ff5b5c;
}

.card .alert-danger hr {
  background-color: #ff5b5c !important;
}

.alert-solid-danger {
  background-color: #ff5b5c;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.alert-solid-danger .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-solid-danger .alert-link {
  color: #fff;
}

.card .alert-solid-danger hr {
  background-color: #fff !important;
}

.alert-dark {
  background-color: #4a5262;
  border-color: #696f7c;
  color: white;
}
.alert-dark .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='white' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='white' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-dark .alert-link {
  color: white;
}

.card .alert-dark hr {
  background-color: white !important;
}

.alert-solid-dark {
  background-color: #717784;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.alert-solid-dark .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-solid-dark .alert-link {
  color: #fff;
}

.card .alert-solid-dark hr {
  background-color: #fff !important;
}

.alert-gray {
  background-color: rgba(62, 70, 87, 0.96);
  border-color: rgba(84, 91, 106, 0.925);
  color: rgba(255, 255, 255, 0.75);
}
.alert-gray .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='rgba(255, 255, 255, 0.75)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='rgba(255, 255, 255, 0.75)' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-gray .alert-link {
  color: rgba(255, 255, 255, 0.75);
}

.card .alert-gray hr {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.alert-solid-gray {
  background-color: rgba(255, 255, 255, 0.75);
  color: #000;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.alert-solid-gray .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23000' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23000' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-solid-gray .alert-link {
  color: #000;
}

.card .alert-solid-gray hr {
  background-color: #000 !important;
}

.alert-dismissible .btn-close {
  padding: 1.05rem 1.25rem;
}

[dir=rtl] .alert-dismissible {
  padding-left: 3.75rem;
  padding-right: 1.25rem;
}
[dir=rtl] .alert-dismissible .btn-close {
  right: auto;
  left: 0;
}

.tooltip-secondary .tooltip-inner, .tooltip-secondary > .tooltip .tooltip-inner, .ngb-tooltip-secondary + ngb-tooltip-window .tooltip-inner {
  background: #69809a;
  color: #fff;
}
.tooltip-secondary.bs-tooltip-top .tooltip-arrow::before, .tooltip-secondary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-top .tooltip-arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-top .tooltip-arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: #69809a;
}
.tooltip-secondary.bs-tooltip-start .tooltip-arrow::before, .tooltip-secondary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-start .tooltip-arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: #69809a;
}
[dir=rtl] .tooltip-secondary.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-secondary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .tooltip-secondary > .tooltip.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-secondary > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-right-color: #69809a;
}
.tooltip-secondary.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-secondary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-bottom .tooltip-arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: #69809a;
}
.tooltip-secondary.bs-tooltip-end .tooltip-arrow::before, .tooltip-secondary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-end .tooltip-arrow::before, .tooltip-secondary > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: #69809a;
}
[dir=rtl] .tooltip-secondary.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-secondary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .tooltip-secondary > .tooltip.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-secondary > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-secondary + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-left-color: #69809a;
}

.tooltip-success .tooltip-inner, .tooltip-success > .tooltip .tooltip-inner, .ngb-tooltip-success + ngb-tooltip-window .tooltip-inner {
  background: #39da8a;
  color: #fff;
}
.tooltip-success.bs-tooltip-top .tooltip-arrow::before, .tooltip-success.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .tooltip-success > .tooltip.bs-tooltip-top .tooltip-arrow::before, .tooltip-success > .tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-top .tooltip-arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: #39da8a;
}
.tooltip-success.bs-tooltip-start .tooltip-arrow::before, .tooltip-success.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .tooltip-success > .tooltip.bs-tooltip-start .tooltip-arrow::before, .tooltip-success > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: #39da8a;
}
[dir=rtl] .tooltip-success.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-success.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .tooltip-success > .tooltip.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-success > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-right-color: #39da8a;
}
.tooltip-success.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-success.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .tooltip-success > .tooltip.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-success > .tooltip.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-bottom .tooltip-arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: #39da8a;
}
.tooltip-success.bs-tooltip-end .tooltip-arrow::before, .tooltip-success.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .tooltip-success > .tooltip.bs-tooltip-end .tooltip-arrow::before, .tooltip-success > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: #39da8a;
}
[dir=rtl] .tooltip-success.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-success.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .tooltip-success > .tooltip.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-success > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-success + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-left-color: #39da8a;
}

.tooltip-info .tooltip-inner, .tooltip-info > .tooltip .tooltip-inner, .ngb-tooltip-info + ngb-tooltip-window .tooltip-inner {
  background: #00cfdd;
  color: #fff;
}
.tooltip-info.bs-tooltip-top .tooltip-arrow::before, .tooltip-info.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .tooltip-info > .tooltip.bs-tooltip-top .tooltip-arrow::before, .tooltip-info > .tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-top .tooltip-arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: #00cfdd;
}
.tooltip-info.bs-tooltip-start .tooltip-arrow::before, .tooltip-info.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .tooltip-info > .tooltip.bs-tooltip-start .tooltip-arrow::before, .tooltip-info > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: #00cfdd;
}
[dir=rtl] .tooltip-info.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-info.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .tooltip-info > .tooltip.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-info > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-right-color: #00cfdd;
}
.tooltip-info.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-info.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .tooltip-info > .tooltip.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-info > .tooltip.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-bottom .tooltip-arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: #00cfdd;
}
.tooltip-info.bs-tooltip-end .tooltip-arrow::before, .tooltip-info.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .tooltip-info > .tooltip.bs-tooltip-end .tooltip-arrow::before, .tooltip-info > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: #00cfdd;
}
[dir=rtl] .tooltip-info.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-info.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .tooltip-info > .tooltip.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-info > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-info + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-left-color: #00cfdd;
}

.tooltip-warning .tooltip-inner, .tooltip-warning > .tooltip .tooltip-inner, .ngb-tooltip-warning + ngb-tooltip-window .tooltip-inner {
  background: #fdac41;
  color: #fff;
}
.tooltip-warning.bs-tooltip-top .tooltip-arrow::before, .tooltip-warning.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .tooltip-warning > .tooltip.bs-tooltip-top .tooltip-arrow::before, .tooltip-warning > .tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-top .tooltip-arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: #fdac41;
}
.tooltip-warning.bs-tooltip-start .tooltip-arrow::before, .tooltip-warning.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .tooltip-warning > .tooltip.bs-tooltip-start .tooltip-arrow::before, .tooltip-warning > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: #fdac41;
}
[dir=rtl] .tooltip-warning.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-warning.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .tooltip-warning > .tooltip.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-warning > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-right-color: #fdac41;
}
.tooltip-warning.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-warning.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .tooltip-warning > .tooltip.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-warning > .tooltip.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-bottom .tooltip-arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: #fdac41;
}
.tooltip-warning.bs-tooltip-end .tooltip-arrow::before, .tooltip-warning.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .tooltip-warning > .tooltip.bs-tooltip-end .tooltip-arrow::before, .tooltip-warning > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: #fdac41;
}
[dir=rtl] .tooltip-warning.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-warning.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .tooltip-warning > .tooltip.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-warning > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-warning + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-left-color: #fdac41;
}

.tooltip-danger .tooltip-inner, .tooltip-danger > .tooltip .tooltip-inner, .ngb-tooltip-danger + ngb-tooltip-window .tooltip-inner {
  background: #ff5b5c;
  color: #fff;
}
.tooltip-danger.bs-tooltip-top .tooltip-arrow::before, .tooltip-danger.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .tooltip-danger > .tooltip.bs-tooltip-top .tooltip-arrow::before, .tooltip-danger > .tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-top .tooltip-arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: #ff5b5c;
}
.tooltip-danger.bs-tooltip-start .tooltip-arrow::before, .tooltip-danger.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .tooltip-danger > .tooltip.bs-tooltip-start .tooltip-arrow::before, .tooltip-danger > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: #ff5b5c;
}
[dir=rtl] .tooltip-danger.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-danger.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .tooltip-danger > .tooltip.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-danger > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-right-color: #ff5b5c;
}
.tooltip-danger.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-danger.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .tooltip-danger > .tooltip.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-danger > .tooltip.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-bottom .tooltip-arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: #ff5b5c;
}
.tooltip-danger.bs-tooltip-end .tooltip-arrow::before, .tooltip-danger.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .tooltip-danger > .tooltip.bs-tooltip-end .tooltip-arrow::before, .tooltip-danger > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: #ff5b5c;
}
[dir=rtl] .tooltip-danger.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-danger.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .tooltip-danger > .tooltip.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-danger > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-danger + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-left-color: #ff5b5c;
}

.tooltip-dark .tooltip-inner, .tooltip-dark > .tooltip .tooltip-inner, .ngb-tooltip-dark + ngb-tooltip-window .tooltip-inner {
  background: #717784;
  color: #fff;
}
.tooltip-dark.bs-tooltip-top .tooltip-arrow::before, .tooltip-dark.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .tooltip-dark > .tooltip.bs-tooltip-top .tooltip-arrow::before, .tooltip-dark > .tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-top .tooltip-arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: #717784;
}
.tooltip-dark.bs-tooltip-start .tooltip-arrow::before, .tooltip-dark.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .tooltip-dark > .tooltip.bs-tooltip-start .tooltip-arrow::before, .tooltip-dark > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: #717784;
}
[dir=rtl] .tooltip-dark.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-dark.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .tooltip-dark > .tooltip.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-dark > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-right-color: #717784;
}
.tooltip-dark.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-dark.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .tooltip-dark > .tooltip.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-dark > .tooltip.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-bottom .tooltip-arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: #717784;
}
.tooltip-dark.bs-tooltip-end .tooltip-arrow::before, .tooltip-dark.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .tooltip-dark > .tooltip.bs-tooltip-end .tooltip-arrow::before, .tooltip-dark > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: #717784;
}
[dir=rtl] .tooltip-dark.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-dark.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .tooltip-dark > .tooltip.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-dark > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-dark + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-left-color: #717784;
}

.tooltip-gray .tooltip-inner, .tooltip-gray > .tooltip .tooltip-inner, .ngb-tooltip-gray + ngb-tooltip-window .tooltip-inner {
  background: rgba(255, 255, 255, 0.75);
  color: #000;
}
.tooltip-gray.bs-tooltip-top .tooltip-arrow::before, .tooltip-gray.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .tooltip-gray > .tooltip.bs-tooltip-top .tooltip-arrow::before, .tooltip-gray > .tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .ngb-tooltip-gray + ngb-tooltip-window.bs-tooltip-top .tooltip-arrow::before, .ngb-tooltip-gray + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: rgba(255, 255, 255, 0.75);
}
.tooltip-gray.bs-tooltip-start .tooltip-arrow::before, .tooltip-gray.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .tooltip-gray > .tooltip.bs-tooltip-start .tooltip-arrow::before, .tooltip-gray > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .ngb-tooltip-gray + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, .ngb-tooltip-gray + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: rgba(255, 255, 255, 0.75);
}
[dir=rtl] .tooltip-gray.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-gray.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .tooltip-gray > .tooltip.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .tooltip-gray > .tooltip.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-gray + ngb-tooltip-window.bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-gray + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-right-color: rgba(255, 255, 255, 0.75);
}
.tooltip-gray.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-gray.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .tooltip-gray > .tooltip.bs-tooltip-bottom .tooltip-arrow::before, .tooltip-gray > .tooltip.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .ngb-tooltip-gray + ngb-tooltip-window.bs-tooltip-bottom .tooltip-arrow::before, .ngb-tooltip-gray + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: rgba(255, 255, 255, 0.75);
}
.tooltip-gray.bs-tooltip-end .tooltip-arrow::before, .tooltip-gray.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .tooltip-gray > .tooltip.bs-tooltip-end .tooltip-arrow::before, .tooltip-gray > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .ngb-tooltip-gray + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, .ngb-tooltip-gray + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: rgba(255, 255, 255, 0.75);
}
[dir=rtl] .tooltip-gray.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-gray.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .tooltip-gray > .tooltip.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .tooltip-gray > .tooltip.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-gray + ngb-tooltip-window.bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-gray + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-left-color: rgba(255, 255, 255, 0.75);
}

.modal-open .tooltip {
  z-index: 1092;
}

.tooltip-inner {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}

[dir=rtl] .tooltip {
  text-align: right;
}
[dir=rtl] .bs-tooltip-end .tooltip-arrow, [dir=rtl] .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  right: 0;
  left: inherit;
}
[dir=rtl] .bs-tooltip-end .tooltip-arrow::before, [dir=rtl] .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #717784;
}
[dir=rtl] .bs-tooltip-start .tooltip-arrow, [dir=rtl] .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  left: 0;
  right: inherit;
}
[dir=rtl] .bs-tooltip-start .tooltip-arrow::before, [dir=rtl] .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #717784;
}

.popover.popover-secondary, .popover .popover-secondary > .popover, .popover .ngb-popover-secondary + ngb-popover-window {
  border-color: transparent;
  background: #69809a;
}
.popover.popover-secondary .popover-header, .popover .popover-secondary > .popover .popover-header, .popover .ngb-popover-secondary + ngb-popover-window .popover-header {
  background: transparent;
  color: #fff;
}
.popover.popover-secondary .popover-body, .popover .popover-secondary > .popover .popover-body, .popover .ngb-popover-secondary + ngb-popover-window .popover-body {
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
}
.popover.popover-secondary > .popover-arrow::before, .popover .popover-secondary > .popover > .popover-arrow::before, .popover .ngb-popover-secondary + ngb-popover-window > .popover-arrow::before {
  border-color: transparent;
}
.popover.popover-secondary.bs-popover-top > .popover-arrow::after, .popover.popover-secondary.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .popover-secondary > .popover.bs-popover-top > .popover-arrow::after, .popover .popover-secondary > .popover.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-top > .popover-arrow::after, .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-top-color: #69809a;
}
.popover.popover-secondary.bs-popover-end > .popover-arrow::after, .popover.popover-secondary.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .popover-secondary > .popover.bs-popover-end > .popover-arrow::after, .popover .popover-secondary > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-end > .popover-arrow::after, .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-right-color: #69809a;
}
[dir=rtl] .popover.popover-secondary.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover.popover-secondary.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .popover-secondary > .popover.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .popover-secondary > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-left-color: #69809a;
}
.popover.popover-secondary.bs-popover-bottom > .popover-arrow::after, .popover.popover-secondary.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .popover-secondary > .popover.bs-popover-bottom > .popover-arrow::after, .popover .popover-secondary > .popover.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-bottom > .popover-arrow::after, .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-bottom-color: #69809a !important;
}
.popover.popover-secondary.bs-popover-start > .popover-arrow::after, .popover.popover-secondary.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .popover-secondary > .popover.bs-popover-start > .popover-arrow::after, .popover .popover-secondary > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-start > .popover-arrow::after, .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-left-color: #69809a;
}
[dir=rtl] .popover.popover-secondary.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover.popover-secondary.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .popover-secondary > .popover.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .popover-secondary > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-right-color: #69809a;
}
.popover.popover-secondary.bs-popover-bottom .popover-header::before, .popover.popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .popover-secondary > .popover.bs-popover-bottom .popover-header::before, .popover .popover-secondary > .popover.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-bottom .popover-header::before, .popover .ngb-popover-secondary + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom: 1px solid transparent;
}

.popover.popover-success, .popover .popover-success > .popover, .popover .ngb-popover-success + ngb-popover-window {
  border-color: transparent;
  background: #39da8a;
}
.popover.popover-success .popover-header, .popover .popover-success > .popover .popover-header, .popover .ngb-popover-success + ngb-popover-window .popover-header {
  background: transparent;
  color: #fff;
}
.popover.popover-success .popover-body, .popover .popover-success > .popover .popover-body, .popover .ngb-popover-success + ngb-popover-window .popover-body {
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
}
.popover.popover-success > .popover-arrow::before, .popover .popover-success > .popover > .popover-arrow::before, .popover .ngb-popover-success + ngb-popover-window > .popover-arrow::before {
  border-color: transparent;
}
.popover.popover-success.bs-popover-top > .popover-arrow::after, .popover.popover-success.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .popover-success > .popover.bs-popover-top > .popover-arrow::after, .popover .popover-success > .popover.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .ngb-popover-success + ngb-popover-window.bs-popover-top > .popover-arrow::after, .popover .ngb-popover-success + ngb-popover-window.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-top-color: #39da8a;
}
.popover.popover-success.bs-popover-end > .popover-arrow::after, .popover.popover-success.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .popover-success > .popover.bs-popover-end > .popover-arrow::after, .popover .popover-success > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .ngb-popover-success + ngb-popover-window.bs-popover-end > .popover-arrow::after, .popover .ngb-popover-success + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-right-color: #39da8a;
}
[dir=rtl] .popover.popover-success.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover.popover-success.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .popover-success > .popover.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .popover-success > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-success + ngb-popover-window.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-success + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-left-color: #39da8a;
}
.popover.popover-success.bs-popover-bottom > .popover-arrow::after, .popover.popover-success.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .popover-success > .popover.bs-popover-bottom > .popover-arrow::after, .popover .popover-success > .popover.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .ngb-popover-success + ngb-popover-window.bs-popover-bottom > .popover-arrow::after, .popover .ngb-popover-success + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-bottom-color: #39da8a !important;
}
.popover.popover-success.bs-popover-start > .popover-arrow::after, .popover.popover-success.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .popover-success > .popover.bs-popover-start > .popover-arrow::after, .popover .popover-success > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .ngb-popover-success + ngb-popover-window.bs-popover-start > .popover-arrow::after, .popover .ngb-popover-success + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-left-color: #39da8a;
}
[dir=rtl] .popover.popover-success.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover.popover-success.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .popover-success > .popover.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .popover-success > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-success + ngb-popover-window.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-success + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-right-color: #39da8a;
}
.popover.popover-success.bs-popover-bottom .popover-header::before, .popover.popover-success.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .popover-success > .popover.bs-popover-bottom .popover-header::before, .popover .popover-success > .popover.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .ngb-popover-success + ngb-popover-window.bs-popover-bottom .popover-header::before, .popover .ngb-popover-success + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom: 1px solid transparent;
}

.popover.popover-info, .popover .popover-info > .popover, .popover .ngb-popover-info + ngb-popover-window {
  border-color: transparent;
  background: #00cfdd;
}
.popover.popover-info .popover-header, .popover .popover-info > .popover .popover-header, .popover .ngb-popover-info + ngb-popover-window .popover-header {
  background: transparent;
  color: #fff;
}
.popover.popover-info .popover-body, .popover .popover-info > .popover .popover-body, .popover .ngb-popover-info + ngb-popover-window .popover-body {
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
}
.popover.popover-info > .popover-arrow::before, .popover .popover-info > .popover > .popover-arrow::before, .popover .ngb-popover-info + ngb-popover-window > .popover-arrow::before {
  border-color: transparent;
}
.popover.popover-info.bs-popover-top > .popover-arrow::after, .popover.popover-info.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .popover-info > .popover.bs-popover-top > .popover-arrow::after, .popover .popover-info > .popover.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .ngb-popover-info + ngb-popover-window.bs-popover-top > .popover-arrow::after, .popover .ngb-popover-info + ngb-popover-window.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-top-color: #00cfdd;
}
.popover.popover-info.bs-popover-end > .popover-arrow::after, .popover.popover-info.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .popover-info > .popover.bs-popover-end > .popover-arrow::after, .popover .popover-info > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .ngb-popover-info + ngb-popover-window.bs-popover-end > .popover-arrow::after, .popover .ngb-popover-info + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-right-color: #00cfdd;
}
[dir=rtl] .popover.popover-info.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover.popover-info.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .popover-info > .popover.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .popover-info > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-info + ngb-popover-window.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-info + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-left-color: #00cfdd;
}
.popover.popover-info.bs-popover-bottom > .popover-arrow::after, .popover.popover-info.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .popover-info > .popover.bs-popover-bottom > .popover-arrow::after, .popover .popover-info > .popover.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .ngb-popover-info + ngb-popover-window.bs-popover-bottom > .popover-arrow::after, .popover .ngb-popover-info + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-bottom-color: #00cfdd !important;
}
.popover.popover-info.bs-popover-start > .popover-arrow::after, .popover.popover-info.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .popover-info > .popover.bs-popover-start > .popover-arrow::after, .popover .popover-info > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .ngb-popover-info + ngb-popover-window.bs-popover-start > .popover-arrow::after, .popover .ngb-popover-info + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-left-color: #00cfdd;
}
[dir=rtl] .popover.popover-info.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover.popover-info.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .popover-info > .popover.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .popover-info > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-info + ngb-popover-window.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-info + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-right-color: #00cfdd;
}
.popover.popover-info.bs-popover-bottom .popover-header::before, .popover.popover-info.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .popover-info > .popover.bs-popover-bottom .popover-header::before, .popover .popover-info > .popover.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .ngb-popover-info + ngb-popover-window.bs-popover-bottom .popover-header::before, .popover .ngb-popover-info + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom: 1px solid transparent;
}

.popover.popover-warning, .popover .popover-warning > .popover, .popover .ngb-popover-warning + ngb-popover-window {
  border-color: transparent;
  background: #fdac41;
}
.popover.popover-warning .popover-header, .popover .popover-warning > .popover .popover-header, .popover .ngb-popover-warning + ngb-popover-window .popover-header {
  background: transparent;
  color: #fff;
}
.popover.popover-warning .popover-body, .popover .popover-warning > .popover .popover-body, .popover .ngb-popover-warning + ngb-popover-window .popover-body {
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
}
.popover.popover-warning > .popover-arrow::before, .popover .popover-warning > .popover > .popover-arrow::before, .popover .ngb-popover-warning + ngb-popover-window > .popover-arrow::before {
  border-color: transparent;
}
.popover.popover-warning.bs-popover-top > .popover-arrow::after, .popover.popover-warning.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .popover-warning > .popover.bs-popover-top > .popover-arrow::after, .popover .popover-warning > .popover.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .ngb-popover-warning + ngb-popover-window.bs-popover-top > .popover-arrow::after, .popover .ngb-popover-warning + ngb-popover-window.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-top-color: #fdac41;
}
.popover.popover-warning.bs-popover-end > .popover-arrow::after, .popover.popover-warning.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .popover-warning > .popover.bs-popover-end > .popover-arrow::after, .popover .popover-warning > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .ngb-popover-warning + ngb-popover-window.bs-popover-end > .popover-arrow::after, .popover .ngb-popover-warning + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-right-color: #fdac41;
}
[dir=rtl] .popover.popover-warning.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover.popover-warning.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .popover-warning > .popover.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .popover-warning > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-warning + ngb-popover-window.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-warning + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-left-color: #fdac41;
}
.popover.popover-warning.bs-popover-bottom > .popover-arrow::after, .popover.popover-warning.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .popover-warning > .popover.bs-popover-bottom > .popover-arrow::after, .popover .popover-warning > .popover.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .ngb-popover-warning + ngb-popover-window.bs-popover-bottom > .popover-arrow::after, .popover .ngb-popover-warning + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-bottom-color: #fdac41 !important;
}
.popover.popover-warning.bs-popover-start > .popover-arrow::after, .popover.popover-warning.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .popover-warning > .popover.bs-popover-start > .popover-arrow::after, .popover .popover-warning > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .ngb-popover-warning + ngb-popover-window.bs-popover-start > .popover-arrow::after, .popover .ngb-popover-warning + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-left-color: #fdac41;
}
[dir=rtl] .popover.popover-warning.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover.popover-warning.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .popover-warning > .popover.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .popover-warning > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-warning + ngb-popover-window.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-warning + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-right-color: #fdac41;
}
.popover.popover-warning.bs-popover-bottom .popover-header::before, .popover.popover-warning.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .popover-warning > .popover.bs-popover-bottom .popover-header::before, .popover .popover-warning > .popover.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .ngb-popover-warning + ngb-popover-window.bs-popover-bottom .popover-header::before, .popover .ngb-popover-warning + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom: 1px solid transparent;
}

.popover.popover-danger, .popover .popover-danger > .popover, .popover .ngb-popover-danger + ngb-popover-window {
  border-color: transparent;
  background: #ff5b5c;
}
.popover.popover-danger .popover-header, .popover .popover-danger > .popover .popover-header, .popover .ngb-popover-danger + ngb-popover-window .popover-header {
  background: transparent;
  color: #fff;
}
.popover.popover-danger .popover-body, .popover .popover-danger > .popover .popover-body, .popover .ngb-popover-danger + ngb-popover-window .popover-body {
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
}
.popover.popover-danger > .popover-arrow::before, .popover .popover-danger > .popover > .popover-arrow::before, .popover .ngb-popover-danger + ngb-popover-window > .popover-arrow::before {
  border-color: transparent;
}
.popover.popover-danger.bs-popover-top > .popover-arrow::after, .popover.popover-danger.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .popover-danger > .popover.bs-popover-top > .popover-arrow::after, .popover .popover-danger > .popover.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .ngb-popover-danger + ngb-popover-window.bs-popover-top > .popover-arrow::after, .popover .ngb-popover-danger + ngb-popover-window.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-top-color: #ff5b5c;
}
.popover.popover-danger.bs-popover-end > .popover-arrow::after, .popover.popover-danger.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .popover-danger > .popover.bs-popover-end > .popover-arrow::after, .popover .popover-danger > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .ngb-popover-danger + ngb-popover-window.bs-popover-end > .popover-arrow::after, .popover .ngb-popover-danger + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-right-color: #ff5b5c;
}
[dir=rtl] .popover.popover-danger.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover.popover-danger.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .popover-danger > .popover.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .popover-danger > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-danger + ngb-popover-window.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-danger + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-left-color: #ff5b5c;
}
.popover.popover-danger.bs-popover-bottom > .popover-arrow::after, .popover.popover-danger.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .popover-danger > .popover.bs-popover-bottom > .popover-arrow::after, .popover .popover-danger > .popover.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .ngb-popover-danger + ngb-popover-window.bs-popover-bottom > .popover-arrow::after, .popover .ngb-popover-danger + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-bottom-color: #ff5b5c !important;
}
.popover.popover-danger.bs-popover-start > .popover-arrow::after, .popover.popover-danger.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .popover-danger > .popover.bs-popover-start > .popover-arrow::after, .popover .popover-danger > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .ngb-popover-danger + ngb-popover-window.bs-popover-start > .popover-arrow::after, .popover .ngb-popover-danger + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-left-color: #ff5b5c;
}
[dir=rtl] .popover.popover-danger.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover.popover-danger.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .popover-danger > .popover.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .popover-danger > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-danger + ngb-popover-window.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-danger + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-right-color: #ff5b5c;
}
.popover.popover-danger.bs-popover-bottom .popover-header::before, .popover.popover-danger.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .popover-danger > .popover.bs-popover-bottom .popover-header::before, .popover .popover-danger > .popover.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .ngb-popover-danger + ngb-popover-window.bs-popover-bottom .popover-header::before, .popover .ngb-popover-danger + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom: 1px solid transparent;
}

.popover.popover-dark, .popover .popover-dark > .popover, .popover .ngb-popover-dark + ngb-popover-window {
  border-color: transparent;
  background: #717784;
}
.popover.popover-dark .popover-header, .popover .popover-dark > .popover .popover-header, .popover .ngb-popover-dark + ngb-popover-window .popover-header {
  background: transparent;
  color: #fff;
}
.popover.popover-dark .popover-body, .popover .popover-dark > .popover .popover-body, .popover .ngb-popover-dark + ngb-popover-window .popover-body {
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
}
.popover.popover-dark > .popover-arrow::before, .popover .popover-dark > .popover > .popover-arrow::before, .popover .ngb-popover-dark + ngb-popover-window > .popover-arrow::before {
  border-color: transparent;
}
.popover.popover-dark.bs-popover-top > .popover-arrow::after, .popover.popover-dark.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .popover-dark > .popover.bs-popover-top > .popover-arrow::after, .popover .popover-dark > .popover.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .ngb-popover-dark + ngb-popover-window.bs-popover-top > .popover-arrow::after, .popover .ngb-popover-dark + ngb-popover-window.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-top-color: #717784;
}
.popover.popover-dark.bs-popover-end > .popover-arrow::after, .popover.popover-dark.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .popover-dark > .popover.bs-popover-end > .popover-arrow::after, .popover .popover-dark > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .ngb-popover-dark + ngb-popover-window.bs-popover-end > .popover-arrow::after, .popover .ngb-popover-dark + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-right-color: #717784;
}
[dir=rtl] .popover.popover-dark.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover.popover-dark.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .popover-dark > .popover.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .popover-dark > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-dark + ngb-popover-window.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-dark + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-left-color: #717784;
}
.popover.popover-dark.bs-popover-bottom > .popover-arrow::after, .popover.popover-dark.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .popover-dark > .popover.bs-popover-bottom > .popover-arrow::after, .popover .popover-dark > .popover.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .ngb-popover-dark + ngb-popover-window.bs-popover-bottom > .popover-arrow::after, .popover .ngb-popover-dark + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-bottom-color: #717784 !important;
}
.popover.popover-dark.bs-popover-start > .popover-arrow::after, .popover.popover-dark.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .popover-dark > .popover.bs-popover-start > .popover-arrow::after, .popover .popover-dark > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .ngb-popover-dark + ngb-popover-window.bs-popover-start > .popover-arrow::after, .popover .ngb-popover-dark + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-left-color: #717784;
}
[dir=rtl] .popover.popover-dark.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover.popover-dark.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .popover-dark > .popover.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .popover-dark > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-dark + ngb-popover-window.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-dark + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-right-color: #717784;
}
.popover.popover-dark.bs-popover-bottom .popover-header::before, .popover.popover-dark.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .popover-dark > .popover.bs-popover-bottom .popover-header::before, .popover .popover-dark > .popover.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .ngb-popover-dark + ngb-popover-window.bs-popover-bottom .popover-header::before, .popover .ngb-popover-dark + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom: 1px solid transparent;
}

.popover.popover-gray, .popover .popover-gray > .popover, .popover .ngb-popover-gray + ngb-popover-window {
  border-color: transparent;
  background: rgba(255, 255, 255, 0.75);
}
.popover.popover-gray .popover-header, .popover .popover-gray > .popover .popover-header, .popover .ngb-popover-gray + ngb-popover-window .popover-header {
  background: transparent;
  color: #000;
}
.popover.popover-gray .popover-body, .popover .popover-gray > .popover .popover-body, .popover .ngb-popover-gray + ngb-popover-window .popover-body {
  background: transparent;
  color: rgba(0, 0, 0, 0.8);
}
.popover.popover-gray > .popover-arrow::before, .popover .popover-gray > .popover > .popover-arrow::before, .popover .ngb-popover-gray + ngb-popover-window > .popover-arrow::before {
  border-color: transparent;
}
.popover.popover-gray.bs-popover-top > .popover-arrow::after, .popover.popover-gray.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .popover-gray > .popover.bs-popover-top > .popover-arrow::after, .popover .popover-gray > .popover.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .popover .ngb-popover-gray + ngb-popover-window.bs-popover-top > .popover-arrow::after, .popover .ngb-popover-gray + ngb-popover-window.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-top-color: rgba(255, 255, 255, 0.75);
}
.popover.popover-gray.bs-popover-end > .popover-arrow::after, .popover.popover-gray.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .popover-gray > .popover.bs-popover-end > .popover-arrow::after, .popover .popover-gray > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .popover .ngb-popover-gray + ngb-popover-window.bs-popover-end > .popover-arrow::after, .popover .ngb-popover-gray + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-right-color: rgba(255, 255, 255, 0.75);
}
[dir=rtl] .popover.popover-gray.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover.popover-gray.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .popover-gray > .popover.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .popover-gray > .popover.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-gray + ngb-popover-window.bs-popover-end > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-gray + ngb-popover-window.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-left-color: rgba(255, 255, 255, 0.75);
}
.popover.popover-gray.bs-popover-bottom > .popover-arrow::after, .popover.popover-gray.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .popover-gray > .popover.bs-popover-bottom > .popover-arrow::after, .popover .popover-gray > .popover.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .popover .ngb-popover-gray + ngb-popover-window.bs-popover-bottom > .popover-arrow::after, .popover .ngb-popover-gray + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-bottom-color: rgba(255, 255, 255, 0.75) !important;
}
.popover.popover-gray.bs-popover-start > .popover-arrow::after, .popover.popover-gray.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .popover-gray > .popover.bs-popover-start > .popover-arrow::after, .popover .popover-gray > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .popover .ngb-popover-gray + ngb-popover-window.bs-popover-start > .popover-arrow::after, .popover .ngb-popover-gray + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-left-color: rgba(255, 255, 255, 0.75);
}
[dir=rtl] .popover.popover-gray.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover.popover-gray.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .popover-gray > .popover.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .popover-gray > .popover.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-gray + ngb-popover-window.bs-popover-start > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-gray + ngb-popover-window.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-right-color: rgba(255, 255, 255, 0.75);
}
.popover.popover-gray.bs-popover-bottom .popover-header::before, .popover.popover-gray.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .popover-gray > .popover.bs-popover-bottom .popover-header::before, .popover .popover-gray > .popover.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .popover .ngb-popover-gray + ngb-popover-window.bs-popover-bottom .popover-header::before, .popover .ngb-popover-gray + ngb-popover-window.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom: 1px solid transparent;
}

.modal-open .popover {
  z-index: 1091;
}

.popover {
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.popover .popover-arrow {
  z-index: 1;
}
.popover.bs-popover-bottom > .popover-arrow::after, .popover.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-bottom-color: transparent;
  top: 2px;
}
.popover.bs-popover-bottom > .popover-arrow:before, .popover.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow:before {
  top: 1px;
}

.popover-header {
  padding: 1rem 1rem 0;
  font-size: 1.125rem;
}

[dir=rtl] .popover {
  text-align: right;
}
[dir=rtl] .bs-popover-end > .popover-arrow, [dir=rtl] .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  right: calc(-0.5rem - 0px);
  left: inherit;
}
[dir=rtl] .bs-popover-end > .popover-arrow::before, [dir=rtl] .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  right: 0;
  left: inherit;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #283144;
}
[dir=rtl] .bs-popover-end > .popover-arrow::after, [dir=rtl] .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  right: 0px;
  left: inherit;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #283144;
}
[dir=rtl] .bs-popover-bottom .popover-header::before, [dir=rtl] .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  right: 50%;
  margin-right: -0.5rem;
}
[dir=rtl] .bs-popover-start > .popover-arrow, [dir=rtl] .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  left: calc(-0.5rem - 0px);
  right: inherit;
}
[dir=rtl] .bs-popover-start > .popover-arrow::before, [dir=rtl] .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  left: 0;
  right: inherit;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #283144;
}
[dir=rtl] .bs-popover-start > .popover-arrow::after, [dir=rtl] .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  left: 0px;
  right: inherit;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #283144;
}

.form-label,
.col-form-label {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: inherit;
}

.form-label-lg {
  font-size: 1.063rem;
}

.form-label-sm {
  font-size: 0.813rem;
}

.form-control::placeholder {
  transition: all 0.25s ease;
}
.form-control:focus::placeholder {
  transform: translate(5px);
  transition: all 0.25s ease;
}

.form-select {
  background-clip: padding-box;
}

[dir=rtl] .form-select {
  padding: 0.469rem 0.735rem 0.469rem 1.735rem;
  background-position: left 0.735rem center;
}
[dir=rtl] .form-select[multiple], [dir=rtl] .form-select[size]:not([size="1"]) {
  padding-left: 0.735rem;
  background-image: none;
}
[dir=rtl] .form-select-sm {
  padding-top: 0.187rem;
  padding-bottom: 0.187rem;
  padding-right: 0.6rem;
}
[dir=rtl] .form-select-lg {
  padding-top: 0.74rem;
  padding-bottom: 0.74rem;
  padding-right: 0.85rem;
}

select.form-select option {
  background-color: #283144;
}

[dir=rtl] .form-check {
  padding-left: 0;
  padding-right: 1.7em;
}
[dir=rtl] .form-check .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.7em;
}

[dir=rtl] .form-switch {
  padding-left: 0;
  padding-right: 2.5em;
}
[dir=rtl] .form-switch .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
  background-position: right center;
}
[dir=rtl] .form-switch .form-check-input:checked {
  background-position: left center;
}
[dir=rtl] .form-check-inline {
  margin-right: 0;
  margin-left: 1rem;
}

.form-check-secondary .form-check-input:focus {
  border-color: #69809a;
}
.form-check-secondary .form-check-input:checked {
  background-color: #69809a;
  border-color: #69809a;
}
.form-check-secondary .form-check-input[type=checkbox]:indeterminate {
  background-color: #69809a;
  border-color: #69809a;
}

.form-check-secondary.custom-option.checked {
  border: 1px solid #69809a;
}

.form-check-success .form-check-input:focus {
  border-color: #39da8a;
}
.form-check-success .form-check-input:checked {
  background-color: #39da8a;
  border-color: #39da8a;
}
.form-check-success .form-check-input[type=checkbox]:indeterminate {
  background-color: #39da8a;
  border-color: #39da8a;
}

.form-check-success.custom-option.checked {
  border: 1px solid #39da8a;
}

.form-check-info .form-check-input:focus {
  border-color: #00cfdd;
}
.form-check-info .form-check-input:checked {
  background-color: #00cfdd;
  border-color: #00cfdd;
}
.form-check-info .form-check-input[type=checkbox]:indeterminate {
  background-color: #00cfdd;
  border-color: #00cfdd;
}

.form-check-info.custom-option.checked {
  border: 1px solid #00cfdd;
}

.form-check-warning .form-check-input:focus {
  border-color: #fdac41;
}
.form-check-warning .form-check-input:checked {
  background-color: #fdac41;
  border-color: #fdac41;
}
.form-check-warning .form-check-input[type=checkbox]:indeterminate {
  background-color: #fdac41;
  border-color: #fdac41;
}

.form-check-warning.custom-option.checked {
  border: 1px solid #fdac41;
}

.form-check-danger .form-check-input:focus {
  border-color: #ff5b5c;
}
.form-check-danger .form-check-input:checked {
  background-color: #ff5b5c;
  border-color: #ff5b5c;
}
.form-check-danger .form-check-input[type=checkbox]:indeterminate {
  background-color: #ff5b5c;
  border-color: #ff5b5c;
}

.form-check-danger.custom-option.checked {
  border: 1px solid #ff5b5c;
}

.form-check-light .form-check-input:focus {
  border-color: #434c5f;
}
.form-check-light .form-check-input:checked {
  background-color: #434c5f;
  border-color: #434c5f;
}
.form-check-light .form-check-input[type=checkbox]:indeterminate {
  background-color: #434c5f;
  border-color: #434c5f;
}

.form-check-light.custom-option.checked {
  border: 1px solid #434c5f;
}

.form-check-dark .form-check-input:focus {
  border-color: #717784;
}
.form-check-dark .form-check-input:checked {
  background-color: #717784;
  border-color: #717784;
}
.form-check-dark .form-check-input[type=checkbox]:indeterminate {
  background-color: #717784;
  border-color: #717784;
}

.form-check-dark.custom-option.checked {
  border: 1px solid #717784;
}

.form-check-gray .form-check-input:focus {
  border-color: rgba(255, 255, 255, 0.75);
}
.form-check-gray .form-check-input:checked {
  background-color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.75);
}
.form-check-gray .form-check-input[type=checkbox]:indeterminate {
  background-color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.75);
}

.form-check-gray.custom-option.checked {
  border: 1px solid rgba(255, 255, 255, 0.75);
}

.form-range::-webkit-slider-thumb {
  box-shadow: 0 0 6px 0 rgba(255, 255, 255, 0.6);
  transition: transform 0.2s;
  transform-origin: center;
}
.form-range::-webkit-slider-thumb:focus {
  box-shadow: 0 0 8px 0px rgba(255, 255, 255, 0.6);
}
.form-range::-webkit-slider-thumb:active {
  transform: scale(1.4, 1.4);
}
.form-range::-moz-range-thumb {
  box-shadow: 0 0 6px 0 rgba(255, 255, 255, 0.6);
  transition: transform 0.2s;
  transform-origin: center;
}
.form-range::-moz-range-thumb:focus {
  box-shadow: 0 0 8px 0px rgba(255, 255, 255, 0.6);
}
.form-range::-moz-range-thumb:active {
  transform: scale(1.4, 1.4);
}
.form-range:disabled::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.03);
}
.form-range:disabled::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.03);
  box-shadow: none;
}
.form-range:disabled::-webkit-slider-thumb {
  box-shadow: none;
}
.form-range:disabled::-moz-range-thumb {
  box-shadow: none;
}

.input-group:focus-within {
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(99, 123, 169, 0.18);
}
.input-group:focus-within .form-control,
.input-group:focus-within .input-group-text {
  box-shadow: none;
}
.input-group.disabled .input-group-text {
  background-color: #36445d;
}
.input-group.has-validation > .input-group-text:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-text {
  background-clip: padding-box;
}
.input-group-text i {
  font-size: 0.9375rem;
}

.input-group-lg > .input-group-text i {
  font-size: 1.063rem;
}

.input-group-sm > .input-group-text i {
  font-size: 0.813rem;
}

html:not([dir=rtl]) .input-group-merge .input-group-text:first-child {
  border-right: 0;
}
html:not([dir=rtl]) .input-group-merge .input-group-text:last-child {
  border-left: 0;
}
html:not([dir=rtl]) .input-group-merge .form-control:not(:first-child) {
  padding-left: 0;
  border-left: 0;
}
html:not([dir=rtl]) .input-group-merge .form-control:not(:last-child) {
  padding-right: 0;
  border-right: 0;
}

.input-group-text {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .input-group-text {
    transition: none;
  }
}

[dir=rtl] .input-group-lg > .form-select,
[dir=rtl] .input-group-sm > .form-select {
  padding-left: 2.47rem;
}
[dir=rtl] .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
[dir=rtl] .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[dir=rtl] .input-group.input-group-lg:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
[dir=rtl] .input-group.input-group-lg:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
[dir=rtl] .input-group.input-group-sm:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
[dir=rtl] .input-group.input-group-sm:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}
[dir=rtl] .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
[dir=rtl] .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[dir=rtl] .input-group.input-group-lg > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
[dir=rtl] .input-group.input-group-lg > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
[dir=rtl] .input-group.input-group-sm > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
[dir=rtl] .input-group.input-group-sm > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}
[dir=rtl] .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: 0px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
[dir=rtl] .input-group.input-group-lg > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
[dir=rtl] .input-group.input-group-sm > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}
[dir=rtl] .input-group > :not(:first-child):not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
[dir=rtl] .input-group > .dropdown-toggle:nth-last-child(n+3) {
  border-radius: 0 !important;
}
[dir=rtl] .input-group.has-validation > .input-group-text:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[dir=rtl] .input-group.has-validation > .form-control:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[dir=rtl] .input-group-merge .input-group-text:first-child {
  border-left: 0;
}
[dir=rtl] .input-group-merge .input-group-text:last-child {
  border-right: 0;
}
[dir=rtl] .input-group-merge .form-control:not(:first-child) {
  padding-right: 0;
  border-right: 0;
}
[dir=rtl] .input-group-merge .form-control:not(:last-child) {
  padding-left: 0;
  border-left: 0;
}

.fv-plugins-bootstrap5-row-invalid .input-group.has-validation .input-group-text,
.fv-plugins-bootstrap5-row-invalid .input-group.has-validation:focus-within .input-group-text {
  border-color: #ff5b5c;
}

.form-floating > .form-control:focus::placeholder,
.form-floating > .form-control:not(:placeholder-shown)::placeholder {
  color: #8295ba;
}

[dir=rtl] .form-floating > label {
  right: 0;
  transform-origin: 100% 0;
}
[dir=rtl] .form-floating > .form-control:focus ~ label,
[dir=rtl] .form-floating > .form-control:not(:placeholder-shown) ~ label,
[dir=rtl] .form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-0.5rem) translateX(-0.15rem);
}
[dir=rtl] .form-floating > .form-control:-webkit-autofill ~ label {
  transform: scale(0.85) translateY(-0.5rem) translateX(-0.15rem);
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 85%;
  color: #39da8a;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.7rem;
  margin-top: 0.1rem;
  font-size: 0.9375rem;
  color: #fff;
  background-color: #39da8a;
  border-radius: 0.1875rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #39da8a;
}
.dark-style .was-validated .form-control:valid, .dark-style .form-control.is-valid {
  border-color: #39da8a !important;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #39da8a;
  box-shadow: 0 0 0.1875rem 0.0625rem rgba(57, 218, 138, 0.18);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #39da8a;
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #39da8a;
  box-shadow: 0 0 0.1875rem 0.0625rem rgba(57, 218, 138, 0.18);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #39da8a;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #39da8a;
  border-color: #39da8a;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0.1875rem 0.0625rem rgba(57, 218, 138, 0.18);
  border-color: #39da8a;
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #39da8a;
}

html:not([dir=rtl]) .form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
[dir=rtl] .form-check-inline .form-check-input ~ .valid-feedback {
  margin-right: 0.5em;
}

.was-validated .input-group .form-control:valid ~ .input-group-text, .input-group .form-control.is-valid ~ .input-group-text {
  border-color: #39da8a;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus {
  border-color: #39da8a;
  box-shadow: none;
}
.was-validated .input-group .form-control:valid:focus ~ .input-group-text, .input-group .form-control.is-valid:focus ~ .input-group-text {
  border-color: #39da8a;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 85%;
  color: #ff5b5c;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.7rem;
  margin-top: 0.1rem;
  font-size: 0.9375rem;
  color: #fff;
  background-color: #ff5b5c;
  border-radius: 0.1875rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ff5b5c;
}
.dark-style .was-validated .form-control:invalid, .dark-style .form-control.is-invalid {
  border-color: #ff5b5c !important;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ff5b5c;
  box-shadow: 0 0 0.1875rem 0.0625rem rgba(255, 91, 92, 0.18);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ff5b5c;
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #ff5b5c;
  box-shadow: 0 0 0.1875rem 0.0625rem rgba(255, 91, 92, 0.18);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ff5b5c;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #ff5b5c;
  border-color: #ff5b5c;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0.1875rem 0.0625rem rgba(255, 91, 92, 0.18);
  border-color: #ff5b5c;
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff5b5c;
}

html:not([dir=rtl]) .form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
[dir=rtl] .form-check-inline .form-check-input ~ .invalid-feedback {
  margin-right: 0.5em;
}

.was-validated .input-group .form-control:invalid ~ .input-group-text, .input-group .form-control.is-invalid ~ .input-group-text {
  border-color: #ff5b5c;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus {
  border-color: #ff5b5c;
  box-shadow: none;
}
.was-validated .input-group .form-control:invalid:focus ~ .input-group-text, .input-group .form-control.is-invalid:focus ~ .input-group-text {
  border-color: #ff5b5c;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 3;
}

form .error:not(li):not(input) {
  color: #ff5b5c;
  font-size: 85%;
  margin-top: 0.25rem;
}
form .invalid,
form .is-invalid .invalid:before,
form .is-invalid::before {
  border-color: #ff5b5c !important;
}
form .form-label.invalid, form .form-label.is-invalid {
  border-color: #ff5b5c;
  box-shadow: 0 0 0 2px rgba(255, 91, 92, 0.4) !important;
}
form select.invalid ~ .select2 .select2-selection {
  border-color: #ff5b5c;
}
form select.is-invalid ~ .select2 .select2-selection {
  border-color: #ff5b5c !important;
}
form select.selectpicker.is-invalid ~ .btn {
  border-color: 1px solid #ff5b5c;
  border-color: #ff5b5c;
}

@media (hover: none) {
  .fv-plugins-bootstrap5-row-invalid .form-control {
    border-color: #ff5b5c;
  }
}
.fv-plugins-bootstrap5 .invalid-feedback,
.fv-plugins-bootstrap5 .invalid-tooltip {
  display: block;
}

.fv-plugins-bootstrap5-row-invalid .tagify.tagify--empty {
  border-color: #ff5b5c !important;
}

.modal-content {
  box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.28);
}

.modal .btn-close {
  background-color: rgba(99, 123, 169, 0.16);
  padding: 0.5625rem 0.5625rem;
  margin: 0 0 0 auto;
  z-index: 1;
}

.modal-footer {
  padding: 0 1.5rem 1.375rem;
}

[dir=rtl] .modal,
[dir=rtl] .modal-backdrop {
  right: 0;
}
[dir=rtl] .modal-header .btn-close {
  margin-left: 0;
  margin-right: auto;
}

.modal-onboarding .close-label {
  font-size: 0.8rem;
  position: absolute;
  top: 1.5rem;
  opacity: 0.95;
}
.modal-onboarding .close-label:hover {
  opacity: 0.95;
}
.modal-onboarding .onboarding-media {
  margin-bottom: 1rem;
}
.modal-onboarding .onboarding-media img {
  margin: 0 auto;
}
.modal-onboarding .onboarding-content {
  margin: 2rem;
}
.modal-onboarding form {
  margin-top: 2rem;
  text-align: left;
}
.modal-onboarding .carousel-indicators {
  bottom: -10px;
}
.modal-onboarding .carousel-control-prev,
.modal-onboarding .carousel-control-next {
  top: auto;
  bottom: 0.75rem;
  opacity: 1;
}
[dir=rtl] .modal-onboarding .carousel-control-prev,
[dir=rtl] .modal-onboarding .carousel-control-next {
  flex-direction: row-reverse;
}
.modal-onboarding .carousel-control-prev {
  left: 1rem;
}
.modal-onboarding .onboarding-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-onboarding .onboarding-horizontal .onboarding-media {
  margin: 2rem;
  margin-top: 0;
}
.modal-onboarding .onboarding-horizontal .carousel-control-prev {
  left: 0;
}
.modal-onboarding.animated .onboarding-media {
  transform: translateY(10px) scale(0.8);
  transition: all 0.5s cubic-bezier(0.25, 1.1, 0.5, 1.35);
  transition-delay: 0.3s;
  opacity: 0;
}
.modal-onboarding.animated .onboarding-content {
  transform: translateY(40px);
  transition-delay: 0.1s;
  transition: all 0.4s ease;
  opacity: 0;
}
.modal-onboarding.animated .onboarding-title {
  opacity: 0;
  transition-delay: 0.5s;
  transition: all 0.5s cubic-bezier(0.25, 1.1, 0.5, 1.35);
  transform: translateY(40px);
}
.modal-onboarding.animated .onboarding-info {
  opacity: 0;
  transition-delay: 0.6s;
  transition: all 0.5s cubic-bezier(0.25, 1.1, 0.5, 1.35);
  transform: translateY(40px);
}
.modal-onboarding.animated form {
  opacity: 0;
  transition-delay: 0.7s;
  transition: all 0.5s ease;
  transform: translateY(40px);
}
.modal-onboarding.animated.show .onboarding-media {
  transform: translateY(0) scale(1);
  opacity: 1;
}
.modal-onboarding.animated.show .onboarding-content {
  transform: translateY(0);
  opacity: 1;
}
.modal-onboarding.animated.show .onboarding-title {
  transform: translateY(0);
  opacity: 1;
}
.modal-onboarding.animated.show .onboarding-info {
  opacity: 1;
  transform: translateY(0px);
}
.modal-onboarding.animated.show form {
  opacity: 1;
  transform: translateY(0px);
}

.modal-top .modal-dialog {
  margin-top: 0;
}
.modal-top .modal-content {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-transparent .modal-dialog {
  display: flex;
  margin: 0 auto;
  min-height: 100vh;
}
.modal-transparent .modal-content {
  margin: auto;
  width: 100%;
  border: 0;
  background: transparent;
  box-shadow: none;
}
.modal-transparent .btn-close {
  position: absolute;
  top: 0;
  right: 1.5rem;
  transform: none;
  opacity: 1;
  padding: 0.25em 0.25em;
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-color: transparent !important;
}
[dir=rtl] .modal-transparent .btn-close {
  right: auto;
  left: 1.5rem;
}

.modal-simple .modal-content {
  padding: 3rem;
}
.modal-simple .btn-close {
  position: absolute;
  top: -1.625rem;
}
[dir=rtl] .modal-simple .btn-close {
  left: -1.625rem;
}
html:not([dir=rtl]) .modal-simple .btn-close {
  right: -1.625rem;
}
@media (max-width: 767.98px) {
  .modal-simple .btn-close {
    top: 0;
  }
  [dir=rtl] .modal-simple .btn-close {
    left: 0;
  }
  html:not([dir=rtl]) .modal-simple .btn-close {
    right: 0;
  }
}

.modal-refer-and-earn .modal-refer-and-earn-step {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}
.modal-refer-and-earn .modal-refer-and-earn-step i {
  font-size: 2.5rem;
}

.modal-top.fade .modal-dialog,
.modal-top .modal.fade .modal-dialog {
  transform: translateY(-100%);
}

.modal-top.show .modal-dialog,
.modal-top .modal.show .modal-dialog {
  transform: translateY(0);
}

.modal-transparent.fade .modal-dialog,
.modal-transparent .modal.fade .modal-dialog {
  transform: scale(0.5, 0.5);
}

.modal-transparent.show .modal-dialog,
.modal-transparent .modal.show .modal-dialog {
  transform: scale(1, 1);
}

@media (max-width: 991.98px) {
  .modal-onboarding .onboarding-horizontal {
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  .modal .modal-dialog:not(.modal-fullscreen) {
    padding: 0 0.75rem;
    padding-left: 0.75rem !important;
  }
  .modal .carousel-control-prev,
.modal .carousel-control-next {
    display: none;
  }
}
@media (min-width: 576px) {
  .modal-content {
    box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.28);
  }

  .modal-sm .modal-dialog {
    max-width: 22.5rem;
  }
}
@media (min-width: 1200px) {
  .modal-xl .modal-dialog {
    max-width: 1140px;
  }
}
.progress.progress-stacked {
  background-color: #283144;
}
.progress.progress-stacked > .progress-bar:not(:last-child) {
  margin-right: 0.35rem;
}

html:not([dir=rtl]) .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.07) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.07) 50%, rgba(255, 255, 255, 0.07) 75%, transparent 75%, transparent);
}
html:not([dir=rtl]) .progress .progress-bar:last-child {
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
}
html:not([dir=rtl]) .progress .progress-bar:first-child {
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
}

[dir=rtl] .progress-bar-striped {
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.07) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.07) 50%, rgba(255, 255, 255, 0.07) 75%, transparent 75%, transparent);
}
[dir=rtl] .progress-bar-animated {
  animation-direction: reverse;
}
[dir=rtl] .progress .progress-bar:last-child {
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
}
[dir=rtl] .progress .progress-bar:first-child {
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
}

.breadcrumb-item,
.breadcrumb-item a {
  color: #a1b0cb;
}
.breadcrumb-item:hover, .breadcrumb-item:focus,
.breadcrumb-item a:hover,
.breadcrumb-item a:focus {
  color: #a1b0cb;
}
.breadcrumb-item.active,
.breadcrumb-item a.active {
  font-weight: 600;
}
.breadcrumb-item.active::before,
.breadcrumb-item a.active::before {
  font-weight: 400;
}

.breadcrumb-item.active a, .breadcrumb-item.active a:hover, .breadcrumb-item.active a:focus, .breadcrumb-item.active a:active {
  color: inherit;
}

.breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
  font-family: boxicons;
  vertical-align: middle;
}

.breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before {
  content: "\ecb3";
  font-size: 1.3rem;
  line-height: 1.2;
}

.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
  content: "\ee4a";
  font-size: 1.063rem;
  line-height: 1.39rem;
}

[dir=rtl] .breadcrumb-item + .breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: 0;
}
[dir=rtl] .breadcrumb-item + .breadcrumb-item::before {
  content: "\\";
  padding-right: 0;
  padding-left: 0.5rem;
  float: right;
}
[dir=rtl] .breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before {
  content: "\ecb0";
}
[dir=rtl] .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
  content: "\ed97";
}

.list-group-item-secondary {
  background-color: #3c495e;
  color: #69809a !important;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #69809a;
}
a.list-group-item-secondary:hover, a.list-group-item-secondary:focus,
button.list-group-item-secondary:hover,
button.list-group-item-secondary:focus {
  background-color: #364255;
  color: #69809a;
}
a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
  border-color: #69809a;
  background-color: #69809a;
  color: #69809a;
}

.list-group.list-group-timeline .list-group-timeline-secondary:before {
  background-color: #69809a;
}

.list-group-item-success {
  background-color: #2d6459;
  color: #39da8a !important;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #39da8a;
}
a.list-group-item-success:hover, a.list-group-item-success:focus,
button.list-group-item-success:hover,
button.list-group-item-success:focus {
  background-color: #295a50;
  color: #39da8a;
}
a.list-group-item-success.active,
button.list-group-item-success.active {
  border-color: #39da8a;
  background-color: #39da8a;
  color: #39da8a;
}

.list-group.list-group-timeline .list-group-timeline-success:before {
  background-color: #39da8a;
}

.list-group-item-info {
  background-color: #1c6072;
  color: #00cfdd !important;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #00cfdd;
}
a.list-group-item-info:hover, a.list-group-item-info:focus,
button.list-group-item-info:hover,
button.list-group-item-info:focus {
  background-color: #195667;
  color: #00cfdd;
}
a.list-group-item-info.active,
button.list-group-item-info.active {
  border-color: #00cfdd;
  background-color: #00cfdd;
  color: #00cfdd;
}

.list-group.list-group-timeline .list-group-timeline-info:before {
  background-color: #00cfdd;
}

.list-group-item-warning {
  background-color: #685643;
  color: #fdac41 !important;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #fdac41;
}
a.list-group-item-warning:hover, a.list-group-item-warning:focus,
button.list-group-item-warning:hover,
button.list-group-item-warning:focus {
  background-color: #5e4d3c;
  color: #fdac41;
}
a.list-group-item-warning.active,
button.list-group-item-warning.active {
  border-color: #fdac41;
  background-color: #fdac41;
  color: #fdac41;
}

.list-group.list-group-timeline .list-group-timeline-warning:before {
  background-color: #fdac41;
}

.list-group-item-danger {
  background-color: #693e4b;
  color: #ff5b5c !important;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #ff5b5c;
}
a.list-group-item-danger:hover, a.list-group-item-danger:focus,
button.list-group-item-danger:hover,
button.list-group-item-danger:focus {
  background-color: #5f3844;
  color: #ff5b5c;
}
a.list-group-item-danger.active,
button.list-group-item-danger.active {
  border-color: #ff5b5c;
  background-color: #ff5b5c;
  color: #ff5b5c;
}

.list-group.list-group-timeline .list-group-timeline-danger:before {
  background-color: #ff5b5c;
}

.list-group-item-dark {
  background-color: #3e4657;
  color: #fff !important;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: #fff;
}
a.list-group-item-dark:hover, a.list-group-item-dark:focus,
button.list-group-item-dark:hover,
button.list-group-item-dark:focus {
  background-color: #383f4e;
  color: #fff;
}
a.list-group-item-dark.active,
button.list-group-item-dark.active {
  border-color: #717784;
  background-color: #717784;
  color: #fff;
}

.list-group.list-group-timeline .list-group-timeline-dark:before {
  background-color: #717784;
}

.list-group-item-gray {
  background-color: rgba(84, 91, 106, 0.925);
  color: rgba(255, 255, 255, 0.75) !important;
}

a.list-group-item-gray,
button.list-group-item-gray {
  color: rgba(255, 255, 255, 0.75);
}
a.list-group-item-gray:hover, a.list-group-item-gray:focus,
button.list-group-item-gray:hover,
button.list-group-item-gray:focus {
  background-color: rgba(74, 81, 94, 0.9325);
  color: rgba(255, 255, 255, 0.75);
}
a.list-group-item-gray.active,
button.list-group-item-gray.active {
  border-color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.75);
  color: rgba(255, 255, 255, 0.75);
}

.list-group.list-group-timeline .list-group-timeline-gray:before {
  background-color: rgba(255, 255, 255, 0.75);
}

.list-group.list-group-timeline {
  position: relative;
}
.list-group.list-group-timeline:before {
  background-color: #36445d;
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0.2rem;
}
.list-group.list-group-timeline .list-group-item {
  border: none;
  padding-left: 1.25rem;
}
.list-group.list-group-timeline .list-group-item:before {
  position: absolute;
  display: block;
  content: "";
  width: 7px;
  height: 7px;
  left: 0;
  top: 50%;
  margin-top: -3.5px;
  border-radius: 100%;
}
.list-group .list-group-item.active h1,
.list-group .list-group-item.active .h1,
.list-group .list-group-item.active h2,
.list-group .list-group-item.active .h2,
.list-group .list-group-item.active h3,
.list-group .list-group-item.active .h3,
.list-group .list-group-item.active h4,
.list-group .list-group-item.active .h4,
.list-group .list-group-item.active h5,
.list-group .list-group-item.active .h5,
.list-group .list-group-item.active h6,
.list-group .list-group-item.active .h6 {
  color: #fff;
}

[dir=rtl] .list-group {
  padding-right: 0;
}
[dir=rtl] .list-group.list-group-timeline:before {
  left: auto;
  right: 0.2rem;
}
[dir=rtl] .list-group.list-group-timeline .list-group-item {
  padding-right: 1.25rem;
}
[dir=rtl] .list-group.list-group-timeline .list-group-item:before {
  left: auto;
  right: 0;
}
[dir=rtl] .list-group.list-group-horizontal .list-group-item:first-child {
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
[dir=rtl] .list-group.list-group-horizontal .list-group-item:last-child {
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  [dir=rtl] .list-group.list-group-horizontal-sm .list-group-item:first-child {
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  [dir=rtl] .list-group.list-group-horizontal-sm .list-group-item:last-child {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  [dir=rtl] .list-group.list-group-horizontal-md .list-group-item:first-child {
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  [dir=rtl] .list-group.list-group-horizontal-md .list-group-item:last-child {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  [dir=rtl] .list-group.list-group-horizontal-lg .list-group-item:first-child {
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  [dir=rtl] .list-group.list-group-horizontal-lg .list-group-item:last-child {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  [dir=rtl] .list-group.list-group-horizontal-xl .list-group-item:first-child {
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  [dir=rtl] .list-group.list-group-horizontal-xl .list-group-item:last-child {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  [dir=rtl] .list-group.list-group-horizontal-xxl .list-group-item:first-child {
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  [dir=rtl] .list-group.list-group-horizontal-xxl .list-group-item:last-child {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left-width: 1px;
  }
}

.navbar {
  z-index: 2;
}
.navbar .dropdown:focus,
.navbar .dropdown-toggle:focus {
  outline: 0;
}
.navbar .navbar-toggler:focus {
  box-shadow: none;
}

.fixed-top {
  z-index: 1030;
}

.navbar.navbar-light {
  color: #637ba9;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.2) !important;
}

.navbar.navbar-dark {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.4) !important;
}

.navbar-collapse,
.navbar-brand,
.navbar-text {
  flex-shrink: 1;
}

.navbar-dark hr {
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-light hr {
  border-color: rgba(255, 255, 255, 0.75);
}

[dir=rtl] .navbar-nav {
  padding-right: 0;
}
[dir=rtl] .navbar-brand {
  margin-right: 0;
}

.mega-dropdown .dropdown-toggle {
  outline: 0;
}
.mega-dropdown .dropdown-menu {
  width: 100%;
}

.card {
  background-clip: padding-box;
  box-shadow: 0 0.125rem 0.875rem 0 rgba(0, 0, 0, 0.16);
}
.card .card-link {
  display: inline-block;
}
.card .card-header + .card-body,
.card .card-header + .card-content > .card-body:first-of-type {
  padding-top: 0;
}

.card-action.card-fullscreen {
  display: block;
  z-index: 9999;
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  border: none;
  border-radius: 0;
}
.card-action .card-alert {
  position: absolute;
  width: 100%;
  z-index: 999;
}
.card-action .card-alert .alert {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.card-action .card-header.collapsed {
  border-bottom: 0;
}
.card-action .card-header {
  display: flex;
  line-height: 1.54;
}
.card-action .card-header .card-action-title {
  flex-grow: 1;
  margin-right: 0.5rem;
}
.card-action .card-header .card-action-element {
  flex-shrink: 0;
  background-color: inherit;
  top: 1rem;
  right: 1.5rem;
  color: #a1b0cb;
}
.card-action .card-header .card-action-element a {
  color: #a1b0cb;
}
.card-action .card-header .card-action-element a .collapse-icon::after {
  margin-top: -0.15rem;
}
.card-action .blockUI .sk-fold {
  margin: 0 auto;
}
.card-action .blockUI h5, .card-action .blockUI .h5 {
  color: #a1b0cb;
  margin: 1rem 0 0 0;
}

.card-header,
.card-footer {
  border-color: #36445d;
}

.card hr {
  color: #36445d;
}

.card .row-bordered > [class*=" col "] .card .row-bordered > [class$=" col"], .card .row-bordered > [class*=" col "]::before, .card .row-bordered > [class*=" col "]::after,
.card .row-bordered > [class^="col "] .card .row-bordered > [class$=" col"],
.card .row-bordered > [class^="col "]::before,
.card .row-bordered > [class^="col "]::after,
.card .row-bordered > [class*=" col-"] .card .row-bordered > [class$=" col"],
.card .row-bordered > [class*=" col-"]::before,
.card .row-bordered > [class*=" col-"]::after,
.card .row-bordered > [class^=col-] .card .row-bordered > [class$=" col"],
.card .row-bordered > [class^=col-]::before,
.card .row-bordered > [class^=col-]::after,
.card .row-bordered > [class=col] .card .row-bordered > [class$=" col"],
.card .row-bordered > [class=col]::before,
.card .row-bordered > [class=col]::after {
  border-color: #36445d;
}

.card-header.header-elements,
.card-title.header-elements {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.card-header.card-header-elements {
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
}
.card-header .card-header-elements {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.card-header-elements,
.card-title-elements {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.card-header-elements + .card-header-elements,
.card-header-elements > * + *,
.card-header-elements + .card-title-elements,
.card-title-elements > * + *,
.card-title-elements + .card-header-elements,
.card-title-elements + .card-title-elements {
  margin-left: 0.25rem;
}
[dir=rtl] .card-header-elements + .card-header-elements,
[dir=rtl] .card-header-elements > * + *,
[dir=rtl] .card-header-elements + .card-title-elements,
[dir=rtl] .card-title-elements > * + *,
[dir=rtl] .card-title-elements + .card-header-elements,
[dir=rtl] .card-title-elements + .card-title-elements {
  margin-left: 0;
  margin-right: 0.25rem;
}

.card-img-left {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media (max-width: 767.98px) {
  .card-img-left {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.card-img-right {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@media (max-width: 767.98px) {
  .card-img-right {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.card > .list-group .list-group-item {
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}

html:not([dir=rtl]) .card .card-separator {
  border-right: 1px solid #36445d;
}
[dir=rtl] .card .card-separator {
  border-left: 1px solid #36445d;
}

@media (max-width: 767.98px) {
  .card .card-separator {
    border-bottom: 1px solid #36445d;
    padding-bottom: 1.375rem;
  }
  html:not([dir=rtl]) .card .card-separator {
    border-right-width: 0 !important;
  }
  [dir=rtl] .card .card-separator {
    border-left-width: 0 !important;
  }
}
[dir=rtl] .card-link + .card-link {
  margin-right: 1.375rem;
  margin-left: 0;
}
[dir=rtl] .card-action .card-header .card-action-title {
  margin-left: 0.5rem;
  margin-right: 0;
}
[dir=rtl] .card-action .card-header .card-action-element,
[dir=rtl] .card-action .card-header .card-action-element-toggle {
  left: 1.5rem;
  right: auto;
}
@media (min-width: 576px) {
  [dir=rtl] .card-group > .card {
    border: 0 solid #36445d;
    border-radius: 0.3125rem;
  }
  [dir=rtl] .card-group > .card .card-img-top,
[dir=rtl] .card-group > .card .card-header:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  [dir=rtl] .card-group > .card .card-img-bottom,
[dir=rtl] .card-group > .card .card-footer:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  [dir=rtl] .card-group > .card + .card {
    border-right: 0;
  }
  [dir=rtl] .card-group > .card:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  [dir=rtl] .card-group > .card:not(:first-child) .card-img-top,
[dir=rtl] .card-group > .card:not(:first-child) .card-header {
    border-top-right-radius: 0;
  }
  [dir=rtl] .card-group > .card:not(:first-child) .card-img-bottom,
[dir=rtl] .card-group > .card:not(:first-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  [dir=rtl] .card-group > .card:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  [dir=rtl] .card-group > .card:not(:last-child) .card-img-top,
[dir=rtl] .card-group > .card:not(:last-child) .card-header {
    border-top-left-radius: 0;
  }
  [dir=rtl] .card-group > .card:not(:last-child) .card-img-bottom,
[dir=rtl] .card-group > .card:not(:last-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion.accordion-header-secondary .accordion-item.active .accordion-button {
  color: #69809a !important;
}
.accordion.accordion-header-secondary .accordion-item.active .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%2369809a' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.accordion.accordion-header-success .accordion-item.active .accordion-button {
  color: #39da8a !important;
}
.accordion.accordion-header-success .accordion-item.active .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%2339da8a' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.accordion.accordion-header-info .accordion-item.active .accordion-button {
  color: #00cfdd !important;
}
.accordion.accordion-header-info .accordion-item.active .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%2300cfdd' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.accordion.accordion-header-warning .accordion-item.active .accordion-button {
  color: #fdac41 !important;
}
.accordion.accordion-header-warning .accordion-item.active .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%23fdac41' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.accordion.accordion-header-danger .accordion-item.active .accordion-button {
  color: #ff5b5c !important;
}
.accordion.accordion-header-danger .accordion-item.active .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%23ff5b5c' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.accordion.accordion-header-light .accordion-item.active .accordion-button {
  color: #434c5f !important;
}
.accordion.accordion-header-light .accordion-item.active .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%23434c5f' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.accordion.accordion-header-dark .accordion-item.active .accordion-button {
  color: #717784 !important;
}
.accordion.accordion-header-dark .accordion-item.active .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%23717784' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.accordion.accordion-header-gray .accordion-item.active .accordion-button {
  color: rgba(255, 255, 255, 0.75) !important;
}
.accordion.accordion-header-gray .accordion-item.active .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='rgba%28255, 255, 255, 0.75%29' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.accordion-header + .accordion-collapse .accordion-body {
  padding-top: 0;
}

.accordion.accordion-without-arrow .accordion-button::after {
  background-image: none !important;
}
.accordion .accordion-item.active {
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}

.card.accordion-item {
  box-shadow: none;
}

.accordion-button.collapsed:focus {
  box-shadow: none;
}

.accordion-button {
  box-shadow: none;
}

.accordion-header {
  line-height: 1.54;
}

.accordion-item:not(:first-of-type) {
  border-top: 0 solid #36445d;
}

.accordion-button {
  font-weight: inherit;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.accordion-button.collapsed {
  border-radius: 0.3125rem;
}

.accordion > .card:not(:last-of-type) {
  border-radius: 0.3125rem !important;
  margin-bottom: 0.5rem;
}

[dir=rtl] .accordion-button {
  text-align: right;
}
[dir=rtl] .accordion-button::after {
  margin-left: 0;
  margin-right: auto;
  transform: rotate(180deg);
}
[dir=rtl] .accordion-button:not(.collapsed)::after {
  transform: rotate(90deg);
}

.close:focus {
  outline: 0;
}

[dir=rtl] .close {
  float: left;
}

.toast .toast-header.bg-secondary,
.bs-toast .toast-header.bg-secondary {
  background-color: #69809a;
  color: #fff;
}

.toast .toast-header.bg-success,
.bs-toast .toast-header.bg-success {
  background-color: #39da8a;
  color: #fff;
}

.toast .toast-header.bg-info,
.bs-toast .toast-header.bg-info {
  background-color: #00cfdd;
  color: #fff;
}

.toast .toast-header.bg-warning,
.bs-toast .toast-header.bg-warning {
  background-color: #fdac41;
  color: #fff;
}

.toast .toast-header.bg-danger,
.bs-toast .toast-header.bg-danger {
  background-color: #ff5b5c;
  color: #fff;
}

.toast .toast-header.bg-light,
.bs-toast .toast-header.bg-light {
  background-color: #434c5f;
  color: #fff;
}

.toast .toast-header.bg-dark,
.bs-toast .toast-header.bg-dark {
  background-color: #717784;
  color: #fff;
}

.toast .toast-header.bg-gray,
.bs-toast .toast-header.bg-gray {
  background-color: rgba(255, 255, 255, 0.75);
  color: #000;
}

.toast.bs-toast {
  z-index: 1095;
  background-color: rgba(40, 49, 68, 0.85);
}
.toast.bs-toast .toast-header .btn-close {
  padding: 0.435rem;
  font-size: 0.7rem;
  background-color: #fff;
}
html:not([dir=rtl]) .toast.bs-toast .toast-header .btn-close {
  margin-left: 0.5rem;
}
[dir=rtl] .toast.bs-toast .toast-header .btn-close {
  margin-left: -0.5rem;
  margin-right: 0.5rem;
}
.toast.bs-toast .toast-body {
  padding: 0.625rem 1rem;
}

html:not(.dark-style) .toast.bs-toast .toast-header:not([class*=bg-]) .btn-close {
  background-color: #d8deea;
}

.toast-ex {
  position: fixed;
  top: 4.1rem;
  right: 0.5rem;
}
[dir=rtl] .toast-ex {
  left: 0.5rem;
  right: auto;
}

.toast-placement-ex {
  position: fixed;
}

.carousel .carousel-item.active h1,
.carousel .carousel-item.active .h1,
.carousel .carousel-item.active h2,
.carousel .carousel-item.active .h2,
.carousel .carousel-item.active h3,
.carousel .carousel-item.active .h3,
.carousel .carousel-item.active h4,
.carousel .carousel-item.active .h4,
.carousel .carousel-item.active h5,
.carousel .carousel-item.active .h5,
.carousel .carousel-item.active h6,
.carousel .carousel-item.active .h6,
.carousel .carousel-item.carousel-item-start h1,
.carousel .carousel-item.carousel-item-start .h1,
.carousel .carousel-item.carousel-item-start h2,
.carousel .carousel-item.carousel-item-start .h2,
.carousel .carousel-item.carousel-item-start h3,
.carousel .carousel-item.carousel-item-start .h3,
.carousel .carousel-item.carousel-item-start h4,
.carousel .carousel-item.carousel-item-start .h4,
.carousel .carousel-item.carousel-item-start h5,
.carousel .carousel-item.carousel-item-start .h5,
.carousel .carousel-item.carousel-item-start h6,
.carousel .carousel-item.carousel-item-start .h6 {
  color: #fff;
}

.carousel.carousel-dark .carousel-item.active h1,
.carousel.carousel-dark .carousel-item.active .h1,
.carousel.carousel-dark .carousel-item.active h2,
.carousel.carousel-dark .carousel-item.active .h2,
.carousel.carousel-dark .carousel-item.active h3,
.carousel.carousel-dark .carousel-item.active .h3,
.carousel.carousel-dark .carousel-item.active h4,
.carousel.carousel-dark .carousel-item.active .h4,
.carousel.carousel-dark .carousel-item.active h5,
.carousel.carousel-dark .carousel-item.active .h5,
.carousel.carousel-dark .carousel-item.active h6,
.carousel.carousel-dark .carousel-item.active .h6,
.carousel.carousel-dark .carousel-item.carousel-item-start h1,
.carousel.carousel-dark .carousel-item.carousel-item-start .h1,
.carousel.carousel-dark .carousel-item.carousel-item-start h2,
.carousel.carousel-dark .carousel-item.carousel-item-start .h2,
.carousel.carousel-dark .carousel-item.carousel-item-start h3,
.carousel.carousel-dark .carousel-item.carousel-item-start .h3,
.carousel.carousel-dark .carousel-item.carousel-item-start h4,
.carousel.carousel-dark .carousel-item.carousel-item-start .h4,
.carousel.carousel-dark .carousel-item.carousel-item-start h5,
.carousel.carousel-dark .carousel-item.carousel-item-start .h5,
.carousel.carousel-dark .carousel-item.carousel-item-start h6,
.carousel.carousel-dark .carousel-item.carousel-item-start .h6 {
  color: #000;
}

.spinner-border-lg {
  width: 3rem;
  height: 3rem;
  border-width: 0.3em;
}

.spinner-grow-lg {
  width: 3rem;
  height: 3rem;
  border-width: 0.3em;
}

.btn .spinner-border,
.btn .spinner-grow {
  position: relative;
  top: -0.0625rem;
  height: 1em;
  width: 1em;
}
.btn .spinner-border {
  border-width: 0.15em;
}

@-webkit-keyframes spinner-border-rtl {
  to {
    transform: rotate(-360deg);
  }
}
@-moz-keyframes spinner-border-rtl {
  to {
    transform: rotate(-360deg);
  }
}
@keyframes spinner-border-rtl {
  to {
    transform: rotate(-360deg);
  }
}
[dir=rtl] .spinner-border {
  animation-name: spinner-border-rtl;
}

.offcanvas {
  box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.28);
}

[dir=rtl] .offcanvas-header .btn-close {
  margin: -0.75rem auto -0.75rem -0.75rem;
}
[dir=rtl] .offcanvas-start {
  left: auto;
  right: 0;
}
[dir=rtl] .offcanvas-start:not(.show) {
  transform: translateX(100%);
}
[dir=rtl] .offcanvas-end {
  right: auto;
  left: 0;
}
[dir=rtl] .offcanvas-end:not(.show) {
  transform: translateX(-100%);
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;
}

.shadow-lg {
  box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.3) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.zindex-1 {
  z-index: 1 !important;
}

.zindex-2 {
  z-index: 2 !important;
}

.zindex-3 {
  z-index: 3 !important;
}

.zindex-4 {
  z-index: 4 !important;
}

.zindex-5 {
  z-index: 5 !important;
}

.border {
  border: 1px solid #36445d !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #36445d !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #36445d !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-primary {
  border-color: #5a8dee !important;
}

.border-secondary {
  border-color: #69809a !important;
}

.border-success {
  border-color: #39da8a !important;
}

.border-info {
  border-color: #00cfdd !important;
}

.border-warning {
  border-color: #fdac41 !important;
}

.border-danger {
  border-color: #ff5b5c !important;
}

.border-light {
  border-color: rgba(255, 255, 255, 0.75) !important;
}

.border-dark {
  border-color: #717784 !important;
}

.border-gray {
  border-color: rgba(255, 255, 255, 0.75) !important;
}

.border-white {
  border-color: #fff !important;
}

.border-transparent {
  border-color: transparent !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-px-20 {
  width: 20px !important;
}

.w-px-30 {
  width: 30px !important;
}

.w-px-40 {
  width: 40px !important;
}

.w-px-50 {
  width: 50px !important;
}

.w-px-75 {
  width: 75px !important;
}

.w-px-100 {
  width: 100px !important;
}

.w-px-150 {
  width: 150px !important;
}

.w-px-200 {
  width: 200px !important;
}

.w-px-250 {
  width: 250px !important;
}

.w-px-300 {
  width: 300px !important;
}

.w-px-350 {
  width: 350px !important;
}

.w-px-400 {
  width: 400px !important;
}

.w-px-500 {
  width: 500px !important;
}

.w-px-600 {
  width: 600px !important;
}

.w-px-700 {
  width: 700px !important;
}

.w-px-800 {
  width: 800px !important;
}

.w-auto {
  width: auto !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-px-20 {
  height: 20px !important;
}

.h-px-30 {
  height: 30px !important;
}

.h-px-40 {
  height: 40px !important;
}

.h-px-50 {
  height: 50px !important;
}

.h-px-75 {
  height: 75px !important;
}

.h-px-100 {
  height: 100px !important;
}

.h-px-150 {
  height: 150px !important;
}

.h-px-200 {
  height: 200px !important;
}

.h-px-250 {
  height: 250px !important;
}

.h-px-300 {
  height: 300px !important;
}

.h-px-350 {
  height: 350px !important;
}

.h-px-400 {
  height: 400px !important;
}

.h-px-500 {
  height: 500px !important;
}

.h-px-600 {
  height: 600px !important;
}

.h-px-700 {
  height: 700px !important;
}

.h-px-800 {
  height: 800px !important;
}

.h-auto {
  height: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3625rem + 1.35vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.2875rem + 0.45vw) !important;
}

.fs-4 {
  font-size: calc(1.2625rem + 0.15vw) !important;
}

.fs-5 {
  font-size: 1.125rem !important;
}

.fs-6 {
  font-size: 0.9375rem !important;
}

.fs-tiny {
  font-size: 70% !important;
}

.fs-big {
  font-size: 112% !important;
}

.fs-large {
  font-size: 150% !important;
}

.fs-xlarge {
  font-size: 170% !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: 100 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 900 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-inherit {
  line-height: inherit !important;
}

.lh-sm {
  line-height: 1.2 !important;
}

.lh-base {
  line-height: 1.47 !important;
}

.lh-lg {
  line-height: 1.5 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-none {
  text-transform: none !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: #637ba9 !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: #fff !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: #a1b0cb !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #8295ba !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: #fff !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: #1c222f !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-lighter {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, 0.03) !important;
}

.bg-lightest {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, 0.015) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.1875rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3125rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-grab {
  cursor: grab !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.375rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.625rem !important;
  }

  .fs-4 {
    font-size: 1.375rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
html:not([dir=rtl]) .zindex-1 {
  z-index: 1 !important;
}
html:not([dir=rtl]) .zindex-2 {
  z-index: 2 !important;
}
html:not([dir=rtl]) .zindex-3 {
  z-index: 3 !important;
}
html:not([dir=rtl]) .zindex-4 {
  z-index: 4 !important;
}
html:not([dir=rtl]) .zindex-5 {
  z-index: 5 !important;
}
html:not([dir=rtl]) .float-start {
  float: left !important;
}
html:not([dir=rtl]) .float-end {
  float: right !important;
}
html:not([dir=rtl]) .float-none {
  float: none !important;
}
html:not([dir=rtl]) .end-0 {
  right: 0 !important;
}
html:not([dir=rtl]) .end-50 {
  right: 50% !important;
}
html:not([dir=rtl]) .end-100 {
  right: 100% !important;
}
html:not([dir=rtl]) .start-0 {
  left: 0 !important;
}
html:not([dir=rtl]) .start-50 {
  left: 50% !important;
}
html:not([dir=rtl]) .start-100 {
  left: 100% !important;
}
html:not([dir=rtl]) .translate-middle {
  transform: translate(-50%, -50%) !important;
}
html:not([dir=rtl]) .translate-middle-x {
  transform: translateX(-50%) !important;
}
html:not([dir=rtl]) .translate-middle-y {
  transform: translateY(-50%) !important;
}
html:not([dir=rtl]) .border-end {
  border-right: 1px solid #36445d !important;
}
html:not([dir=rtl]) .border-end-0 {
  border-right: 0 !important;
}
html:not([dir=rtl]) .border-start {
  border-left: 1px solid #36445d !important;
}
html:not([dir=rtl]) .border-start-0 {
  border-left: 0 !important;
}
html:not([dir=rtl]) .text-start {
  text-align: left !important;
}
html:not([dir=rtl]) .text-end {
  text-align: right !important;
}
html:not([dir=rtl]) .text-center {
  text-align: center !important;
}
html:not([dir=rtl]) .rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
html:not([dir=rtl]) .rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}
html:not([dir=rtl]) .rounded-start-top {
  border-top-left-radius: 0.25rem !important;
}
html:not([dir=rtl]) .rounded-start-bottom {
  border-bottom-left-radius: 0.25rem !important;
}
html:not([dir=rtl]) .rounded-end-top {
  border-top-right-radius: 0.25rem !important;
}
html:not([dir=rtl]) .rounded-end-bottom {
  border-bottom-right-radius: 0.25rem !important;
}
html:not([dir=rtl]) .me-0 {
  margin-right: 0 !important;
}
html:not([dir=rtl]) .me-1 {
  margin-right: 0.25rem !important;
}
html:not([dir=rtl]) .me-2 {
  margin-right: 0.5rem !important;
}
html:not([dir=rtl]) .me-3 {
  margin-right: 1rem !important;
}
html:not([dir=rtl]) .me-4 {
  margin-right: 1.5rem !important;
}
html:not([dir=rtl]) .me-5 {
  margin-right: 3rem !important;
}
html:not([dir=rtl]) .me-auto {
  margin-right: auto !important;
}
html:not([dir=rtl]) .ms-0 {
  margin-left: 0 !important;
}
html:not([dir=rtl]) .ms-1 {
  margin-left: 0.25rem !important;
}
html:not([dir=rtl]) .ms-2 {
  margin-left: 0.5rem !important;
}
html:not([dir=rtl]) .ms-3 {
  margin-left: 1rem !important;
}
html:not([dir=rtl]) .ms-4 {
  margin-left: 1.5rem !important;
}
html:not([dir=rtl]) .ms-5 {
  margin-left: 3rem !important;
}
html:not([dir=rtl]) .ms-auto {
  margin-left: auto !important;
}
html:not([dir=rtl]) .me-n1 {
  margin-right: -0.25rem !important;
}
html:not([dir=rtl]) .me-n2 {
  margin-right: -0.5rem !important;
}
html:not([dir=rtl]) .me-n3 {
  margin-right: -1rem !important;
}
html:not([dir=rtl]) .me-n4 {
  margin-right: -1.5rem !important;
}
html:not([dir=rtl]) .me-n5 {
  margin-right: -3rem !important;
}
html:not([dir=rtl]) .ms-n1 {
  margin-left: -0.25rem !important;
}
html:not([dir=rtl]) .ms-n2 {
  margin-left: -0.5rem !important;
}
html:not([dir=rtl]) .ms-n3 {
  margin-left: -1rem !important;
}
html:not([dir=rtl]) .ms-n4 {
  margin-left: -1.5rem !important;
}
html:not([dir=rtl]) .ms-n5 {
  margin-left: -3rem !important;
}
html:not([dir=rtl]) .pe-0 {
  padding-right: 0 !important;
}
html:not([dir=rtl]) .pe-1 {
  padding-right: 0.25rem !important;
}
html:not([dir=rtl]) .pe-2 {
  padding-right: 0.5rem !important;
}
html:not([dir=rtl]) .pe-3 {
  padding-right: 1rem !important;
}
html:not([dir=rtl]) .pe-4 {
  padding-right: 1.5rem !important;
}
html:not([dir=rtl]) .pe-5 {
  padding-right: 3rem !important;
}
html:not([dir=rtl]) .ps-0 {
  padding-left: 0 !important;
}
html:not([dir=rtl]) .ps-1 {
  padding-left: 0.25rem !important;
}
html:not([dir=rtl]) .ps-2 {
  padding-left: 0.5rem !important;
}
html:not([dir=rtl]) .ps-3 {
  padding-left: 1rem !important;
}
html:not([dir=rtl]) .ps-4 {
  padding-left: 1.5rem !important;
}
html:not([dir=rtl]) .ps-5 {
  padding-left: 3rem !important;
}
html:not([dir=rtl]) .rotate-0 {
  transform: rotate(0deg) !important;
}
html:not([dir=rtl]) .rotate-90 {
  transform: rotate(90deg) !important;
}
html:not([dir=rtl]) .rotate-180 {
  transform: rotate(180deg) !important;
}
html:not([dir=rtl]) .rotate-270 {
  transform: rotate(270deg) !important;
}
html:not([dir=rtl]) .rotate-n90 {
  transform: rotate(-90deg) !important;
}
html:not([dir=rtl]) .rotate-n180 {
  transform: rotate(-180deg) !important;
}
html:not([dir=rtl]) .rotate-n270 {
  transform: rotate(-270deg) !important;
}
html:not([dir=rtl]) .scaleX-n1 {
  transform: scaleX(-1) !important;
}
html:not([dir=rtl]) .scaleY-n1 {
  transform: scaleY(-1) !important;
}
@media (min-width: 576px) {
  html:not([dir=rtl]) .float-sm-start {
    float: left !important;
  }
  html:not([dir=rtl]) .float-sm-end {
    float: right !important;
  }
  html:not([dir=rtl]) .float-sm-none {
    float: none !important;
  }
  html:not([dir=rtl]) .text-sm-start {
    text-align: left !important;
  }
  html:not([dir=rtl]) .text-sm-end {
    text-align: right !important;
  }
  html:not([dir=rtl]) .text-sm-center {
    text-align: center !important;
  }
  html:not([dir=rtl]) .me-sm-0 {
    margin-right: 0 !important;
  }
  html:not([dir=rtl]) .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .me-sm-3 {
    margin-right: 1rem !important;
  }
  html:not([dir=rtl]) .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .me-sm-5 {
    margin-right: 3rem !important;
  }
  html:not([dir=rtl]) .me-sm-auto {
    margin-right: auto !important;
  }
  html:not([dir=rtl]) .ms-sm-0 {
    margin-left: 0 !important;
  }
  html:not([dir=rtl]) .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .ms-sm-3 {
    margin-left: 1rem !important;
  }
  html:not([dir=rtl]) .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .ms-sm-5 {
    margin-left: 3rem !important;
  }
  html:not([dir=rtl]) .ms-sm-auto {
    margin-left: auto !important;
  }
  html:not([dir=rtl]) .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  html:not([dir=rtl]) .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  html:not([dir=rtl]) .me-sm-n3 {
    margin-right: -1rem !important;
  }
  html:not([dir=rtl]) .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  html:not([dir=rtl]) .me-sm-n5 {
    margin-right: -3rem !important;
  }
  html:not([dir=rtl]) .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  html:not([dir=rtl]) .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  html:not([dir=rtl]) .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  html:not([dir=rtl]) .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  html:not([dir=rtl]) .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  html:not([dir=rtl]) .pe-sm-0 {
    padding-right: 0 !important;
  }
  html:not([dir=rtl]) .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .pe-sm-3 {
    padding-right: 1rem !important;
  }
  html:not([dir=rtl]) .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .pe-sm-5 {
    padding-right: 3rem !important;
  }
  html:not([dir=rtl]) .ps-sm-0 {
    padding-left: 0 !important;
  }
  html:not([dir=rtl]) .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .ps-sm-3 {
    padding-left: 1rem !important;
  }
  html:not([dir=rtl]) .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  html:not([dir=rtl]) .float-md-start {
    float: left !important;
  }
  html:not([dir=rtl]) .float-md-end {
    float: right !important;
  }
  html:not([dir=rtl]) .float-md-none {
    float: none !important;
  }
  html:not([dir=rtl]) .text-md-start {
    text-align: left !important;
  }
  html:not([dir=rtl]) .text-md-end {
    text-align: right !important;
  }
  html:not([dir=rtl]) .text-md-center {
    text-align: center !important;
  }
  html:not([dir=rtl]) .me-md-0 {
    margin-right: 0 !important;
  }
  html:not([dir=rtl]) .me-md-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .me-md-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .me-md-3 {
    margin-right: 1rem !important;
  }
  html:not([dir=rtl]) .me-md-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .me-md-5 {
    margin-right: 3rem !important;
  }
  html:not([dir=rtl]) .me-md-auto {
    margin-right: auto !important;
  }
  html:not([dir=rtl]) .ms-md-0 {
    margin-left: 0 !important;
  }
  html:not([dir=rtl]) .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .ms-md-3 {
    margin-left: 1rem !important;
  }
  html:not([dir=rtl]) .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .ms-md-5 {
    margin-left: 3rem !important;
  }
  html:not([dir=rtl]) .ms-md-auto {
    margin-left: auto !important;
  }
  html:not([dir=rtl]) .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  html:not([dir=rtl]) .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  html:not([dir=rtl]) .me-md-n3 {
    margin-right: -1rem !important;
  }
  html:not([dir=rtl]) .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  html:not([dir=rtl]) .me-md-n5 {
    margin-right: -3rem !important;
  }
  html:not([dir=rtl]) .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  html:not([dir=rtl]) .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  html:not([dir=rtl]) .ms-md-n3 {
    margin-left: -1rem !important;
  }
  html:not([dir=rtl]) .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  html:not([dir=rtl]) .ms-md-n5 {
    margin-left: -3rem !important;
  }
  html:not([dir=rtl]) .pe-md-0 {
    padding-right: 0 !important;
  }
  html:not([dir=rtl]) .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .pe-md-3 {
    padding-right: 1rem !important;
  }
  html:not([dir=rtl]) .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .pe-md-5 {
    padding-right: 3rem !important;
  }
  html:not([dir=rtl]) .ps-md-0 {
    padding-left: 0 !important;
  }
  html:not([dir=rtl]) .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .ps-md-3 {
    padding-left: 1rem !important;
  }
  html:not([dir=rtl]) .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  html:not([dir=rtl]) .float-lg-start {
    float: left !important;
  }
  html:not([dir=rtl]) .float-lg-end {
    float: right !important;
  }
  html:not([dir=rtl]) .float-lg-none {
    float: none !important;
  }
  html:not([dir=rtl]) .text-lg-start {
    text-align: left !important;
  }
  html:not([dir=rtl]) .text-lg-end {
    text-align: right !important;
  }
  html:not([dir=rtl]) .text-lg-center {
    text-align: center !important;
  }
  html:not([dir=rtl]) .me-lg-0 {
    margin-right: 0 !important;
  }
  html:not([dir=rtl]) .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .me-lg-3 {
    margin-right: 1rem !important;
  }
  html:not([dir=rtl]) .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .me-lg-5 {
    margin-right: 3rem !important;
  }
  html:not([dir=rtl]) .me-lg-auto {
    margin-right: auto !important;
  }
  html:not([dir=rtl]) .ms-lg-0 {
    margin-left: 0 !important;
  }
  html:not([dir=rtl]) .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .ms-lg-3 {
    margin-left: 1rem !important;
  }
  html:not([dir=rtl]) .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .ms-lg-5 {
    margin-left: 3rem !important;
  }
  html:not([dir=rtl]) .ms-lg-auto {
    margin-left: auto !important;
  }
  html:not([dir=rtl]) .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  html:not([dir=rtl]) .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  html:not([dir=rtl]) .me-lg-n3 {
    margin-right: -1rem !important;
  }
  html:not([dir=rtl]) .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  html:not([dir=rtl]) .me-lg-n5 {
    margin-right: -3rem !important;
  }
  html:not([dir=rtl]) .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  html:not([dir=rtl]) .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  html:not([dir=rtl]) .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  html:not([dir=rtl]) .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  html:not([dir=rtl]) .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  html:not([dir=rtl]) .pe-lg-0 {
    padding-right: 0 !important;
  }
  html:not([dir=rtl]) .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .pe-lg-3 {
    padding-right: 1rem !important;
  }
  html:not([dir=rtl]) .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .pe-lg-5 {
    padding-right: 3rem !important;
  }
  html:not([dir=rtl]) .ps-lg-0 {
    padding-left: 0 !important;
  }
  html:not([dir=rtl]) .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .ps-lg-3 {
    padding-left: 1rem !important;
  }
  html:not([dir=rtl]) .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  html:not([dir=rtl]) .float-xl-start {
    float: left !important;
  }
  html:not([dir=rtl]) .float-xl-end {
    float: right !important;
  }
  html:not([dir=rtl]) .float-xl-none {
    float: none !important;
  }
  html:not([dir=rtl]) .text-xl-start {
    text-align: left !important;
  }
  html:not([dir=rtl]) .text-xl-end {
    text-align: right !important;
  }
  html:not([dir=rtl]) .text-xl-center {
    text-align: center !important;
  }
  html:not([dir=rtl]) .me-xl-0 {
    margin-right: 0 !important;
  }
  html:not([dir=rtl]) .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .me-xl-3 {
    margin-right: 1rem !important;
  }
  html:not([dir=rtl]) .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .me-xl-5 {
    margin-right: 3rem !important;
  }
  html:not([dir=rtl]) .me-xl-auto {
    margin-right: auto !important;
  }
  html:not([dir=rtl]) .ms-xl-0 {
    margin-left: 0 !important;
  }
  html:not([dir=rtl]) .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .ms-xl-3 {
    margin-left: 1rem !important;
  }
  html:not([dir=rtl]) .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .ms-xl-5 {
    margin-left: 3rem !important;
  }
  html:not([dir=rtl]) .ms-xl-auto {
    margin-left: auto !important;
  }
  html:not([dir=rtl]) .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  html:not([dir=rtl]) .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  html:not([dir=rtl]) .me-xl-n3 {
    margin-right: -1rem !important;
  }
  html:not([dir=rtl]) .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  html:not([dir=rtl]) .me-xl-n5 {
    margin-right: -3rem !important;
  }
  html:not([dir=rtl]) .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  html:not([dir=rtl]) .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  html:not([dir=rtl]) .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  html:not([dir=rtl]) .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  html:not([dir=rtl]) .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  html:not([dir=rtl]) .pe-xl-0 {
    padding-right: 0 !important;
  }
  html:not([dir=rtl]) .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .pe-xl-3 {
    padding-right: 1rem !important;
  }
  html:not([dir=rtl]) .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .pe-xl-5 {
    padding-right: 3rem !important;
  }
  html:not([dir=rtl]) .ps-xl-0 {
    padding-left: 0 !important;
  }
  html:not([dir=rtl]) .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .ps-xl-3 {
    padding-left: 1rem !important;
  }
  html:not([dir=rtl]) .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1400px) {
  html:not([dir=rtl]) .float-xxl-start {
    float: left !important;
  }
  html:not([dir=rtl]) .float-xxl-end {
    float: right !important;
  }
  html:not([dir=rtl]) .float-xxl-none {
    float: none !important;
  }
  html:not([dir=rtl]) .text-xxl-start {
    text-align: left !important;
  }
  html:not([dir=rtl]) .text-xxl-end {
    text-align: right !important;
  }
  html:not([dir=rtl]) .text-xxl-center {
    text-align: center !important;
  }
  html:not([dir=rtl]) .me-xxl-0 {
    margin-right: 0 !important;
  }
  html:not([dir=rtl]) .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .me-xxl-3 {
    margin-right: 1rem !important;
  }
  html:not([dir=rtl]) .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .me-xxl-5 {
    margin-right: 3rem !important;
  }
  html:not([dir=rtl]) .me-xxl-auto {
    margin-right: auto !important;
  }
  html:not([dir=rtl]) .ms-xxl-0 {
    margin-left: 0 !important;
  }
  html:not([dir=rtl]) .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-auto {
    margin-left: auto !important;
  }
  html:not([dir=rtl]) .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  html:not([dir=rtl]) .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  html:not([dir=rtl]) .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  html:not([dir=rtl]) .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  html:not([dir=rtl]) .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  html:not([dir=rtl]) .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  html:not([dir=rtl]) .pe-xxl-0 {
    padding-right: 0 !important;
  }
  html:not([dir=rtl]) .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  html:not([dir=rtl]) .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  html:not([dir=rtl]) .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  html:not([dir=rtl]) .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  html:not([dir=rtl]) .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  html:not([dir=rtl]) .ps-xxl-0 {
    padding-left: 0 !important;
  }
  html:not([dir=rtl]) .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  html:not([dir=rtl]) .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  html:not([dir=rtl]) .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  html:not([dir=rtl]) .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  html:not([dir=rtl]) .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
[dir=rtl] .zindex-1 {
  z-index: 1 !important;
}
[dir=rtl] .zindex-2 {
  z-index: 2 !important;
}
[dir=rtl] .zindex-3 {
  z-index: 3 !important;
}
[dir=rtl] .zindex-4 {
  z-index: 4 !important;
}
[dir=rtl] .zindex-5 {
  z-index: 5 !important;
}
[dir=rtl] .float-start {
  float: right !important;
}
[dir=rtl] .float-end {
  float: left !important;
}
[dir=rtl] .float-none {
  float: none !important;
}
[dir=rtl] .end-0 {
  left: 0 !important;
}
[dir=rtl] .end-50 {
  left: 50% !important;
}
[dir=rtl] .end-100 {
  left: 100% !important;
}
[dir=rtl] .start-0 {
  right: 0 !important;
}
[dir=rtl] .start-50 {
  right: 50% !important;
}
[dir=rtl] .start-100 {
  right: 100% !important;
}
[dir=rtl] .translate-middle {
  transform: translate(50%, -50%) !important;
}
[dir=rtl] .translate-middle-x {
  transform: translateX(50%) !important;
}
[dir=rtl] .translate-middle-y {
  transform: translateY(-50%) !important;
}
[dir=rtl] .border-end {
  border-left: 1px solid #36445d !important;
}
[dir=rtl] .border-end-0 {
  border-left: 0 !important;
}
[dir=rtl] .border-start {
  border-right: 1px solid #36445d !important;
}
[dir=rtl] .border-start-0 {
  border-right: 0 !important;
}
[dir=rtl] .text-start {
  text-align: right !important;
}
[dir=rtl] .text-end {
  text-align: left !important;
}
[dir=rtl] .text-center {
  text-align: center !important;
}
[dir=rtl] .rounded-end {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
[dir=rtl] .rounded-start {
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
[dir=rtl] .rounded-start-top {
  border-top-right-radius: 0.25rem !important;
}
[dir=rtl] .rounded-start-bottom {
  border-bottom-right-radius: 0.25rem !important;
}
[dir=rtl] .rounded-end-top {
  border-top-left-radius: 0.25rem !important;
}
[dir=rtl] .rounded-end-bottom {
  border-bottom-left-radius: 0.25rem !important;
}
[dir=rtl] .me-0 {
  margin-left: 0 !important;
}
[dir=rtl] .me-1 {
  margin-left: 0.25rem !important;
}
[dir=rtl] .me-2 {
  margin-left: 0.5rem !important;
}
[dir=rtl] .me-3 {
  margin-left: 1rem !important;
}
[dir=rtl] .me-4 {
  margin-left: 1.5rem !important;
}
[dir=rtl] .me-5 {
  margin-left: 3rem !important;
}
[dir=rtl] .me-auto {
  margin-left: auto !important;
}
[dir=rtl] .ms-0 {
  margin-right: 0 !important;
}
[dir=rtl] .ms-1 {
  margin-right: 0.25rem !important;
}
[dir=rtl] .ms-2 {
  margin-right: 0.5rem !important;
}
[dir=rtl] .ms-3 {
  margin-right: 1rem !important;
}
[dir=rtl] .ms-4 {
  margin-right: 1.5rem !important;
}
[dir=rtl] .ms-5 {
  margin-right: 3rem !important;
}
[dir=rtl] .ms-auto {
  margin-right: auto !important;
}
[dir=rtl] .me-n1 {
  margin-left: -0.25rem !important;
}
[dir=rtl] .me-n2 {
  margin-left: -0.5rem !important;
}
[dir=rtl] .me-n3 {
  margin-left: -1rem !important;
}
[dir=rtl] .me-n4 {
  margin-left: -1.5rem !important;
}
[dir=rtl] .me-n5 {
  margin-left: -3rem !important;
}
[dir=rtl] .ms-n1 {
  margin-right: -0.25rem !important;
}
[dir=rtl] .ms-n2 {
  margin-right: -0.5rem !important;
}
[dir=rtl] .ms-n3 {
  margin-right: -1rem !important;
}
[dir=rtl] .ms-n4 {
  margin-right: -1.5rem !important;
}
[dir=rtl] .ms-n5 {
  margin-right: -3rem !important;
}
[dir=rtl] .pe-0 {
  padding-left: 0 !important;
}
[dir=rtl] .pe-1 {
  padding-left: 0.25rem !important;
}
[dir=rtl] .pe-2 {
  padding-left: 0.5rem !important;
}
[dir=rtl] .pe-3 {
  padding-left: 1rem !important;
}
[dir=rtl] .pe-4 {
  padding-left: 1.5rem !important;
}
[dir=rtl] .pe-5 {
  padding-left: 3rem !important;
}
[dir=rtl] .ps-0 {
  padding-right: 0 !important;
}
[dir=rtl] .ps-1 {
  padding-right: 0.25rem !important;
}
[dir=rtl] .ps-2 {
  padding-right: 0.5rem !important;
}
[dir=rtl] .ps-3 {
  padding-right: 1rem !important;
}
[dir=rtl] .ps-4 {
  padding-right: 1.5rem !important;
}
[dir=rtl] .ps-5 {
  padding-right: 3rem !important;
}
[dir=rtl] .rotate-0 {
  transform: rotate(0deg) !important;
}
[dir=rtl] .rotate-90 {
  transform: rotate(-90deg) !important;
}
[dir=rtl] .rotate-180 {
  transform: rotate(-180deg) !important;
}
[dir=rtl] .rotate-270 {
  transform: rotate(-270deg) !important;
}
[dir=rtl] .rotate-n90 {
  transform: rotate(90deg) !important;
}
[dir=rtl] .rotate-n180 {
  transform: rotate(180deg) !important;
}
[dir=rtl] .rotate-n270 {
  transform: rotate(270deg) !important;
}
[dir=rtl] .scaleX-n1 {
  transform: scaleX(1) !important;
}
[dir=rtl] .scaleX-n1-rtl {
  transform: scaleX(-1) !important;
}
[dir=rtl] .scaleY-n1 {
  transform: scaleY(1) !important;
}
[dir=rtl] .scaleY-n1-rtl {
  transform: scaleY(-1) !important;
}
@media (min-width: 576px) {
  [dir=rtl] .float-sm-start {
    float: right !important;
  }
  [dir=rtl] .float-sm-end {
    float: left !important;
  }
  [dir=rtl] .float-sm-none {
    float: none !important;
  }
  [dir=rtl] .text-sm-start {
    text-align: right !important;
  }
  [dir=rtl] .text-sm-end {
    text-align: left !important;
  }
  [dir=rtl] .text-sm-center {
    text-align: center !important;
  }
  [dir=rtl] .me-sm-0 {
    margin-left: 0 !important;
  }
  [dir=rtl] .me-sm-1 {
    margin-left: 0.25rem !important;
  }
  [dir=rtl] .me-sm-2 {
    margin-left: 0.5rem !important;
  }
  [dir=rtl] .me-sm-3 {
    margin-left: 1rem !important;
  }
  [dir=rtl] .me-sm-4 {
    margin-left: 1.5rem !important;
  }
  [dir=rtl] .me-sm-5 {
    margin-left: 3rem !important;
  }
  [dir=rtl] .me-sm-auto {
    margin-left: auto !important;
  }
  [dir=rtl] .ms-sm-0 {
    margin-right: 0 !important;
  }
  [dir=rtl] .ms-sm-1 {
    margin-right: 0.25rem !important;
  }
  [dir=rtl] .ms-sm-2 {
    margin-right: 0.5rem !important;
  }
  [dir=rtl] .ms-sm-3 {
    margin-right: 1rem !important;
  }
  [dir=rtl] .ms-sm-4 {
    margin-right: 1.5rem !important;
  }
  [dir=rtl] .ms-sm-5 {
    margin-right: 3rem !important;
  }
  [dir=rtl] .ms-sm-auto {
    margin-right: auto !important;
  }
  [dir=rtl] .me-sm-n1 {
    margin-left: -0.25rem !important;
  }
  [dir=rtl] .me-sm-n2 {
    margin-left: -0.5rem !important;
  }
  [dir=rtl] .me-sm-n3 {
    margin-left: -1rem !important;
  }
  [dir=rtl] .me-sm-n4 {
    margin-left: -1.5rem !important;
  }
  [dir=rtl] .me-sm-n5 {
    margin-left: -3rem !important;
  }
  [dir=rtl] .ms-sm-n1 {
    margin-right: -0.25rem !important;
  }
  [dir=rtl] .ms-sm-n2 {
    margin-right: -0.5rem !important;
  }
  [dir=rtl] .ms-sm-n3 {
    margin-right: -1rem !important;
  }
  [dir=rtl] .ms-sm-n4 {
    margin-right: -1.5rem !important;
  }
  [dir=rtl] .ms-sm-n5 {
    margin-right: -3rem !important;
  }
  [dir=rtl] .pe-sm-0 {
    padding-left: 0 !important;
  }
  [dir=rtl] .pe-sm-1 {
    padding-left: 0.25rem !important;
  }
  [dir=rtl] .pe-sm-2 {
    padding-left: 0.5rem !important;
  }
  [dir=rtl] .pe-sm-3 {
    padding-left: 1rem !important;
  }
  [dir=rtl] .pe-sm-4 {
    padding-left: 1.5rem !important;
  }
  [dir=rtl] .pe-sm-5 {
    padding-left: 3rem !important;
  }
  [dir=rtl] .ps-sm-0 {
    padding-right: 0 !important;
  }
  [dir=rtl] .ps-sm-1 {
    padding-right: 0.25rem !important;
  }
  [dir=rtl] .ps-sm-2 {
    padding-right: 0.5rem !important;
  }
  [dir=rtl] .ps-sm-3 {
    padding-right: 1rem !important;
  }
  [dir=rtl] .ps-sm-4 {
    padding-right: 1.5rem !important;
  }
  [dir=rtl] .ps-sm-5 {
    padding-right: 3rem !important;
  }
}
@media (min-width: 768px) {
  [dir=rtl] .float-md-start {
    float: right !important;
  }
  [dir=rtl] .float-md-end {
    float: left !important;
  }
  [dir=rtl] .float-md-none {
    float: none !important;
  }
  [dir=rtl] .text-md-start {
    text-align: right !important;
  }
  [dir=rtl] .text-md-end {
    text-align: left !important;
  }
  [dir=rtl] .text-md-center {
    text-align: center !important;
  }
  [dir=rtl] .me-md-0 {
    margin-left: 0 !important;
  }
  [dir=rtl] .me-md-1 {
    margin-left: 0.25rem !important;
  }
  [dir=rtl] .me-md-2 {
    margin-left: 0.5rem !important;
  }
  [dir=rtl] .me-md-3 {
    margin-left: 1rem !important;
  }
  [dir=rtl] .me-md-4 {
    margin-left: 1.5rem !important;
  }
  [dir=rtl] .me-md-5 {
    margin-left: 3rem !important;
  }
  [dir=rtl] .me-md-auto {
    margin-left: auto !important;
  }
  [dir=rtl] .ms-md-0 {
    margin-right: 0 !important;
  }
  [dir=rtl] .ms-md-1 {
    margin-right: 0.25rem !important;
  }
  [dir=rtl] .ms-md-2 {
    margin-right: 0.5rem !important;
  }
  [dir=rtl] .ms-md-3 {
    margin-right: 1rem !important;
  }
  [dir=rtl] .ms-md-4 {
    margin-right: 1.5rem !important;
  }
  [dir=rtl] .ms-md-5 {
    margin-right: 3rem !important;
  }
  [dir=rtl] .ms-md-auto {
    margin-right: auto !important;
  }
  [dir=rtl] .me-md-n1 {
    margin-left: -0.25rem !important;
  }
  [dir=rtl] .me-md-n2 {
    margin-left: -0.5rem !important;
  }
  [dir=rtl] .me-md-n3 {
    margin-left: -1rem !important;
  }
  [dir=rtl] .me-md-n4 {
    margin-left: -1.5rem !important;
  }
  [dir=rtl] .me-md-n5 {
    margin-left: -3rem !important;
  }
  [dir=rtl] .ms-md-n1 {
    margin-right: -0.25rem !important;
  }
  [dir=rtl] .ms-md-n2 {
    margin-right: -0.5rem !important;
  }
  [dir=rtl] .ms-md-n3 {
    margin-right: -1rem !important;
  }
  [dir=rtl] .ms-md-n4 {
    margin-right: -1.5rem !important;
  }
  [dir=rtl] .ms-md-n5 {
    margin-right: -3rem !important;
  }
  [dir=rtl] .pe-md-0 {
    padding-left: 0 !important;
  }
  [dir=rtl] .pe-md-1 {
    padding-left: 0.25rem !important;
  }
  [dir=rtl] .pe-md-2 {
    padding-left: 0.5rem !important;
  }
  [dir=rtl] .pe-md-3 {
    padding-left: 1rem !important;
  }
  [dir=rtl] .pe-md-4 {
    padding-left: 1.5rem !important;
  }
  [dir=rtl] .pe-md-5 {
    padding-left: 3rem !important;
  }
  [dir=rtl] .ps-md-0 {
    padding-right: 0 !important;
  }
  [dir=rtl] .ps-md-1 {
    padding-right: 0.25rem !important;
  }
  [dir=rtl] .ps-md-2 {
    padding-right: 0.5rem !important;
  }
  [dir=rtl] .ps-md-3 {
    padding-right: 1rem !important;
  }
  [dir=rtl] .ps-md-4 {
    padding-right: 1.5rem !important;
  }
  [dir=rtl] .ps-md-5 {
    padding-right: 3rem !important;
  }
}
@media (min-width: 992px) {
  [dir=rtl] .float-lg-start {
    float: right !important;
  }
  [dir=rtl] .float-lg-end {
    float: left !important;
  }
  [dir=rtl] .float-lg-none {
    float: none !important;
  }
  [dir=rtl] .text-lg-start {
    text-align: right !important;
  }
  [dir=rtl] .text-lg-end {
    text-align: left !important;
  }
  [dir=rtl] .text-lg-center {
    text-align: center !important;
  }
  [dir=rtl] .me-lg-0 {
    margin-left: 0 !important;
  }
  [dir=rtl] .me-lg-1 {
    margin-left: 0.25rem !important;
  }
  [dir=rtl] .me-lg-2 {
    margin-left: 0.5rem !important;
  }
  [dir=rtl] .me-lg-3 {
    margin-left: 1rem !important;
  }
  [dir=rtl] .me-lg-4 {
    margin-left: 1.5rem !important;
  }
  [dir=rtl] .me-lg-5 {
    margin-left: 3rem !important;
  }
  [dir=rtl] .me-lg-auto {
    margin-left: auto !important;
  }
  [dir=rtl] .ms-lg-0 {
    margin-right: 0 !important;
  }
  [dir=rtl] .ms-lg-1 {
    margin-right: 0.25rem !important;
  }
  [dir=rtl] .ms-lg-2 {
    margin-right: 0.5rem !important;
  }
  [dir=rtl] .ms-lg-3 {
    margin-right: 1rem !important;
  }
  [dir=rtl] .ms-lg-4 {
    margin-right: 1.5rem !important;
  }
  [dir=rtl] .ms-lg-5 {
    margin-right: 3rem !important;
  }
  [dir=rtl] .ms-lg-auto {
    margin-right: auto !important;
  }
  [dir=rtl] .me-lg-n1 {
    margin-left: -0.25rem !important;
  }
  [dir=rtl] .me-lg-n2 {
    margin-left: -0.5rem !important;
  }
  [dir=rtl] .me-lg-n3 {
    margin-left: -1rem !important;
  }
  [dir=rtl] .me-lg-n4 {
    margin-left: -1.5rem !important;
  }
  [dir=rtl] .me-lg-n5 {
    margin-left: -3rem !important;
  }
  [dir=rtl] .ms-lg-n1 {
    margin-right: -0.25rem !important;
  }
  [dir=rtl] .ms-lg-n2 {
    margin-right: -0.5rem !important;
  }
  [dir=rtl] .ms-lg-n3 {
    margin-right: -1rem !important;
  }
  [dir=rtl] .ms-lg-n4 {
    margin-right: -1.5rem !important;
  }
  [dir=rtl] .ms-lg-n5 {
    margin-right: -3rem !important;
  }
  [dir=rtl] .pe-lg-0 {
    padding-left: 0 !important;
  }
  [dir=rtl] .pe-lg-1 {
    padding-left: 0.25rem !important;
  }
  [dir=rtl] .pe-lg-2 {
    padding-left: 0.5rem !important;
  }
  [dir=rtl] .pe-lg-3 {
    padding-left: 1rem !important;
  }
  [dir=rtl] .pe-lg-4 {
    padding-left: 1.5rem !important;
  }
  [dir=rtl] .pe-lg-5 {
    padding-left: 3rem !important;
  }
  [dir=rtl] .ps-lg-0 {
    padding-right: 0 !important;
  }
  [dir=rtl] .ps-lg-1 {
    padding-right: 0.25rem !important;
  }
  [dir=rtl] .ps-lg-2 {
    padding-right: 0.5rem !important;
  }
  [dir=rtl] .ps-lg-3 {
    padding-right: 1rem !important;
  }
  [dir=rtl] .ps-lg-4 {
    padding-right: 1.5rem !important;
  }
  [dir=rtl] .ps-lg-5 {
    padding-right: 3rem !important;
  }
}
@media (min-width: 1200px) {
  [dir=rtl] .float-xl-start {
    float: right !important;
  }
  [dir=rtl] .float-xl-end {
    float: left !important;
  }
  [dir=rtl] .float-xl-none {
    float: none !important;
  }
  [dir=rtl] .text-xl-start {
    text-align: right !important;
  }
  [dir=rtl] .text-xl-end {
    text-align: left !important;
  }
  [dir=rtl] .text-xl-center {
    text-align: center !important;
  }
  [dir=rtl] .me-xl-0 {
    margin-left: 0 !important;
  }
  [dir=rtl] .me-xl-1 {
    margin-left: 0.25rem !important;
  }
  [dir=rtl] .me-xl-2 {
    margin-left: 0.5rem !important;
  }
  [dir=rtl] .me-xl-3 {
    margin-left: 1rem !important;
  }
  [dir=rtl] .me-xl-4 {
    margin-left: 1.5rem !important;
  }
  [dir=rtl] .me-xl-5 {
    margin-left: 3rem !important;
  }
  [dir=rtl] .me-xl-auto {
    margin-left: auto !important;
  }
  [dir=rtl] .ms-xl-0 {
    margin-right: 0 !important;
  }
  [dir=rtl] .ms-xl-1 {
    margin-right: 0.25rem !important;
  }
  [dir=rtl] .ms-xl-2 {
    margin-right: 0.5rem !important;
  }
  [dir=rtl] .ms-xl-3 {
    margin-right: 1rem !important;
  }
  [dir=rtl] .ms-xl-4 {
    margin-right: 1.5rem !important;
  }
  [dir=rtl] .ms-xl-5 {
    margin-right: 3rem !important;
  }
  [dir=rtl] .ms-xl-auto {
    margin-right: auto !important;
  }
  [dir=rtl] .me-xl-n1 {
    margin-left: -0.25rem !important;
  }
  [dir=rtl] .me-xl-n2 {
    margin-left: -0.5rem !important;
  }
  [dir=rtl] .me-xl-n3 {
    margin-left: -1rem !important;
  }
  [dir=rtl] .me-xl-n4 {
    margin-left: -1.5rem !important;
  }
  [dir=rtl] .me-xl-n5 {
    margin-left: -3rem !important;
  }
  [dir=rtl] .ms-xl-n1 {
    margin-right: -0.25rem !important;
  }
  [dir=rtl] .ms-xl-n2 {
    margin-right: -0.5rem !important;
  }
  [dir=rtl] .ms-xl-n3 {
    margin-right: -1rem !important;
  }
  [dir=rtl] .ms-xl-n4 {
    margin-right: -1.5rem !important;
  }
  [dir=rtl] .ms-xl-n5 {
    margin-right: -3rem !important;
  }
  [dir=rtl] .pe-xl-0 {
    padding-left: 0 !important;
  }
  [dir=rtl] .pe-xl-1 {
    padding-left: 0.25rem !important;
  }
  [dir=rtl] .pe-xl-2 {
    padding-left: 0.5rem !important;
  }
  [dir=rtl] .pe-xl-3 {
    padding-left: 1rem !important;
  }
  [dir=rtl] .pe-xl-4 {
    padding-left: 1.5rem !important;
  }
  [dir=rtl] .pe-xl-5 {
    padding-left: 3rem !important;
  }
  [dir=rtl] .ps-xl-0 {
    padding-right: 0 !important;
  }
  [dir=rtl] .ps-xl-1 {
    padding-right: 0.25rem !important;
  }
  [dir=rtl] .ps-xl-2 {
    padding-right: 0.5rem !important;
  }
  [dir=rtl] .ps-xl-3 {
    padding-right: 1rem !important;
  }
  [dir=rtl] .ps-xl-4 {
    padding-right: 1.5rem !important;
  }
  [dir=rtl] .ps-xl-5 {
    padding-right: 3rem !important;
  }
}
@media (min-width: 1400px) {
  [dir=rtl] .float-xxl-start {
    float: right !important;
  }
  [dir=rtl] .float-xxl-end {
    float: left !important;
  }
  [dir=rtl] .float-xxl-none {
    float: none !important;
  }
  [dir=rtl] .text-xxl-start {
    text-align: right !important;
  }
  [dir=rtl] .text-xxl-end {
    text-align: left !important;
  }
  [dir=rtl] .text-xxl-center {
    text-align: center !important;
  }
  [dir=rtl] .me-xxl-0 {
    margin-left: 0 !important;
  }
  [dir=rtl] .me-xxl-1 {
    margin-left: 0.25rem !important;
  }
  [dir=rtl] .me-xxl-2 {
    margin-left: 0.5rem !important;
  }
  [dir=rtl] .me-xxl-3 {
    margin-left: 1rem !important;
  }
  [dir=rtl] .me-xxl-4 {
    margin-left: 1.5rem !important;
  }
  [dir=rtl] .me-xxl-5 {
    margin-left: 3rem !important;
  }
  [dir=rtl] .me-xxl-auto {
    margin-left: auto !important;
  }
  [dir=rtl] .ms-xxl-0 {
    margin-right: 0 !important;
  }
  [dir=rtl] .ms-xxl-1 {
    margin-right: 0.25rem !important;
  }
  [dir=rtl] .ms-xxl-2 {
    margin-right: 0.5rem !important;
  }
  [dir=rtl] .ms-xxl-3 {
    margin-right: 1rem !important;
  }
  [dir=rtl] .ms-xxl-4 {
    margin-right: 1.5rem !important;
  }
  [dir=rtl] .ms-xxl-5 {
    margin-right: 3rem !important;
  }
  [dir=rtl] .ms-xxl-auto {
    margin-right: auto !important;
  }
  [dir=rtl] .me-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  [dir=rtl] .me-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  [dir=rtl] .me-xxl-n3 {
    margin-left: -1rem !important;
  }
  [dir=rtl] .me-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  [dir=rtl] .me-xxl-n5 {
    margin-left: -3rem !important;
  }
  [dir=rtl] .ms-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  [dir=rtl] .ms-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  [dir=rtl] .ms-xxl-n3 {
    margin-right: -1rem !important;
  }
  [dir=rtl] .ms-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  [dir=rtl] .ms-xxl-n5 {
    margin-right: -3rem !important;
  }
  [dir=rtl] .pe-xxl-0 {
    padding-left: 0 !important;
  }
  [dir=rtl] .pe-xxl-1 {
    padding-left: 0.25rem !important;
  }
  [dir=rtl] .pe-xxl-2 {
    padding-left: 0.5rem !important;
  }
  [dir=rtl] .pe-xxl-3 {
    padding-left: 1rem !important;
  }
  [dir=rtl] .pe-xxl-4 {
    padding-left: 1.5rem !important;
  }
  [dir=rtl] .pe-xxl-5 {
    padding-left: 3rem !important;
  }
  [dir=rtl] .ps-xxl-0 {
    padding-right: 0 !important;
  }
  [dir=rtl] .ps-xxl-1 {
    padding-right: 0.25rem !important;
  }
  [dir=rtl] .ps-xxl-2 {
    padding-right: 0.5rem !important;
  }
  [dir=rtl] .ps-xxl-3 {
    padding-right: 1rem !important;
  }
  [dir=rtl] .ps-xxl-4 {
    padding-right: 1.5rem !important;
  }
  [dir=rtl] .ps-xxl-5 {
    padding-right: 3rem !important;
  }
}
body {
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  -moz-font-feature-settings: "liga" on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 768px) {
  button.list-group-item {
    outline: none;
  }
}
.app-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  z-index: 3;
  transition: all 0.25s ease;
}
.app-overlay.show {
  visibility: visible;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 992px) {
  .container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
}

.img-thumbnail {
  position: relative;
  display: block;
}
.img-thumbnail img {
  z-index: 1;
}

.img-thumbnail-content {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  display: block;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: translate(-50%, -50%);
}
.img-thumbnail:hover .img-thumbnail-content, .img-thumbnail:focus .img-thumbnail-content {
  opacity: 1;
}

.img-thumbnail-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: block;
  transition: all 0.2s ease-in-out;
}
.img-thumbnail:not(:hover):not(:focus) .img-thumbnail-overlay {
  opacity: 0 !important;
}

.img-thumbnail-shadow {
  transition: box-shadow 0.2s;
}
.img-thumbnail-shadow:hover, .img-thumbnail-shadow:focus {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
}

.img-thumbnail-zoom-in {
  overflow: hidden;
}
.img-thumbnail-zoom-in img {
  transition: all 0.3s ease-in-out;
  transform: translate3d(0);
}
.img-thumbnail-zoom-in .img-thumbnail-content {
  transform: translate(-50%, -50%) scale(0.6);
}
.img-thumbnail-zoom-in:hover img, .img-thumbnail-zoom-in:focus img {
  transform: scale(1.1);
}
.img-thumbnail-zoom-in:hover .img-thumbnail-content, .img-thumbnail-zoom-in:focus .img-thumbnail-content {
  transform: translate(-50%, -50%) scale(1);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card,
.card-body,
.media,
.flex-column,
.tab-content {
    min-height: 1px;
  }

  img {
    min-height: 1px;
    height: auto;
  }
}
[dir=rtl] body {
  text-align: right;
  direction: rtl;
}

.buy-now .btn-buy-now {
  position: fixed;
  bottom: 3rem;
  right: 1.875rem;
  z-index: 999999;
  box-shadow: 0 1px 20px 1px #ff5b5c;
}
[dir=rtl] .buy-now .btn-buy-now {
  left: 1.875rem;
  right: inherit;
}
.buy-now .btn-buy-now:hover {
  box-shadow: none;
}

.ui-square,
.ui-rect,
.ui-rect-30,
.ui-rect-60,
.ui-rect-67,
.ui-rect-75 {
  position: relative !important;
  display: block !important;
  padding-top: 100% !important;
  width: 100% !important;
}

.ui-square {
  padding-top: 100% !important;
}

.ui-rect {
  padding-top: 50% !important;
}

.ui-rect-30 {
  padding-top: 30% !important;
}

.ui-rect-60 {
  padding-top: 60% !important;
}

.ui-rect-67 {
  padding-top: 67% !important;
}

.ui-rect-75 {
  padding-top: 75% !important;
}

.ui-square-content,
.ui-rect-content {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.text-strike-through {
  text-decoration: line-through;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ui-stars,
.ui-star,
.ui-star > * {
  height: 1.1em;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.ui-stars {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}

.ui-star {
  position: relative;
  display: block;
  float: left;
  width: 1.1em;
  height: 1.1em;
  text-decoration: none !important;
  font-size: 1.1em;
  line-height: 1;
  user-select: none;
}
[dir=rtl] .ui-star {
  float: right;
}
.ui-star + .ui-star {
  margin-left: -0.1em;
}
[dir=rtl] .ui-star + .ui-star {
  margin-right: -0.1em;
  margin-left: 0;
}
.ui-star > *,
.ui-star > *::before,
.ui-star > *::after {
  position: absolute;
  left: 0.55em;
  height: 100%;
  font-size: 1em;
  line-height: 1;
  transform: translateX(-50%);
}
[dir=rtl] .ui-star > *,
[dir=rtl] .ui-star > *::before,
[dir=rtl] .ui-star > *::after {
  right: 0.55em;
  left: auto;
  transform: translateX(50%);
}
.ui-star > * {
  top: 0;
  width: 100%;
  text-align: center;
}
.ui-star > *:first-child {
  z-index: 10;
  display: none;
  overflow: hidden;
  color: #fdac41;
}
.ui-star > *:last-child {
  z-index: 5;
  display: block;
}
.ui-star.half-filled > *:first-child {
  width: 50%;
  transform: translateX(-100%);
}
[dir=rtl] .ui-star.half-filled > *:first-child {
  transform: translateX(100%);
}
.ui-star.filled > *:first-child, .ui-star.half-filled > *:first-child {
  display: block;
}
.ui-star.filled > *:last-child {
  display: none;
}

.ui-stars.hoverable .ui-star > *:first-child {
  display: block;
}

.ui-stars.hoverable .ui-star:first-child:not(.filled) > *:first-child,
.ui-stars.hoverable .ui-star:first-child:not(.filled) ~ .ui-star > *:first-child,
.ui-stars.hoverable .ui-star:first-child:not(.half-filled) > *:first-child,
.ui-stars.hoverable .ui-star:first-child:not(.half-filled) ~ .ui-star > *:first-child {
  display: none;
}

.ui-stars.hoverable .ui-star.filled > *:first-child,
.ui-stars.hoverable .ui-star.half-filled > *:first-child {
  display: block !important;
}

.ui-stars.hoverable:hover .ui-star > *:first-child {
  display: block !important;
  width: 100% !important;
  transform: translateX(-50%) !important;
}
[dir=rtl] .ui-stars.hoverable:hover .ui-star > *:first-child {
  transform: translateX(50%) !important;
}

.ui-stars.hoverable .ui-star:hover ~ .ui-star > *:first-child {
  display: none !important;
}
.ui-stars.hoverable .ui-star:hover ~ .ui-star > *:last-child {
  display: block !important;
}

.ui-bg-cover {
  background-color: rgba(0, 0, 0, 0);
  background-position: center center;
  background-size: cover;
}

.ui-bg-overlay-container,
.ui-bg-video-container {
  position: relative;
}
.ui-bg-overlay-container > *,
.ui-bg-video-container > * {
  position: relative;
}

.ui-bg-overlay-container .ui-bg-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}

.menu {
  display: flex;
}
.menu .app-brand {
  width: 100%;
}
.menu .ps__thumb-y,
.menu .ps__rail-y {
  width: 0.125rem !important;
}
.menu .ps__rail-y {
  right: 0.25rem !important;
  left: auto !important;
  background: none !important;
}
[dir=rtl] .menu .ps__rail-y {
  right: auto !important;
  left: 0.25rem !important;
}
.menu .ps__rail-y:hover,
.menu .ps__rail-y:focus,
.menu .ps__rail-y.ps--clicking,
.menu .ps__rail-y:hover > .ps__thumb-y,
.menu .ps__rail-y:focus > .ps__thumb-y,
.menu .ps__rail-y.ps--clicking > .ps__thumb-y {
  width: 0.375rem !important;
}

.menu-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.menu-inner-shadow {
  display: none;
  position: absolute;
  top: 3.225rem;
  height: 3rem;
  width: 100%;
  pointer-events: none;
  z-index: 2;
}
html:not(.layout-menu-fixed) .menu-inner-shadow {
  display: none !important;
}

.menu-item {
  align-items: flex-start;
  justify-content: flex-start;
}
.menu-item.menu-item-animating {
  transition: height 0.3s ease-in-out;
}

.menu-item,
.menu-header,
.menu-divider,
.menu-block {
  flex: 0 0 auto;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu-header {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.menu-icon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 0.5rem;
  font-size: 1.25rem;
}
.menu:not(.menu-no-animation) .menu-icon {
  transition: margin-right 0.3s ease;
}
[dir=rtl] .menu-icon {
  margin-right: 0;
  margin-left: 0.5rem;
}
.menu:not(.menu-no-animation) [dir=rtl] .menu-icon {
  transition: margin-left 0.3s ease;
}

.menu-link {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  margin: 0;
}
.menu-item.disabled .menu-link {
  cursor: not-allowed !important;
}
.menu:not(.menu-no-animation) .menu-link {
  transition-duration: 0.3s;
  transition-property: color, background-color;
}
.menu-link > :not(.menu-icon) {
  flex: 0 1 auto;
  opacity: 1;
}

.menu-sub {
  display: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.menu:not(.menu-no-animation) .menu-sub {
  transition: background-color 0.3s;
}
.menu-item.open > .menu-sub {
  display: flex;
}

.menu-toggle::after {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  width: 0.45em;
  height: 0.45em;
  border: 1.5px solid;
  border-bottom: 0;
  border-left: 0;
  transform: translateY(-50%) rotate(45deg);
}
[dir=rtl] .menu-toggle::after {
  border-left: 1.5px solid;
  border-right: 0;
  transform: translateY(-50%) rotate(-45deg);
}
.menu-item.open:not(.menu-item-closing) > .menu-toggle::after {
  transform: translateY(-50%) rotate(135deg);
}
[dir=rtl] .menu-item.open:not(.menu-item-closing) > .menu-toggle::after {
  transform: translateY(-50%) rotate(-135deg);
}
.menu:not(.menu-no-animation) .menu-toggle::after {
  transition-duration: 0.3s;
  transition-property: -webkit-transform, transform;
}

.menu-divider {
  width: 100%;
  border: 0;
  border-top: 1px solid;
}

.menu-vertical {
  flex-direction: column;
  width: 16.25rem;
}
.menu-vertical:not(.menu-no-animation) {
  transition: width 0.3s;
}
.menu-vertical .menu-block,
.menu-vertical .menu-inner > .menu-item,
.menu-vertical .menu-inner > .menu-header {
  width: 14.374rem;
}
.menu-vertical .menu-inner {
  flex-direction: column;
  flex: 1 1 auto;
}
.menu-vertical .menu-inner > .menu-item {
  margin: 0.0625rem 0.938rem;
}
.menu-vertical .menu-item .menu-link,
.menu-vertical .menu-block {
  padding: 0.625rem 0.938rem;
}
.menu-vertical .menu-header {
  margin: 0.938rem 0 0.469rem 0;
  padding: 0.625rem 1.876rem 0.625rem 1.876rem;
}
.menu-vertical .menu-item .menu-link {
  font-size: 0.9375rem;
}
.menu-vertical .menu-item.active > .menu-link:not(.menu-toggle) {
  font-weight: 600;
}
.menu-vertical .menu-item .menu-toggle {
  padding-right: calc(0.938rem + 1.35em);
}
[dir=rtl] .menu-vertical .menu-item .menu-toggle {
  padding-right: 0.938rem;
  padding-left: calc(0.938rem + 1.35em);
}
.menu-vertical .menu-item .menu-toggle::after {
  right: 0.938rem;
}
[dir=rtl] .menu-vertical .menu-item .menu-toggle::after {
  right: auto;
  left: 0.938rem;
}
.menu-vertical .menu-divider {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  padding: 0;
}
.menu-vertical .menu-sub {
  padding-top: 0.125rem;
}
.menu-vertical .menu-sub .menu-link {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.menu-vertical .menu-icon {
  width: 1.5rem;
}
.menu-vertical .menu-sub .menu-icon {
  margin-right: 0;
}
@media (max-width: 1199.98px) {
  .menu-vertical .menu-sub .menu-icon {
    display: none;
  }
}
[dir=rtl] .menu-vertical .menu-sub .menu-icon {
  margin-left: 0;
}
.menu-vertical .menu-horizontal-wrapper {
  flex: none;
}
.menu-vertical .menu-sub .menu-link {
  padding-left: 2.938rem;
}
[dir=rtl] .menu-vertical .menu-sub .menu-link {
  padding-right: 2.938rem;
  padding-left: 0.938rem;
}
.menu-vertical .menu-sub .menu-sub .menu-link {
  padding-left: 3.588rem;
}
[dir=rtl] .menu-vertical .menu-sub .menu-sub .menu-link {
  padding-right: 3.588rem;
  padding-left: 0.938rem;
}
.menu-vertical .menu-sub .menu-sub .menu-sub .menu-link {
  padding-left: 4.238rem;
}
[dir=rtl] .menu-vertical .menu-sub .menu-sub .menu-sub .menu-link {
  padding-right: 4.238rem;
  padding-left: 0.938rem;
}
.menu-vertical .menu-sub .menu-sub .menu-sub .menu-sub .menu-link {
  padding-left: 4.888rem;
}
[dir=rtl] .menu-vertical .menu-sub .menu-sub .menu-sub .menu-sub .menu-link {
  padding-right: 4.888rem;
  padding-left: 0.938rem;
}
.menu-vertical .menu-sub .menu-sub .menu-sub .menu-sub .menu-sub .menu-link {
  padding-left: 5.538rem;
}
[dir=rtl] .menu-vertical .menu-sub .menu-sub .menu-sub .menu-sub .menu-sub .menu-link {
  padding-right: 5.538rem;
  padding-left: 0.938rem;
}

.menu-collapsed:not(:hover) {
  width: 5rem;
}
.menu-collapsed:not(:hover) .menu-inner > .menu-item {
  width: 3.124rem;
}
.menu-collapsed:not(:hover) .menu-inner > .menu-item > .menu-link {
  padding-left: 0.938rem;
}
.menu-collapsed:not(:hover) .menu-inner > .menu-header,
.menu-collapsed:not(:hover) .menu-block {
  position: relative;
  margin-left: 0.938rem;
  padding-right: 1.376rem;
  padding-left: 0.5rem;
  width: 16.25rem;
  text-indent: -9999px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu-collapsed:not(:hover) .menu-inner > .menu-header .menu-header-text,
.menu-collapsed:not(:hover) .menu-block .menu-header-text {
  overflow: hidden;
  opacity: 0;
}
.menu-collapsed:not(:hover) .menu-inner > .menu-header::before,
.menu-collapsed:not(:hover) .menu-block::before {
  content: "";
  position: absolute;
  left: 1.062rem;
  display: block;
  width: 1.25rem;
  text-align: center;
  top: 1.1875rem;
  height: 0.125rem;
}
.menu-collapsed:not(:hover) .menu-inner > .menu-item div:not(.menu-block) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
}
.menu-collapsed:not(:hover) .menu-inner > .menu-item > .menu-sub,
.menu-collapsed:not(:hover) .menu-inner > .menu-item.open > .menu-sub {
  display: none;
}
.menu-collapsed:not(:hover) .menu-inner > .menu-item > .menu-toggle::after {
  display: none;
}
.menu-collapsed:not(:hover) .menu-inner > .menu-item > .menu-link .menu-icon {
  margin-left: -1.876rem;
  width: 5rem;
  text-align: center;
  margin-right: 0;
}
[dir=rtl] .menu-collapsed:not(:hover) .menu-block {
  width: 5rem !important;
}
[dir=rtl] .menu-collapsed:not(:hover) .menu-inner > .menu-item > .menu-link {
  padding-right: 0.938rem;
  padding-left: 0.938rem;
}
[dir=rtl] .menu-collapsed:not(:hover) .menu-inner > .menu-header,
[dir=rtl] .menu-collapsed:not(:hover) .menu-block {
  margin-right: 0.938rem;
  margin-left: 0;
  padding-right: 0.5rem;
  padding-left: 1.376rem;
}
[dir=rtl] .menu-collapsed:not(:hover) .menu-inner > .menu-header::before,
[dir=rtl] .menu-collapsed:not(:hover) .menu-block::before {
  right: 1.062rem;
  left: auto;
}
[dir=rtl] .menu-collapsed:not(:hover) .menu-inner > .menu-item > .menu-link .menu-icon {
  margin-right: -1.876rem;
  margin-left: 0;
}

.menu-horizontal {
  flex-direction: row;
  width: 100%;
}
.menu-horizontal .menu-inner {
  overflow: hidden;
  flex-direction: row;
  flex: 0 1 100%;
}
.menu-horizontal .menu-item .menu-link {
  padding: 0.565rem calc(1rem - 0.0625rem);
}
.menu-horizontal .menu-item .menu-toggle {
  padding-right: calc(1rem + 1.35em - 0.0625rem);
}
[dir=rtl] .menu-horizontal .menu-item .menu-toggle {
  padding-right: calc(1rem - 0.0625rem);
  padding-left: calc(1rem + 1.35em - 0.0625rem);
}
.menu-horizontal .menu-item .menu-toggle::after {
  right: 1rem;
  transform: translateY(-50%) rotate(45deg) !important;
}
[dir=rtl] .menu-horizontal .menu-item .menu-toggle::after {
  transform: translateY(-50%) rotate(-45deg) !important;
  right: auto;
  left: 1rem;
}
.menu-horizontal .menu-inner > .menu-item > .menu-toggle::after {
  transform: translateY(-50%) rotate(135deg) !important;
}
[dir=rtl] .menu-horizontal .menu-inner > .menu-item > .menu-toggle::after {
  transform: translateY(-75%) rotate(-135deg) !important;
}
.menu-horizontal .menu-sub .menu-item.open:not(.menu-item-closing) > .menu-toggle::after {
  transform: translateY(-50%) rotate(45deg);
}
[dir=rtl] .menu-horizontal .menu-sub .menu-item.open:not(.menu-item-closing) > .menu-toggle::after {
  transform: translateY(-50%) rotate(-45deg);
}
.menu-horizontal .menu-inner > .menu-item:not(.menu-item-closing).open .menu-item.open {
  position: relative;
}
.menu-horizontal .menu-header,
.menu-horizontal .menu-divider {
  display: none !important;
}
.menu-horizontal .menu-sub {
  position: absolute;
  width: 14.5rem;
  padding: 0.325rem 0;
  border-radius: 0.3125rem;
}
.menu-horizontal .menu-sub .menu-sub {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
}
html:not([dir=rtl]) .menu-horizontal .menu-sub .menu-sub .menu-item .menu-link {
  padding-left: 2.35rem;
}
[dir=rtl] .menu-horizontal .menu-sub .menu-sub .menu-item .menu-link {
  padding-right: 2.35rem;
}
[dir=rtl] .menu-horizontal .menu-sub .menu-sub {
  left: -100%;
}
.menu-horizontal .menu-sub .menu-link {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border-radius: 0 !important;
}
.menu-horizontal .menu-sub .menu-item.active > .menu-link:not(.menu-toggle) {
  font-weight: 600;
}
.menu-horizontal:not(.menu-no-animation) .menu-inner .menu-item.open .menu-sub {
  animation: menuDropdownShow 0.3s ease-in-out;
}
.menu-horizontal .menu-sub > .menu-sub .menu-link {
  padding-left: 2.35rem;
}
[dir=rtl] .menu-horizontal .menu-sub > .menu-sub .menu-link {
  padding-right: 2.35rem;
  padding-left: 1rem;
}
@media (max-width: 991.98px) {
  .menu-horizontal {
    display: none;
  }
}

.menu-horizontal-wrapper {
  overflow: hidden;
  flex: 0 1 100%;
  width: 0;
}
.menu:not(.menu-no-animation) .menu-horizontal-wrapper .menu-inner {
  transition: margin 0.3s;
}

.menu-horizontal-prev,
.menu-horizontal-next {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: 2.25rem;
}
.menu-horizontal-prev::after,
.menu-horizontal-next::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border: 1px solid;
  border-top: 0;
}
.menu-horizontal-prev.disabled,
.menu-horizontal-next.disabled {
  cursor: not-allowed !important;
}

.menu-horizontal-prev::after {
  border-right: 0;
  transform: translate(-50%, -50%) rotate(45deg);
}
[dir=rtl] .menu-horizontal-prev::after {
  transform: translate(-50%, -50%) rotate(-135deg);
}

.menu-horizontal-next::after {
  border-left: 0;
  transform: translate(-50%, -50%) rotate(-45deg);
}
[dir=rtl] .menu-horizontal-next::after {
  transform: translate(-50%, -50%) rotate(135deg);
}

@-webkit-keyframes menuDropdownShow {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-moz-keyframes menuDropdownShow {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes menuDropdownShow {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.menu-light {
  color: #637ba9;
}
.menu-light .menu-link,
.menu-light .menu-horizontal-prev,
.menu-light .menu-horizontal-next {
  color: #637ba9;
}
.menu-light .menu-link:hover, .menu-light .menu-link:focus,
.menu-light .menu-horizontal-prev:hover,
.menu-light .menu-horizontal-prev:focus,
.menu-light .menu-horizontal-next:hover,
.menu-light .menu-horizontal-next:focus {
  color: #4e5155;
  border-radius: 0.3125rem;
}
.menu-light .menu-link.active,
.menu-light .menu-horizontal-prev.active,
.menu-light .menu-horizontal-next.active {
  color: #4e5155;
}
.menu-light .menu-item.disabled .menu-link {
  color: rgba(0, 0, 0, 0.2) !important;
}
.menu-light .menu-item.open:not(.menu-item-closing) > .menu-toggle,
.menu-light .menu-item.active > .menu-link {
  color: #4e5155;
}
.menu-light .menu-item.active > .menu-link:not(.menu-toggle) {
  background: rgba(0, 0, 0, 0.05);
}
.menu-light .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-sub,
.menu-light .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-toggle {
  color: #637ba9;
}
.menu-light .menu-text {
  color: #4e5155;
}
.menu-light .menu-header {
  color: #637ba9;
}
.menu-light hr,
.menu-light .menu-divider,
.menu-light .menu-inner > .menu-item.open > .menu-sub::before {
  border-color: rgba(0, 0, 0, 0.06) !important;
}
.menu-light .menu-inner > .menu-header::before,
.menu-light .menu-block::before {
  background-color: rgba(0, 0, 0, 0.2);
}
.menu-light .menu-inner > .menu-item.open .menu-item.open > .menu-toggle::before {
  background-color: rgba(0, 0, 0, 0.06);
}
.menu-light .menu-inner > .menu-item.open .menu-item.active > .menu-link::before {
  background-color: #4e5155;
}
.menu-light .ps__thumb-y {
  background: #637ba9 !important;
}

.menu-dark {
  color: rgba(255, 255, 255, 0.8);
}
.menu-dark .menu-link,
.menu-dark .menu-horizontal-prev,
.menu-dark .menu-horizontal-next {
  color: rgba(255, 255, 255, 0.8);
}
.menu-dark .menu-link:hover, .menu-dark .menu-link:focus,
.menu-dark .menu-horizontal-prev:hover,
.menu-dark .menu-horizontal-prev:focus,
.menu-dark .menu-horizontal-next:hover,
.menu-dark .menu-horizontal-next:focus {
  color: #fff;
}
.menu-dark .menu-link.active,
.menu-dark .menu-horizontal-prev.active,
.menu-dark .menu-horizontal-next.active {
  color: #fff;
}
.menu-dark .menu-item.disabled .menu-link {
  color: rgba(255, 255, 255, 0.4) !important;
}
.menu-dark .menu-item.open:not(.menu-item-closing) > .menu-toggle,
.menu-dark .menu-item.active > .menu-link {
  color: #fff;
}
.menu-dark .menu-item.active > .menu-link:not(.menu-toggle) {
  background: rgba(0, 0, 0, 0.06);
}
.menu-dark .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-sub,
.menu-dark .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-toggle {
  color: rgba(255, 255, 255, 0.8);
}
.menu-dark .menu-text {
  color: #fff;
}
.menu-dark .menu-header {
  color: rgba(255, 255, 255, 0.8);
}
.menu-dark hr,
.menu-dark .menu-divider,
.menu-dark .menu-inner > .menu-item.open > .menu-sub::before {
  border-color: rgba(255, 255, 255, 0.2) !important;
}
.menu-dark .menu-inner > .menu-header::before,
.menu-dark .menu-block::before {
  background-color: rgba(255, 255, 255, 0.4);
}
.menu-dark .menu-inner > .menu-item.open .menu-item.open > .menu-toggle::before {
  background-color: rgba(255, 255, 255, 0.2);
}
.menu-dark .menu-inner > .menu-item.open .menu-item.active > .menu-link::before {
  background-color: #fff;
}
.menu-dark .ps__thumb-y {
  background: rgba(255, 255, 255, 0.8) !important;
}

.layout-container {
  min-height: 100vh;
}

.layout-wrapper,
.layout-container {
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
}

.layout-menu-offcanvas .layout-wrapper,
.layout-menu-fixed-offcanvas .layout-wrapper {
  overflow: hidden;
}

.layout-wrapper:not(.layout-horizontal) .breadcrumb-wrapper {
  padding-top: 0 !important;
}

.layout-menu-offcanvas .layout-navbar .layout-menu-toggle,
.layout-menu-fixed-offcanvas .layout-navbar .layout-menu-toggle {
  display: block !important;
}

@media (min-width: 1200px) {
  .layout-menu-offcanvas .layout-menu .layout-menu-toggle,
.layout-menu-fixed-offcanvas .layout-menu .layout-menu-toggle {
    display: none;
  }
}
.layout-page,
.content-wrapper,
.content-wrapper > *,
.layout-menu {
  min-height: 1px;
}

.layout-navbar,
.content-footer {
  flex: 0 0 auto;
}

.layout-page {
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  padding: 0;
}
.layout-without-menu .layout-page {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.content-wrapper {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
}
.content-wrapper .container-p-y:not([class^=pt-]):not([class*=" pt-"]) {
  padding-top: 1.15rem !important;
}

.content-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.content-backdrop.fade {
  opacity: 0;
}
.content-backdrop.show {
  opacity: 0.5;
}
.layout-menu-fixed .content-backdrop {
  z-index: 10;
}
.layout-horizontal .content-backdrop:not(.fade) {
  z-index: 9;
}
.content-backdrop.fade {
  z-index: -1;
}

.layout-navbar {
  position: relative;
  padding-top: 0.25rem;
  padding-bottom: 0.2rem;
  height: 3.875rem;
  flex-wrap: nowrap;
  color: #a1b0cb;
  z-index: 2;
}
.layout-navbar .navbar {
  transform: translate3d(0, 0, 0);
}
.layout-navbar .navbar-nav-right {
  flex-basis: 100%;
}
.layout-navbar .navbar-search-wrapper .search-input,
.layout-navbar .navbar-search-wrapper .input-group-text {
  background-color: transparent;
}
.layout-horizontal .layout-navbar .navbar-search-wrapper .search-input,
.layout-horizontal .layout-navbar .navbar-search-wrapper .search-toggler {
  background-color: #283144 !important;
}
.layout-navbar .navbar-search-wrapper .navbar-search-suggestion {
  max-height: 28rem;
  border-radius: 0.5rem;
}
.layout-navbar .navbar-search-wrapper .navbar-search-suggestion .suggestion {
  color: #a1b0cb;
}
.layout-navbar .navbar-search-wrapper .navbar-search-suggestion .suggestion.active {
  background: #1c222f;
  color: #a1b0cb;
}
.layout-navbar .navbar-search-wrapper .navbar-search-suggestion .suggestions-header {
  font-weight: 700;
}
.layout-navbar .search-input-wrapper .search-toggler {
  position: absolute;
  top: 1.25rem;
  right: 1rem;
  z-index: 1;
}
[dir=rtl] .layout-navbar .search-input-wrapper .search-toggler {
  right: inherit;
  left: 1rem;
}
.layout-navbar .search-input-wrapper .twitter-typeahead {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 1200px) {
  .layout-navbar .search-input-wrapper .twitter-typeahead.container-xxl {
    left: calc((100% - 1440px) * 0.5);
  }
  [dir=rtl] .layout-navbar .search-input-wrapper .twitter-typeahead.container-xxl {
    right: calc((100% - 1440px) * 0.5);
    left: inherit;
  }
  .layout-navbar .search-input-wrapper .twitter-typeahead.container-xxl + .search-toggler {
    right: calc((100% - 1440px + 5rem) * 0.5);
  }
  [dir=rtl] .layout-navbar .search-input-wrapper .twitter-typeahead.container-xxl + .search-toggler {
    left: calc((100% - 1440px + 5rem) * 0.5);
    right: inherit;
  }
}
.layout-navbar .search-input-wrapper .search-input {
  height: 100%;
  box-shadow: none;
}
.layout-navbar .search-input-wrapper .navbar-search-suggestion {
  left: 2% !important;
  width: 96%;
}
.layout-navbar .navbar-dropdown .badge-notifications {
  top: 0.5rem;
  padding: 0.2rem 0.4rem;
}
.layout-navbar .navbar-dropdown .dropdown-menu {
  min-width: 22rem;
  overflow: hidden;
}
.layout-navbar .navbar-dropdown .dropdown-menu .last-login {
  white-space: normal;
}
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list {
  max-height: 27.5rem;
}
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list .dropdown-notifications-item {
  padding: 1rem;
  cursor: pointer;
}
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list .dropdown-notifications-item .dropdown-notifications-actions {
  text-align: center;
}
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list .dropdown-notifications-item .dropdown-notifications-actions > a {
  display: block;
}
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list .dropdown-notifications-item .dropdown-notifications-archive i,
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list .dropdown-notifications-item .dropdown-notifications-archive span {
  color: #a1b0cb;
}
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list .dropdown-notifications-item.marked-as-read .dropdown-notifications-read,
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list .dropdown-notifications-item.marked-as-read .dropdown-notifications-archive {
  visibility: hidden;
}
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list .dropdown-notifications-item.marked-as-read .dropdown-notifications-read span {
  background-color: #69809a;
}
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list .dropdown-notifications-item:not(.marked-as-read) .dropdown-notifications-archive {
  visibility: hidden;
}
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list .dropdown-notifications-item:hover.marked-as-read .dropdown-notifications-read,
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list .dropdown-notifications-item:hover.marked-as-read .dropdown-notifications-archive {
  visibility: visible;
}
.layout-navbar .navbar-dropdown.dropdown-notifications .dropdown-notifications-list .dropdown-notifications-item:hover:not(.marked-as-read) .dropdown-notifications-archive {
  visibility: visible;
}
.layout-navbar .navbar-dropdown.dropdown-shortcuts .dropdown-shortcuts-list {
  max-height: 27.5rem;
}
.layout-navbar .navbar-dropdown.dropdown-shortcuts .dropdown-shortcuts-item {
  text-align: center;
  padding: 1.5rem;
}
.layout-navbar .navbar-dropdown.dropdown-shortcuts .dropdown-shortcuts-item:hover {
  background-color: rgba(99, 123, 169, 0.08);
}
.layout-navbar .navbar-dropdown.dropdown-shortcuts .dropdown-shortcuts-item .dropdown-shortcuts-icon {
  height: 3.125rem;
  width: 3.125rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout-navbar .navbar-dropdown.dropdown-shortcuts .dropdown-shortcuts-item a,
.layout-navbar .navbar-dropdown.dropdown-shortcuts .dropdown-shortcuts-item a:hover {
  display: block;
  margin-bottom: 0;
  color: #a1b0cb !important;
  font-weight: 600;
}
.layout-navbar .navbar-dropdown.dropdown-user .dropdown-menu {
  min-width: 14rem;
}
.layout-navbar[class*=bg-]:not(.bg-navbar-theme) .nav-item .input-group-text,
.layout-navbar[class*=bg-]:not(.bg-navbar-theme) .nav-item .dropdown-toggle {
  color: #fff;
}
@media (max-width: 1199.98px) {
  .layout-navbar .navbar-nav .nav-item.dropdown .dropdown-menu {
    position: absolute;
  }
  .layout-navbar .navbar-nav .nav-item.dropdown .dropdown-menu .last-login {
    white-space: nowrap;
  }
}
@media (max-width: 767.98px) {
  .layout-navbar .navbar-nav .nav-item.dropdown {
    position: static;
    float: left;
  }
  .layout-navbar .navbar-nav .nav-item.dropdown .badge-notifications {
    top: auto;
  }
  .layout-navbar .navbar-nav .nav-item.dropdown .dropdown-menu {
    position: absolute;
    left: 0.9rem;
    min-width: auto;
    width: 92%;
  }
}

@media (max-width: 1199.98px) {
  .layout-navbar {
    z-index: 1080;
  }
}
[dir=rtl] .navbar.layout-navbar .search-input-wrapper .navbar-search-suggestion {
  right: 2% !important;
}

.layout-menu {
  position: relative;
  flex: 1 0 auto;
}
.layout-menu .menu {
  transform: translate3d(0, 0, 0);
}
.layout-menu .menu-vertical {
  height: 100%;
}

.layout-front .app-content {
  min-height: calc(100vh - 8rem);
}

.navbar-collapse-header {
  display: none;
}

@media (max-width: 991.98px) {
  .navbar-front .navbar-collapse-header {
    display: block;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-front .navbar-collapse {
    position: absolute;
    z-index: 1090;
    top: 0;
    right: 0;
    left: 0;
    overflow-y: auto;
    width: calc(100% - (0.5rem * 2));
    height: auto !important;
    margin: 0.5rem;
  }
  .navbar-front .navbar-collapse.collapsing, .navbar-front .navbar-collapse.show {
    padding: 1.375rem;
    border-radius: 0.25rem;
    background: #fff;
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
  }
}
.layout-content-navbar .layout-page {
  flex-basis: 100%;
  flex-direction: column;
  width: 0;
  min-width: 0;
  max-width: 100%;
}
.layout-content-navbar .content-wrapper {
  width: 100%;
}

.layout-navbar-full .layout-container {
  flex-direction: column;
}
@media (min-width: 1200px) {
  .layout-navbar-full:not(.layout-horizontal) .menu-inner {
    margin-top: 0.75rem;
  }
}
.layout-navbar-full .content-wrapper {
  flex-basis: 100%;
  width: 0;
  min-width: 0;
  max-width: 100%;
}
.layout-navbar-full .content-backdrop.show {
  z-index: 9;
}
.layout-menu-fixed .layout-navbar-full .content-backdrop.show, .layout-menu-fixed-offcanvas .layout-navbar-full .content-backdrop.show {
  z-index: 1076;
}

.layout-menu-flipped .layout-navbar-full .layout-page {
  flex-direction: row-reverse;
}
.layout-menu-flipped .layout-content-navbar .layout-container {
  flex-direction: row-reverse;
}

.layout-menu-toggle {
  display: block;
}
.layout-menu-toggle .menu-toggle-icon:before {
  content: "\ed06";
}
.layout-menu-collapsed .layout-menu-toggle .menu-toggle-icon:before {
  content: "\ecbf";
}

@media (min-width: 1200px) {
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical {
    width: 5rem;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-item,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-item {
    width: 3.124rem;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-item > .menu-link,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-item > .menu-link {
    padding-left: 0.938rem;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-header,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-block,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-header,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-block {
    position: relative;
    margin-left: 0.938rem;
    padding-right: 1.376rem;
    padding-left: 0.5rem;
    width: 16.25rem;
    text-indent: -9999px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-header .menu-header-text,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-block .menu-header-text,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-header .menu-header-text,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-block .menu-header-text {
    overflow: hidden;
    opacity: 0;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-header::before,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-block::before,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-header::before,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-block::before {
    content: "";
    position: absolute;
    left: 1.062rem;
    display: block;
    width: 1.25rem;
    text-align: center;
    top: 1.1875rem;
    height: 0.125rem;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-item div:not(.menu-block),
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-item div:not(.menu-block) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-item > .menu-sub,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-item.open > .menu-sub,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-item > .menu-sub,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-item.open > .menu-sub {
    display: none;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-item > .menu-toggle::after,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-item > .menu-toggle::after {
    display: none;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-item > .menu-link .menu-icon,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-item > .menu-link .menu-icon {
    margin-left: -1.876rem;
    width: 5rem;
    text-align: center;
    margin-right: 0;
  }

  [dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-block,
[dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-block {
    width: 5rem !important;
  }
  [dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-item > .menu-link,
[dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-item > .menu-link {
    padding-right: 0.938rem;
    padding-left: 0.938rem;
  }
  [dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-header,
[dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-block,
[dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-header,
[dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-block {
    margin-right: 0.938rem;
    margin-left: 0;
    padding-right: 0.5rem;
    padding-left: 1.376rem;
  }
  [dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-header::before,
[dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-block::before,
[dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-header::before,
[dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-block::before {
    right: 1.062rem;
    left: auto;
  }
  [dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .menu-vertical .menu-inner > .menu-item > .menu-link .menu-icon,
[dir=rtl].layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu.menu-vertical .menu-inner > .menu-item > .menu-link .menu-icon {
    margin-right: -1.876rem;
    margin-left: 0;
  }

  .layout-menu-hover.layout-menu-collapsed .layout-menu {
    margin-right: -11.25rem;
  }
  .layout-menu-hover.layout-menu-collapsed.layout-menu-flipped .layout-menu {
    margin-left: -11.25rem;
    margin-right: 0;
  }

  [dir=rtl].layout-menu-hover.layout-menu-collapsed .layout-menu {
    margin-left: -11.25rem;
    margin-right: 0;
  }
  [dir=rtl].layout-menu-hover.layout-menu-collapsed.layout-menu-flipped .layout-menu {
    margin-right: -11.25rem;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .layout-menu-collapsed.layout-menu-offcanvas .layout-menu {
    margin-right: -16.25rem;
    transform: translateX(-100%);
  }
  .layout-menu-collapsed.layout-menu-offcanvas.layout-menu-flipped .layout-menu {
    margin-right: 0;
    margin-left: -16.25rem;
    transform: translateX(100%);
  }

  [dir=rtl].layout-menu-collapsed.layout-menu-offcanvas .layout-menu {
    margin-right: 0;
    margin-left: -16.25rem;
    transform: translateX(100%);
  }
  [dir=rtl].layout-menu-collapsed.layout-menu-offcanvas.layout-menu-flipped .layout-menu {
    margin-right: -16.25rem;
    margin-left: 0;
    transform: translateX(-100%);
  }
}
@media (min-width: 1200px) {
  .layout-menu-fixed .layout-menu,
.layout-menu-fixed-offcanvas .layout-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .layout-menu-fixed.layout-menu-flipped .layout-menu,
.layout-menu-fixed-offcanvas.layout-menu-flipped .layout-menu {
    right: 0;
    left: auto;
  }

  [dir=rtl].layout-menu-fixed .layout-menu, [dir=rtl].layout-menu-fixed-offcanvas .layout-menu {
    right: 0;
    left: auto;
  }
  [dir=rtl].layout-menu-fixed.layout-menu-flipped .layout-menu, [dir=rtl].layout-menu-fixed-offcanvas.layout-menu-flipped .layout-menu {
    right: auto;
    left: 0;
  }

  .layout-menu-fixed-offcanvas.layout-menu-collapsed .layout-menu {
    transform: translateX(-100%);
  }
  .layout-menu-fixed-offcanvas.layout-menu-collapsed.layout-menu-flipped .layout-menu {
    transform: translateX(100%);
  }

  [dir=rtl].layout-menu-fixed-offcanvas.layout-menu-collapsed .layout-menu {
    transform: translateX(100%);
  }
  [dir=rtl].layout-menu-fixed-offcanvas.layout-menu-collapsed.layout-menu-flipped .layout-menu {
    transform: translateX(-100%);
  }

  .layout-menu-fixed:not(.layout-menu-collapsed) .layout-page,
.layout-menu-fixed-offcanvas:not(.layout-menu-collapsed) .layout-page {
    padding-left: 16.25rem;
  }
  .layout-menu-fixed:not(.layout-menu-collapsed).layout-menu-flipped .layout-page,
.layout-menu-fixed-offcanvas:not(.layout-menu-collapsed).layout-menu-flipped .layout-page {
    padding-right: 16.25rem;
    padding-left: 0;
  }

  [dir=rtl].layout-menu-fixed:not(.layout-menu-collapsed) .layout-page, [dir=rtl].layout-menu-fixed-offcanvas:not(.layout-menu-collapsed) .layout-page {
    padding-right: 16.25rem;
    padding-left: 0;
  }
  [dir=rtl].layout-menu-fixed:not(.layout-menu-collapsed).layout-menu-flipped .layout-page, [dir=rtl].layout-menu-fixed-offcanvas:not(.layout-menu-collapsed).layout-menu-flipped .layout-page {
    padding-right: 0;
    padding-left: 16.25rem;
  }

  .layout-menu-fixed.layout-menu-collapsed .layout-page {
    padding-left: 5rem;
  }
  .layout-menu-fixed.layout-menu-collapsed.layout-menu-flipped .layout-page {
    padding-right: 5rem;
    padding-left: 0;
  }

  [dir=rtl].layout-menu-fixed.layout-menu-collapsed .layout-page {
    padding-right: 5rem;
    padding-left: 0;
  }
  [dir=rtl].layout-menu-fixed.layout-menu-collapsed.layout-menu-flipped .layout-page {
    padding-right: 0;
    padding-left: 5rem;
  }
}
html:not(.layout-navbar-fixed):not(.layout-menu-fixed):not(.layout-menu-fixed-offcanvas) .layout-page,
html:not(.layout-navbar-fixed) .layout-content-navbar .layout-page {
  padding-top: 0 !important;
}

html:not(.layout-footer-fixed) .content-wrapper {
  padding-bottom: 0 !important;
}

@media (max-width: 1199.98px) {
  .layout-menu-fixed .layout-wrapper.layout-navbar-full .layout-menu,
.layout-menu-fixed-offcanvas .layout-wrapper.layout-navbar-full .layout-menu {
    top: 0 !important;
  }

  html:not(.layout-navbar-fixed) .layout-navbar-full .layout-page {
    padding-top: 0 !important;
  }
}
.layout-navbar-fixed .layout-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}
.layout-navbar-fixed .layout-navbar.navbar-elevated {
  box-shadow: 6px 4px 30px 0 rgba(0, 0, 0, 0.22);
}

@media (min-width: 1200px) {
  .layout-menu-fixed .layout-navbar-full .layout-navbar,
.layout-menu-fixed-offcanvas .layout-navbar-full .layout-navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }

  .layout-navbar-fixed:not(.layout-menu-collapsed) .layout-content-navbar:not(.layout-without-menu) .layout-navbar,
.layout-menu-fixed.layout-navbar-fixed:not(.layout-menu-collapsed) .layout-content-navbar:not(.layout-without-menu) .layout-navbar,
.layout-menu-fixed-offcanvas.layout-navbar-fixed:not(.layout-menu-collapsed) .layout-content-navbar:not(.layout-without-menu) .layout-navbar {
    left: 16.25rem;
  }
  .layout-navbar-fixed:not(.layout-menu-collapsed).layout-menu-flipped .layout-content-navbar:not(.layout-without-menu) .layout-navbar,
.layout-menu-fixed.layout-navbar-fixed:not(.layout-menu-collapsed).layout-menu-flipped .layout-content-navbar:not(.layout-without-menu) .layout-navbar,
.layout-menu-fixed-offcanvas.layout-navbar-fixed:not(.layout-menu-collapsed).layout-menu-flipped .layout-content-navbar:not(.layout-without-menu) .layout-navbar {
    right: 16.25rem;
    left: 0;
  }

  [dir=rtl].layout-navbar-fixed:not(.layout-menu-collapsed) .layout-content-navbar:not(.layout-without-menu) .layout-navbar, [dir=rtl].layout-menu-fixed.layout-navbar-fixed:not(.layout-menu-collapsed) .layout-content-navbar:not(.layout-without-menu) .layout-navbar, [dir=rtl].layout-menu-fixed-offcanvas.layout-navbar-fixed:not(.layout-menu-collapsed) .layout-content-navbar:not(.layout-without-menu) .layout-navbar {
    right: 16.25rem;
    left: 0;
  }
  [dir=rtl].layout-navbar-fixed:not(.layout-menu-collapsed).layout-menu-flipped .layout-content-navbar:not(.layout-without-menu) .layout-navbar, [dir=rtl].layout-menu-fixed.layout-navbar-fixed:not(.layout-menu-collapsed).layout-menu-flipped .layout-content-navbar:not(.layout-without-menu) .layout-navbar, [dir=rtl].layout-menu-fixed-offcanvas.layout-navbar-fixed:not(.layout-menu-collapsed).layout-menu-flipped .layout-content-navbar:not(.layout-without-menu) .layout-navbar {
    right: 0;
    left: 16.25rem;
  }

  .layout-navbar-fixed.layout-menu-collapsed:not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-content-navbar .layout-navbar,
.layout-menu-fixed.layout-navbar-fixed.layout-menu-collapsed .layout-content-navbar .layout-navbar {
    left: 5rem;
  }
  .layout-navbar-fixed.layout-menu-collapsed:not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas).layout-menu-flipped .layout-content-navbar .layout-navbar,
.layout-menu-fixed.layout-navbar-fixed.layout-menu-collapsed.layout-menu-flipped .layout-content-navbar .layout-navbar {
    right: 5rem;
    left: 0;
  }

  [dir=rtl].layout-navbar-fixed.layout-menu-collapsed:not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-content-navbar .layout-navbar, [dir=rtl].layout-menu-fixed.layout-navbar-fixed.layout-menu-collapsed .layout-content-navbar .layout-navbar {
    right: 5rem;
    left: 0;
  }
  [dir=rtl].layout-navbar-fixed.layout-menu-collapsed:not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas).layout-menu-flipped .layout-content-navbar .layout-navbar, [dir=rtl].layout-menu-fixed.layout-navbar-fixed.layout-menu-collapsed.layout-menu-flipped .layout-content-navbar .layout-navbar {
    right: 0;
    left: 5rem;
  }
}
.layout-footer-fixed .content-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 1200px) {
  .layout-footer-fixed:not(.layout-menu-collapsed) .layout-wrapper:not(.layout-without-menu) .content-footer {
    left: 16.25rem;
  }
  .layout-footer-fixed:not(.layout-menu-collapsed).layout-menu-flipped .layout-wrapper:not(.layout-without-menu) .content-footer {
    right: 16.25rem;
    left: 0;
  }

  .layout-footer-fixed.layout-menu-collapsed:not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-wrapper:not(.layout-without-menu) .content-footer {
    left: 5rem;
  }
  .layout-footer-fixed.layout-menu-collapsed:not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas).layout-menu-flipped .layout-wrapper:not(.layout-without-menu) .content-footer {
    right: 5rem;
    left: 0;
  }

  [dir=rtl].layout-footer-fixed:not(.layout-menu-collapsed) .layout-wrapper:not(.layout-without-menu) .content-footer {
    left: 0;
    right: 16.25rem;
  }
  [dir=rtl].layout-footer-fixed:not(.layout-menu-collapsed).layout-menu-flipped .layout-wrapper:not(.layout-without-menu) .content-footer {
    left: 16.25rem;
    right: 0;
  }
  [dir=rtl].layout-footer-fixed.layout-menu-collapsed:not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-wrapper:not(.layout-without-menu) .content-footer {
    left: 0;
    right: 5rem;
  }
  [dir=rtl].layout-footer-fixed.layout-menu-collapsed:not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas).layout-menu-flipped .layout-wrapper:not(.layout-without-menu) .content-footer {
    right: 0;
    left: 5rem;
  }
}
@media (max-width: 1199.98px) {
  .layout-menu {
    position: fixed !important;
    top: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    transform: translate3d(-100%, 0, 0);
    will-change: transform, -webkit-transform;
  }
  [dir=rtl] .layout-menu {
    right: 0 !important;
    left: auto !important;
    transform: translate3d(100%, 0, 0);
  }
  .layout-menu-flipped .layout-menu {
    right: 0 !important;
    left: auto !important;
    transform: translate3d(100%, 0, 0);
  }
  .layout-menu-expanded .layout-menu {
    transform: translate3d(0, 0, 0) !important;
  }

  .layout-menu-expanded body {
    overflow: hidden;
  }

  [dir=rtl].layout-menu-flipped .layout-menu {
    right: auto !important;
    left: 0 !important;
    transform: translate3d(-100%, 0, 0);
  }

  .layout-overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100% !important;
    left: 0;
    display: none;
    background: #000;
    opacity: 0.5;
    cursor: pointer;
  }
  .layout-menu-expanded .layout-overlay {
    display: block;
  }

  .layout-menu-100vh .layout-menu,
.layout-menu-100vh .layout-overlay {
    height: 100vh !important;
  }

  .drag-target {
    height: 100%;
    width: 40px;
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 1036;
  }
}
.layout-navbar-fixed body:not(.modal-open) .layout-navbar-full .layout-navbar,
.layout-menu-fixed body:not(.modal-open) .layout-navbar-full .layout-navbar,
.layout-menu-fixed-offcanvas body:not(.modal-open) .layout-navbar-full .layout-navbar {
  z-index: 1080;
}
.layout-navbar-fixed body:not(.modal-open) .layout-content-navbar .layout-navbar,
.layout-menu-fixed body:not(.modal-open) .layout-content-navbar .layout-navbar,
.layout-menu-fixed-offcanvas body:not(.modal-open) .layout-content-navbar .layout-navbar {
  z-index: 1075;
}

.layout-footer-fixed .content-footer {
  z-index: 1030;
  box-shadow: 6px 4px 30px 0 rgba(0, 0, 0, 0.22);
}

.layout-menu-horizontal {
  z-index: 9;
}

@media (max-width: 1199.98px) {
  .layout-menu {
    z-index: 1100;
  }

  .layout-overlay {
    z-index: 1099;
  }
}
@media (min-width: 1200px) {
  .layout-navbar-full .layout-navbar {
    z-index: 10;
  }
  .layout-navbar-full .layout-menu {
    z-index: 9;
  }

  .layout-content-navbar .layout-navbar {
    z-index: 9;
  }
  .layout-content-navbar .layout-menu {
    z-index: 10;
  }

  .layout-menu-collapsed:not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas).layout-menu-hover .layout-navbar-full .layout-menu {
    z-index: 1075 !important;
  }
  .layout-menu-collapsed:not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-content-navbar .layout-menu {
    z-index: 1085 !important;
  }

  .layout-menu-fixed body:not(.modal-open) .layout-navbar-full .layout-menu,
.layout-menu-fixed-offcanvas body:not(.modal-open) .layout-navbar-full .layout-menu {
    z-index: 1075;
  }

  .layout-navbar-fixed body:not(.modal-open) .layout-content-navbar .layout-menu,
.layout-menu-fixed body:not(.modal-open) .layout-content-navbar .layout-menu,
.layout-menu-fixed-offcanvas body:not(.modal-open) .layout-content-navbar .layout-menu {
    z-index: 1080;
  }
}
.layout-menu-link-no-transition .layout-menu .menu-link,
.layout-menu-link-no-transition .layout-menu-horizontal .menu-link {
  transition: none !important;
  animation: none !important;
}

.layout-no-transition .layout-menu, .layout-no-transition .layout-menu .menu, .layout-no-transition .layout-menu .menu-item,
.layout-no-transition .layout-menu-horizontal,
.layout-no-transition .layout-menu-horizontal .menu,
.layout-no-transition .layout-menu-horizontal .menu-item {
  transition: none !important;
  animation: none !important;
}

@media (max-width: 1199.98px) {
  .layout-transitioning .layout-overlay {
    animation: menuAnimation 0.3s;
  }
  .layout-transitioning .layout-menu {
    transition-duration: 0.3s;
    transition-property: transform, -webkit-transform;
  }
}
@media (min-width: 1200px) {
  .layout-menu-collapsed:not(.layout-transitioning):not(.layout-menu-offcanvas):not(.layout-menu-fixed):not(.layout-menu-fixed-offcanvas) .layout-menu {
    transition-duration: 0.3s;
    transition-property: margin-left, margin-right, width;
  }

  .layout-transitioning.layout-menu-offcanvas .layout-menu {
    transition-duration: 0.3s;
    transition-property: margin-left, margin-right, transform, -webkit-transform;
  }
  .layout-transitioning.layout-menu-fixed .layout-page, .layout-transitioning.layout-menu-fixed-offcanvas .layout-page {
    transition-duration: 0.3s;
    transition-property: padding-left, padding-right;
  }
  .layout-transitioning.layout-menu-fixed .layout-menu {
    transition: width 0.3s;
  }
  .layout-transitioning.layout-menu-fixed-offcanvas .layout-menu {
    transition-duration: 0.3s;
    transition-property: transform, -webkit-transform;
  }
  .layout-transitioning.layout-navbar-fixed .layout-content-navbar .layout-navbar, .layout-transitioning.layout-footer-fixed .content-footer {
    transition-duration: 0.3s;
    transition-property: left, right;
  }
  .layout-transitioning:not(.layout-menu-offcanvas):not(.layout-menu-fixed):not(.layout-menu-fixed-offcanvas) .layout-menu {
    transition-duration: 0.3s;
    transition-property: margin-left, margin-right, width;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .menu,
.layout-menu,
.layout-page,
.layout-navbar,
.content-footer {
    transition: none !important;
    transition-duration: 0s !important;
  }

  .layout-overlay {
    animation: none !important;
  }
}
@-webkit-keyframes menuAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@-moz-keyframes menuAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes menuAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
.app-brand {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  line-height: 1;
  min-height: 1px;
  align-items: center;
}

.app-brand-link {
  display: flex;
  align-items: center;
}

.app-brand-logo {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  min-height: 1px;
}
.app-brand-logo img,
.app-brand-logo svg {
  display: block;
}

.app-brand-text {
  flex-shrink: 0;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.app-brand-img-collapsed {
  display: none;
}

.app-brand .layout-menu-toggle {
  display: block;
}

html:not([dir=rtl]) .menu-vertical .app-brand {
  padding-right: 1.25rem;
  padding-left: 1.5rem;
}
[dir=rtl] .menu-vertical .app-brand {
  padding-right: 1.5rem;
  padding-left: 1.25rem;
}

.menu-horizontal .app-brand,
.menu-horizontal .app-brand + .menu-divider {
  display: none !important;
}

:not(.layout-menu) > .menu-vertical.menu-collapsed:not(.layout-menu):not(:hover) .app-brand {
  width: 5rem;
}
:not(.layout-menu) > .menu-vertical.menu-collapsed:not(.layout-menu):not(:hover) .app-brand-logo,
:not(.layout-menu) > .menu-vertical.menu-collapsed:not(.layout-menu):not(:hover) .app-brand-link,
:not(.layout-menu) > .menu-vertical.menu-collapsed:not(.layout-menu):not(:hover) .app-brand-text {
  margin-right: auto;
  margin-left: auto;
}
:not(.layout-menu) > .menu-vertical.menu-collapsed:not(.layout-menu):not(:hover) .app-brand-logo ~ .app-brand-text,
:not(.layout-menu) > .menu-vertical.menu-collapsed:not(.layout-menu):not(:hover) .app-brand .layout-menu-toggle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
}
:not(.layout-menu) > .menu-vertical.menu-collapsed:not(.layout-menu):not(:hover) .app-brand-img {
  display: none;
}
:not(.layout-menu) > .menu-vertical.menu-collapsed:not(.layout-menu):not(:hover) .app-brand-img-collapsed {
  display: block;
}

@media (min-width: 1200px) {
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .app-brand {
    width: 5rem;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .app-brand-logo,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .app-brand-link,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .app-brand-text {
    margin-right: auto;
    margin-left: auto;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .app-brand-logo ~ .app-brand-text,
.layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .app-brand .layout-menu-toggle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .app-brand-img {
    display: none;
  }
  .layout-menu-collapsed:not(.layout-menu-hover):not(.layout-menu-offcanvas):not(.layout-menu-fixed-offcanvas) .layout-menu .app-brand-img-collapsed {
    display: block;
  }
}
.custom-option {
  padding-left: 0;
  border: 1px solid #36445d;
  border-radius: 0.25rem;
}
.custom-option:hover {
  border: 1px solid #546990;
}
.custom-option.custom-option-image {
  border-width: 2px !important;
}
.custom-option.custom-option-image:hover {
  border-width: 2px !important;
}
.custom-option.custom-option-image .custom-option-body img {
  border-radius: 0.25rem;
}
.custom-option .custom-option-content {
  cursor: pointer;
  width: 100%;
}

.custom-option-basic .custom-option-content {
  padding: 1em;
  padding-left: 2.7em;
}
.custom-option-basic .custom-option-content .form-check-input {
  margin-top: 0;
}
.custom-option-basic .custom-option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.25rem;
}

.custom-option-icon .custom-option-content {
  text-align: center;
  padding: 1em;
}
.custom-option-icon .custom-option-body {
  display: block;
  margin-bottom: 0.5rem;
}
.custom-option-icon .custom-option-body i {
  font-size: 2rem;
  margin-bottom: 0.25rem;
  display: block;
}
.custom-option-icon .custom-option-body svg {
  height: 38px;
  width: 38px;
  margin-bottom: 0.25rem;
}
.custom-option-icon .custom-option-body .custom-option-title {
  display: block;
  font-family: "Rubik", "Times New Roman", serif;
  font-size: 0.9375rem;
  font-weight: 500;
  color: #d8deea;
}
.custom-option-icon .form-check-input {
  float: none !important;
  margin: 0 !important;
}

.custom-option-image {
  border-width: 2px;
}
.custom-option-image .custom-option-content {
  padding: 0;
}
.custom-option-image .custom-option-body img {
  height: 100%;
  width: 100%;
}
.custom-option-image.custom-option-image-radio .form-check-input {
  display: none;
}
.custom-option-image.custom-option-image-check {
  position: relative;
}
.custom-option-image.custom-option-image-check .form-check-input {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
  border: 0;
  opacity: 0;
}
.custom-option-image.custom-option-image-check .form-check-input:checked {
  opacity: 1;
}
.custom-option-image.custom-option-image-check:hover .form-check-input {
  border: inherit;
  border-width: 1px;
  opacity: 1;
}

[dir=rtl] .custom-option {
  padding-right: 0;
}
[dir=rtl] .custom-option-basic .custom-option-content {
  padding-right: 2.7em;
  padding-left: 1em;
}
[dir=rtl] .custom-option-image.custom-option-image-check .form-check-input {
  right: auto;
  left: 10px;
}

.switch {
  margin-right: 0.75rem;
  position: relative;
  vertical-align: middle;
  margin-bottom: 0;
  display: inline-block;
  border-radius: 30rem;
  min-height: 1.35rem;
  font-size: 0.9375rem;
  line-height: 1.4;
}
.switch .switch-label:first-child {
  padding-right: 0.5rem;
}
.switch .switch-input ~ .switch-label {
  padding-left: 3rem;
}
.switch .switch-toggle-slider {
  width: 2.5rem;
  height: 1.35rem;
  font-size: 0.625rem;
  line-height: 1.35rem;
  border: 1px solid transparent;
  top: 0;
}
.switch .switch-toggle-slider i {
  position: relative;
  font-size: 0.9375rem;
  top: -1.35px;
}
.switch .switch-label {
  top: 0.01875rem;
}
.switch .switch-input:checked ~ .switch-toggle-slider::after {
  left: 1.15rem;
}
.switch .switch-toggle-slider::after {
  top: -1px;
  margin: 0.25rem 0 0 0.25rem;
  width: 13px;
  height: 13px;
}
.switch .switch-on {
  padding-left: 0.25rem;
  padding-right: 1.1rem;
}
.switch .switch-off {
  padding-left: 1.1rem;
  padding-right: 0.25rem;
}
[dir=rtl] .switch .switch-label {
  padding-right: 3rem;
  padding-left: 0;
}
[dir=rtl] .switch .switch-input:checked ~ .switch-toggle-slider::after {
  left: auto;
  right: 1.15rem;
}
[dir=rtl] .switch .switch-toggle-slider::after {
  margin-left: 0;
  margin-right: 0.25rem;
}
[dir=rtl] .switch .switch-on {
  padding-left: 1.1rem;
  padding-right: 0.25rem;
}
[dir=rtl] .switch .switch-off {
  padding-left: 0.25rem;
  padding-right: 1.1rem;
}
[dir=rtl] .switch {
  margin-left: 0.75rem;
  margin-right: 0;
}

.switch-input {
  opacity: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  z-index: -1;
}

.switch-toggle-slider {
  position: absolute;
  overflow: hidden;
  border-radius: 30rem;
  background: #36445d;
  color: #fff;
  transition-duration: 0.2s;
  transition-property: left, right, background, box-shadow;
  cursor: pointer;
  user-select: none;
}

.switch-label {
  display: inline-block;
  font-weight: 400;
  color: #a1b0cb;
  position: relative;
  cursor: default;
}

.switch-off,
.switch-on {
  height: 100%;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  transition-duration: 0.2s;
  transition-property: left, right;
}

.switch-on {
  left: -100%;
}
[dir=rtl] .switch-on {
  left: auto;
  right: -100%;
}
.switch-input:not(:checked) ~ .switch-toggle-slider .switch-on {
  color: transparent;
}

.switch-off {
  left: 0;
}
[dir=rtl] .switch-off {
  right: 0;
  left: auto;
}

.switch-input:checked ~ .switch-toggle-slider .switch-on {
  left: 0;
}
[dir=rtl] .switch-input:checked ~ .switch-toggle-slider .switch-on {
  right: 0;
  left: auto;
}
.switch-input:checked ~ .switch-toggle-slider .switch-off {
  left: 100%;
  color: transparent;
}
[dir=rtl] .switch-input:checked ~ .switch-toggle-slider .switch-off {
  right: 100%;
  left: auto;
}

.switch-toggle-slider::after {
  content: "";
  position: absolute;
  left: 0;
  display: block;
  border-radius: 999px;
  background: #fff;
  box-shadow: -1px 2px 3px 0 rgba(0, 0, 0, 0.2);
  transition-duration: 0.2s;
  transition-property: left, right, background;
}
[dir=rtl] .switch-toggle-slider::after {
  right: 0;
  left: auto;
}

.switches-stacked::after {
  display: block;
  clear: both;
  content: "";
}
.switches-stacked .switch {
  display: block;
}
html:not([dir=rtl]) .switches-stacked .switch {
  margin-right: 0;
}
[dir=rtl] .switches-stacked .switch {
  margin-left: 0;
}
.switches-stacked .switch:not(:last-child) {
  margin-bottom: 0.75rem;
}

.switch-square,
.switch-square .switch-toggle-slider {
  border-radius: 0.25rem;
}

.switch-square .switch-toggle-slider::after {
  border-radius: calc(0.25rem - 2px);
}

.switch-input:disabled ~ .switch-toggle-slider {
  opacity: 0.65;
}
.switch-input:disabled ~ .switch-toggle-slider::after {
  box-shadow: none;
}
.switch-input:disabled ~ .switch-label {
  color: #8295ba;
}

.switch-sm {
  min-height: 1.125rem;
  font-size: 0.813rem;
  line-height: 1.48;
}
.switch-sm .switch-label:first-child {
  padding-right: 0.5rem;
}
.switch-sm .switch-input ~ .switch-label {
  padding-left: 2.5rem;
}
.switch-sm .switch-toggle-slider {
  width: 2rem;
  height: 1.125rem;
  font-size: 0.625rem;
  line-height: 1.125rem;
  border: 1px solid transparent;
  top: 0.03912rem;
}
.switch-sm .switch-toggle-slider i {
  position: relative;
  font-size: 0.813rem;
  top: -2px;
}
.switch-sm .switch-label {
  top: 0;
}
.switch-sm .switch-input:checked ~ .switch-toggle-slider::after {
  left: 0.875rem;
}
.switch-sm .switch-toggle-slider::after {
  top: -1px;
  margin: 0.25rem 0 0 0.25rem;
  width: 10px;
  height: 10px;
}
.switch-sm .switch-on {
  padding-left: 0.25rem;
  padding-right: 0.875rem;
}
.switch-sm .switch-off {
  padding-left: 0.875rem;
  padding-right: 0.25rem;
}
[dir=rtl] .switch-sm .switch-label {
  padding-right: 2.5rem;
  padding-left: 0;
}
[dir=rtl] .switch-sm .switch-input:checked ~ .switch-toggle-slider::after {
  left: auto;
  right: 0.875rem;
}
[dir=rtl] .switch-sm .switch-toggle-slider::after {
  margin-left: 0;
  margin-right: 0.25rem;
}
[dir=rtl] .switch-sm .switch-on {
  padding-left: 0.875rem;
  padding-right: 0.25rem;
}
[dir=rtl] .switch-sm .switch-off {
  padding-left: 0.25rem;
  padding-right: 0.875rem;
}

.switch-lg {
  min-height: 1.75rem;
  font-size: 1.063rem;
  line-height: 1.47;
}
.switch-lg .switch-label:first-child {
  padding-right: 0.5rem;
}
.switch-lg .switch-input ~ .switch-label {
  padding-left: 3.75rem;
}
.switch-lg .switch-toggle-slider {
  width: 3.25rem;
  height: 1.75rem;
  font-size: 0.625rem;
  line-height: 1.75rem;
  border: 1px solid transparent;
  top: 0;
}
.switch-lg .switch-toggle-slider i {
  position: relative;
  font-size: 1.063rem;
  top: -2px;
}
.switch-lg .switch-label {
  top: 0.093695rem;
}
.switch-lg .switch-input:checked ~ .switch-toggle-slider::after {
  left: 1.5rem;
}
.switch-lg .switch-toggle-slider::after {
  top: -1px;
  margin: 0.25rem 0 0 0.25rem;
  width: 20px;
  height: 20px;
}
.switch-lg .switch-on {
  padding-left: 0.25rem;
  padding-right: 1.5rem;
}
.switch-lg .switch-off {
  padding-left: 1.5rem;
  padding-right: 0.25rem;
}
[dir=rtl] .switch-lg .switch-label {
  padding-right: 3.75rem;
  padding-left: 0;
}
[dir=rtl] .switch-lg .switch-input:checked ~ .switch-toggle-slider::after {
  left: auto;
  right: 1.5rem;
}
[dir=rtl] .switch-lg .switch-toggle-slider::after {
  margin-left: 0;
  margin-right: 0.25rem;
}
[dir=rtl] .switch-lg .switch-on {
  padding-left: 1.5rem;
  padding-right: 0.25rem;
}
[dir=rtl] .switch-lg .switch-off {
  padding-left: 0.25rem;
  padding-right: 1.5rem;
}

.switch-secondary.switch .switch-input:checked ~ .switch-toggle-slider {
  background: #69809a;
  color: #fff;
}

.switch-secondary.switch .switch-input:active ~ .switch-toggle-slider {
  box-shadow: none;
}

.switch-secondary.switch .switch-input:focus ~ .switch-toggle-slider {
  box-shadow: 0 0 0 0.12rem rgba(99, 123, 169, 0.3);
}

.switch-success.switch .switch-input:checked ~ .switch-toggle-slider {
  background: #39da8a;
  color: #fff;
}

.switch-success.switch .switch-input:active ~ .switch-toggle-slider {
  box-shadow: none;
}

.switch-success.switch .switch-input:focus ~ .switch-toggle-slider {
  box-shadow: 0 0 0 0.12rem rgba(99, 123, 169, 0.3);
}

.switch-info.switch .switch-input:checked ~ .switch-toggle-slider {
  background: #00cfdd;
  color: #fff;
}

.switch-info.switch .switch-input:active ~ .switch-toggle-slider {
  box-shadow: none;
}

.switch-info.switch .switch-input:focus ~ .switch-toggle-slider {
  box-shadow: 0 0 0 0.12rem rgba(99, 123, 169, 0.3);
}

.switch-warning.switch .switch-input:checked ~ .switch-toggle-slider {
  background: #fdac41;
  color: #fff;
}

.switch-warning.switch .switch-input:active ~ .switch-toggle-slider {
  box-shadow: none;
}

.switch-warning.switch .switch-input:focus ~ .switch-toggle-slider {
  box-shadow: 0 0 0 0.12rem rgba(99, 123, 169, 0.3);
}

.switch-danger.switch .switch-input:checked ~ .switch-toggle-slider {
  background: #ff5b5c;
  color: #fff;
}

.switch-danger.switch .switch-input:active ~ .switch-toggle-slider {
  box-shadow: none;
}

.switch-danger.switch .switch-input:focus ~ .switch-toggle-slider {
  box-shadow: 0 0 0 0.12rem rgba(99, 123, 169, 0.3);
}

.switch-dark.switch .switch-input:checked ~ .switch-toggle-slider {
  background: #717784;
  color: #fff;
}

.switch-dark.switch .switch-input:active ~ .switch-toggle-slider {
  box-shadow: none;
}

.switch-dark.switch .switch-input:focus ~ .switch-toggle-slider {
  box-shadow: 0 0 0 0.12rem rgba(99, 123, 169, 0.3);
}

.switch-gray.switch .switch-input:checked ~ .switch-toggle-slider {
  background: rgba(255, 255, 255, 0.75);
  color: #000;
}

.switch-gray.switch .switch-input:active ~ .switch-toggle-slider {
  box-shadow: none;
}

.switch-gray.switch .switch-input:focus ~ .switch-toggle-slider {
  box-shadow: 0 0 0 0.12rem rgba(99, 123, 169, 0.3);
}

.switch .valid-feedback,
.switch .invalid-feedback {
  padding-left: 0.5rem;
}

.was-validated .switch-input:valid ~ .switch-label, .switch-input.invalid ~ .switch-label, .switch-input.is-valid ~ .switch-label {
  color: #39da8a;
}
.was-validated .switch-input:valid ~ .valid-feedback,
.was-validated .switch-input:valid ~ .valid-tooltip, .switch-input.invalid ~ .valid-feedback,
.switch-input.invalid ~ .valid-tooltip, .switch-input.is-valid ~ .valid-feedback,
.switch-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .switch-input:valid ~ .switch-toggle-slider, .switch-input.invalid ~ .switch-toggle-slider, .switch-input.is-valid ~ .switch-toggle-slider {
  border: 1px solid #39da8a !important;
}
.was-validated .switch-input:valid:checked ~ .switch-toggle-slider, .switch-input.invalid:checked ~ .switch-toggle-slider, .switch-input.is-valid:checked ~ .switch-toggle-slider {
  background: #39da8a;
  color: #fff;
}
.was-validated .switch-input:valid:focus ~ .switch-toggle-slider, .switch-input.invalid:focus ~ .switch-toggle-slider, .switch-input.is-valid:focus ~ .switch-toggle-slider {
  box-shadow: none;
}
.was-validated .switch-input:valid:active ~ .switch-toggle-slider, .switch-input.invalid:active ~ .switch-toggle-slider, .switch-input.is-valid:active ~ .switch-toggle-slider {
  box-shadow: none;
}

.was-validated .switch-input:invalid ~ .switch-label, .switch-input.invalid ~ .switch-label, .switch-input.is-invalid ~ .switch-label {
  color: #ff5b5c;
}
.was-validated .switch-input:invalid ~ .invalid-feedback,
.was-validated .switch-input:invalid ~ .invalid-tooltip, .switch-input.invalid ~ .invalid-feedback,
.switch-input.invalid ~ .invalid-tooltip, .switch-input.is-invalid ~ .invalid-feedback,
.switch-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .switch-input:invalid ~ .switch-toggle-slider, .switch-input.invalid ~ .switch-toggle-slider, .switch-input.is-invalid ~ .switch-toggle-slider {
  border: 1px solid #ff5b5c !important;
}
.was-validated .switch-input:invalid:checked ~ .switch-toggle-slider, .switch-input.invalid:checked ~ .switch-toggle-slider, .switch-input.is-invalid:checked ~ .switch-toggle-slider {
  background: #ff5b5c;
  color: #fff;
}
.was-validated .switch-input:invalid:focus ~ .switch-toggle-slider, .switch-input.invalid:focus ~ .switch-toggle-slider, .switch-input.is-invalid:focus ~ .switch-toggle-slider {
  box-shadow: none;
}
.was-validated .switch-input:invalid:active ~ .switch-toggle-slider, .switch-input.invalid:active ~ .switch-toggle-slider, .switch-input.is-invalid:active ~ .switch-toggle-slider {
  box-shadow: none;
}

.avatar {
  position: relative;
  width: 2.375rem;
  height: 2.375rem;
  cursor: pointer;
}
.avatar img {
  width: 100%;
  height: 100%;
}
.avatar .avatar-initial {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #69809a;
  font-weight: 600;
}
.avatar.avatar-online:after, .avatar.avatar-offline:after, .avatar.avatar-away:after, .avatar.avatar-busy:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 3px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  box-shadow: 0 0 0 2px #fff;
}
.avatar.avatar-online:after {
  background-color: #39da8a;
}
.avatar.avatar-offline:after {
  background-color: #69809a;
}
.avatar.avatar-away:after {
  background-color: #fdac41;
}
.avatar.avatar-busy:after {
  background-color: #ff5b5c;
}

.pull-up {
  transition: all 0.25s ease;
}
.pull-up:hover {
  transform: translateY(-4px) scale(1.02);
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
  z-index: 30;
  border-radius: 50%;
}

.avatar-xs {
  width: 1.625rem;
  height: 1.625rem;
}
.avatar-xs .avatar-initial {
  font-size: 0.625rem;
}
.avatar-xs.avatar-online:after, .avatar-xs.avatar-offline:after, .avatar-xs.avatar-away:after, .avatar-xs.avatar-busy:after {
  width: 0.325rem;
  height: 0.325rem;
  right: 1px;
}

.avatar-sm {
  width: 2rem;
  height: 2rem;
}
.avatar-sm .avatar-initial {
  font-size: 0.75rem;
}
.avatar-sm.avatar-online:after, .avatar-sm.avatar-offline:after, .avatar-sm.avatar-away:after, .avatar-sm.avatar-busy:after {
  width: 0.4rem;
  height: 0.4rem;
  right: 2px;
}

.avatar-md {
  width: 3rem;
  height: 3rem;
}
.avatar-md .avatar-initial {
  font-size: 1.125rem;
}
.avatar-md.avatar-online:after, .avatar-md.avatar-offline:after, .avatar-md.avatar-away:after, .avatar-md.avatar-busy:after {
  width: 0.6rem;
  height: 0.6rem;
  right: 4px;
}

.avatar-lg {
  width: 4rem;
  height: 4rem;
}
.avatar-lg .avatar-initial {
  font-size: 1.5rem;
}
.avatar-lg.avatar-online:after, .avatar-lg.avatar-offline:after, .avatar-lg.avatar-away:after, .avatar-lg.avatar-busy:after {
  width: 0.8rem;
  height: 0.8rem;
  right: 5px;
}

.avatar-xl {
  width: 4.5rem;
  height: 4.5rem;
}
.avatar-xl .avatar-initial {
  font-size: 1.875rem;
}
.avatar-xl.avatar-online:after, .avatar-xl.avatar-offline:after, .avatar-xl.avatar-away:after, .avatar-xl.avatar-busy:after {
  width: 0.9rem;
  height: 0.9rem;
  right: 6px;
}

.avatar-group .avatar {
  transition: all 0.25s ease;
}
.avatar-group .avatar img,
.avatar-group .avatar .avatar-initial {
  border: 2px solid #283144;
}
.avatar-group .avatar .avatar-initial {
  background-color: #8799ae;
}
.avatar-group .avatar:hover {
  z-index: 30;
  transition: all 0.25s ease;
}
.avatar-group .avatar {
  margin-left: -0.8rem;
}
.avatar-group .avatar:first-child {
  margin-left: 0;
}
.avatar-group .avatar-xs {
  margin-left: -0.65rem;
}
.avatar-group .avatar-sm {
  margin-left: -0.75rem;
}
.avatar-group .avatar-md {
  margin-left: -0.9rem;
}
.avatar-group .avatar-lg {
  margin-left: -1.5rem;
}
.avatar-group .avatar-xl {
  margin-left: -1.75rem;
}

[dir=rtl] .avatar-group .avatar {
  margin-right: -0.8rem;
  margin-left: 0;
}
[dir=rtl] .avatar-group .avatar-xs {
  margin-right: -0.65rem;
}
[dir=rtl] .avatar-group .avatar-sm {
  margin-right: -0.75rem;
}
[dir=rtl] .avatar-group .avatar-md {
  margin-right: -0.9rem;
}
[dir=rtl] .avatar-group .avatar-lg {
  margin-right: -1.5rem;
}
[dir=rtl] .avatar-group .avatar-xl {
  margin-right: -1.75rem;
}

.avatar-group .avatar .avatar-initial {
  background-color: #3e4657;
}

.avatar.avatar-online:after, .avatar.avatar-offline:after, .avatar.avatar-away:after, .avatar.avatar-busy:after {
  box-shadow: 0 0 0 2px #1c222f;
}

.timeline {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  list-style: none;
}
.timeline::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 1px;
  border: 0;
  border-left: 1px solid #36445d;
  content: "";
}
.timeline .timeline-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.timeline .timeline-header > *:first-child {
  margin-right: 0.5rem;
}
.timeline .timeline-end-indicator {
  position: absolute;
  bottom: -1.35rem;
  left: -0.65rem;
}
.timeline .timeline-end-indicator i {
  font-size: 1.5rem;
  color: #36445d;
}
.timeline .timeline-label {
  position: relative;
  z-index: 2;
  display: block;
  text-align: left;
  margin-left: -1.5rem;
  margin-bottom: 1rem;
}
.timeline.timeline-solid:before {
  border-style: solid;
}
.timeline.timeline-dotted:before {
  border-style: dotted;
}
.timeline.timeline-dashed:before {
  border-style: dashed;
}
.timeline.timeline-hidden:before {
  border-style: none;
}
.timeline .timeline-item {
  position: relative;
  padding-left: 3rem;
}
.timeline .timeline-item .timeline-event {
  position: relative;
  top: -1rem;
  width: 100%;
  min-height: 4rem;
  background-color: #283144;
  border-radius: 0.25rem;
  padding: 1.25rem 1.5rem;
}
.timeline .timeline-item .timeline-event:before {
  position: absolute;
  top: 0.75rem;
  left: 32px;
  right: 100%;
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-right: 1rem solid;
  border-left: 0 solid;
  border-bottom: 1rem solid transparent;
  border-left-color: #283144;
  border-right-color: #283144;
  margin-left: -3rem;
  content: "";
}
.timeline .timeline-item .timeline-event:after {
  position: absolute;
  top: 0.75rem;
  left: -17px;
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-right: 1rem solid;
  border-left: 0 solid;
  border-bottom: 1rem solid transparent;
  border-left-color: #283144;
  border-right-color: #36445d;
  z-index: -1;
}
.timeline .timeline-item .timeline-event.timeline-event-shadow {
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.075) !important;
}
.timeline .timeline-item .timeline-event .timeline-event-time {
  position: absolute;
  top: 1.2rem;
  font-size: 0.85rem;
  color: #8295ba;
}
.timeline .timeline-item .timeline-indicator {
  position: absolute;
  left: -0.6875rem;
  top: 0;
  z-index: 2;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #5a8dee;
  background-color: #1c222f !important;
}
.timeline .timeline-item .timeline-indicator i {
  color: #5a8dee;
  font-size: 0.85rem;
  vertical-align: baseline;
}
.timeline .timeline-item .timeline-point {
  position: absolute;
  left: -0.312rem;
  top: 0;
  z-index: 2;
  display: block;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  background-color: #5a8dee;
}
.timeline .timeline-item.timeline-item-transparent .timeline-event {
  top: -1.4rem;
  background-color: transparent;
}
html:not([dir=rtl]) .timeline .timeline-item.timeline-item-transparent .timeline-event {
  padding-left: 0;
}
.timeline .timeline-item.timeline-item-transparent .timeline-event.timeline-event-shadow {
  padding-left: 2rem;
}
.timeline .timeline-item.timeline-item-transparent .timeline-event:before {
  display: none;
}
.timeline.timeline-center:before {
  left: 50%;
}
.timeline.timeline-center:after {
  left: 50%;
  margin-left: -0.55rem;
}
.timeline.timeline-center .timeline-end-indicator {
  bottom: -1.4rem;
  left: 50%;
  margin-left: 0.55rem;
}
.timeline.timeline-center .timeline-label {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: auto;
  clear: left;
}
.timeline.timeline-center .timeline-label + .timeline-item {
  margin-top: 2rem;
}
.timeline.timeline-center .timeline-item {
  width: 50%;
  clear: both;
}
.timeline.timeline-center .timeline-item.timeline-item-left, .timeline.timeline-center .timeline-item:nth-of-type(odd):not(.timeline-item-left):not(.timeline-item-right) {
  float: left;
  padding-left: 0;
  padding-right: 3rem;
}
.timeline.timeline-center .timeline-item.timeline-item-left .timeline-event .timeline-event-time, .timeline.timeline-center .timeline-item:nth-of-type(odd):not(.timeline-item-left):not(.timeline-item-right) .timeline-event .timeline-event-time {
  right: -9.5rem;
}
.timeline.timeline-center .timeline-item.timeline-item-left .timeline-event:before, .timeline.timeline-center .timeline-item:nth-of-type(odd):not(.timeline-item-left):not(.timeline-item-right) .timeline-event:before {
  right: -15px;
  left: auto;
  border-left-width: 16px;
  border-right-width: 0;
}
.timeline.timeline-center .timeline-item.timeline-item-left .timeline-point, .timeline.timeline-center .timeline-item:nth-of-type(odd):not(.timeline-item-left):not(.timeline-item-right) .timeline-point {
  left: 100%;
}
.timeline.timeline-center .timeline-item.timeline-item-right, .timeline.timeline-center .timeline-item:nth-of-type(even):not(.timeline-item-left):not(.timeline-item-right) {
  float: right;
}
.timeline.timeline-center .timeline-item.timeline-item-right .timeline-event-time, .timeline.timeline-center .timeline-item:nth-of-type(even):not(.timeline-item-left):not(.timeline-item-right) .timeline-event-time {
  left: -9.5rem;
}
.timeline.timeline-center .timeline-item.timeline-item-right .timeline-point, .timeline.timeline-center .timeline-item:nth-of-type(even):not(.timeline-item-left):not(.timeline-item-right) .timeline-point {
  left: 0;
}
.timeline.timeline-center .timeline-item .timeline-point {
  left: 50%;
  margin-left: -0.6875rem;
}
.timeline.timeline-center .timeline-item .timeline-point-indicator {
  left: 50%;
  margin-left: -0.3125rem;
}

[dir=rtl] .timeline:not(.timeline-center):before {
  right: -1px;
  left: auto;
}
[dir=rtl] .timeline:not(.timeline-center):after {
  left: auto;
  margin-right: -0.65rem;
}
[dir=rtl] .timeline:not(.timeline-center) .timeline-end-indicator {
  left: auto;
  right: -0.75rem;
}
[dir=rtl] .timeline:not(.timeline-center) .timeline-label {
  text-align: right;
  margin-right: -1.5rem;
}
[dir=rtl] .timeline:not(.timeline-center) .timeline-item {
  padding-left: 0;
  padding-right: 3rem;
}
[dir=rtl] .timeline:not(.timeline-center) .timeline-item .timeline-event:before {
  right: -1rem;
  left: auto;
  border-left-width: 1rem;
  border-right-width: 0;
}
[dir=rtl] .timeline:not(.timeline-center) .timeline-item.timeline-item-transparent .timeline-event {
  padding-right: 0;
}
[dir=rtl] .timeline:not(.timeline-center) .timeline-item .timeline-point {
  right: -0.375rem;
  left: auto;
}
[dir=rtl] .timeline:not(.timeline-center) .timeline-item .timeline-indicator {
  right: -0.75rem;
  left: auto;
}

@media (min-width: 768px) {
  .timeline.timeline-center .timeline-item.timeline-item-left .timeline-indicator, .timeline.timeline-center .timeline-item:nth-of-type(odd):not(.timeline-item-left):not(.timeline-item-right) .timeline-indicator {
    left: calc(100% - (1.5rem/ 2));
  }
  .timeline.timeline-center .timeline-item.timeline-item-left .timeline-event:after, .timeline.timeline-center .timeline-item:nth-of-type(odd):not(.timeline-item-left):not(.timeline-item-right) .timeline-event:after {
    transform: rotate(180deg);
    right: -16px;
    left: auto;
  }
}
@media (max-width: 767.98px) {
  .timeline.timeline-center:before {
    left: 0;
  }
  .timeline.timeline-center:after {
    left: 0;
  }
  .timeline.timeline-center .timeline-end-indicator {
    left: -2px;
  }
  .timeline.timeline-center .timeline-label {
    text-align: left;
  }
  .timeline.timeline-center .timeline-item {
    float: left !important;
    width: 100%;
    padding-left: 3rem !important;
    padding-right: 0 !important;
  }
  .timeline.timeline-center .timeline-item .timeline-event:before {
    right: 100% !important;
    border-right-width: 1rem !important;
    border-left-width: 0 !important;
  }
  .timeline.timeline-center .timeline-item .timeline-event .timeline-event-time {
    top: -1.7rem;
    left: 0 !important;
    right: auto !important;
  }
  .timeline.timeline-center .timeline-item .timeline-point {
    left: -0.7rem !important;
    margin-left: 0 !important;
  }
  .timeline.timeline-center .timeline-item .timeline-point-indicator {
    left: 0 !important;
    margin-left: -0.3125rem !important;
  }

  [dir=rtl] .timeline.timeline-center:before {
    left: auto;
    right: 0;
  }
  [dir=rtl] .timeline.timeline-center:after {
    right: -0.55rem;
    left: auto;
  }
  [dir=rtl] .timeline.timeline-center .timeline-end-indicator {
    left: auto;
    right: -12px;
  }
  [dir=rtl] .timeline.timeline-center .timeline-label {
    text-align: right;
  }
  [dir=rtl] .timeline.timeline-center .timeline-item {
    float: right !important;
    width: 100%;
    padding-right: 3rem !important;
    padding-left: 0 !important;
  }
  [dir=rtl] .timeline.timeline-center .timeline-item .timeline-event:before {
    left: 100% !important;
    right: -1rem !important;
    border-left-width: 1rem !important;
    border-right-width: 0 !important;
  }
  [dir=rtl] .timeline.timeline-center .timeline-item .timeline-event:after {
    transform: rotate(180deg);
    right: -17px;
    left: auto;
  }
  [dir=rtl] .timeline.timeline-center .timeline-item .timeline-event .timeline-event-time {
    top: -1.2rem;
    right: 0 !important;
    left: auto !important;
  }
  [dir=rtl] .timeline.timeline-center .timeline-item .timeline-point {
    right: -0.7rem !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .timeline.timeline-center .timeline-item .timeline-point-indicator {
    right: 0 !important;
    margin-right: -0.3125rem !important;
  }
}
@media (max-width: 767.98px) {
  [dir=rtl] .timeline .timeline-item .timeline-indicator {
    left: auto;
    right: -0.6875rem;
  }
}
@media (max-width: 575.98px) {
  .timeline .timeline-header {
    flex-direction: column;
    align-items: flex-start;
  }
}
.timeline .timeline-item-secondary .timeline-event {
  background-color: rgba(105, 128, 154, 0.1);
}
.timeline .timeline-item-secondary .timeline-event:before {
  border-left-color: rgba(105, 128, 154, 0.1) !important;
  border-right-color: rgba(105, 128, 154, 0.1) !important;
}

.timeline .timeline-point-secondary {
  background-color: #69809a !important;
  box-shadow: 0 0 0 0.1875rem rgba(105, 128, 154, 0.16);
}

.timeline .timeline-indicator-secondary {
  border-color: #69809a !important;
}
.timeline .timeline-indicator-secondary i {
  color: #69809a !important;
}

.timeline .timeline-item-success .timeline-event {
  background-color: rgba(57, 218, 138, 0.1);
}
.timeline .timeline-item-success .timeline-event:before {
  border-left-color: rgba(57, 218, 138, 0.1) !important;
  border-right-color: rgba(57, 218, 138, 0.1) !important;
}

.timeline .timeline-point-success {
  background-color: #39da8a !important;
  box-shadow: 0 0 0 0.1875rem rgba(57, 218, 138, 0.16);
}

.timeline .timeline-indicator-success {
  border-color: #39da8a !important;
}
.timeline .timeline-indicator-success i {
  color: #39da8a !important;
}

.timeline .timeline-item-info .timeline-event {
  background-color: rgba(0, 207, 221, 0.1);
}
.timeline .timeline-item-info .timeline-event:before {
  border-left-color: rgba(0, 207, 221, 0.1) !important;
  border-right-color: rgba(0, 207, 221, 0.1) !important;
}

.timeline .timeline-point-info {
  background-color: #00cfdd !important;
  box-shadow: 0 0 0 0.1875rem rgba(0, 207, 221, 0.16);
}

.timeline .timeline-indicator-info {
  border-color: #00cfdd !important;
}
.timeline .timeline-indicator-info i {
  color: #00cfdd !important;
}

.timeline .timeline-item-warning .timeline-event {
  background-color: rgba(253, 172, 65, 0.1);
}
.timeline .timeline-item-warning .timeline-event:before {
  border-left-color: rgba(253, 172, 65, 0.1) !important;
  border-right-color: rgba(253, 172, 65, 0.1) !important;
}

.timeline .timeline-point-warning {
  background-color: #fdac41 !important;
  box-shadow: 0 0 0 0.1875rem rgba(253, 172, 65, 0.16);
}

.timeline .timeline-indicator-warning {
  border-color: #fdac41 !important;
}
.timeline .timeline-indicator-warning i {
  color: #fdac41 !important;
}

.timeline .timeline-item-danger .timeline-event {
  background-color: rgba(255, 91, 92, 0.1);
}
.timeline .timeline-item-danger .timeline-event:before {
  border-left-color: rgba(255, 91, 92, 0.1) !important;
  border-right-color: rgba(255, 91, 92, 0.1) !important;
}

.timeline .timeline-point-danger {
  background-color: #ff5b5c !important;
  box-shadow: 0 0 0 0.1875rem rgba(255, 91, 92, 0.16);
}

.timeline .timeline-indicator-danger {
  border-color: #ff5b5c !important;
}
.timeline .timeline-indicator-danger i {
  color: #ff5b5c !important;
}

.timeline .timeline-item-dark .timeline-event {
  background-color: rgba(67, 76, 95, 0.1);
}
.timeline .timeline-item-dark .timeline-event:before {
  border-left-color: rgba(67, 76, 95, 0.1) !important;
  border-right-color: rgba(67, 76, 95, 0.1) !important;
}

.timeline .timeline-point-dark {
  background-color: #434c5f !important;
  box-shadow: 0 0 0 0.1875rem rgba(67, 76, 95, 0.16);
}

.timeline .timeline-indicator-dark {
  border-color: #434c5f !important;
}
.timeline .timeline-indicator-dark i {
  color: #434c5f !important;
}

.timeline .timeline-item-gray .timeline-event {
  background-color: rgba(255, 255, 255, 0.1);
}
.timeline .timeline-item-gray .timeline-event:before {
  border-left-color: rgba(255, 255, 255, 0.1) !important;
  border-right-color: rgba(255, 255, 255, 0.1) !important;
}

.timeline .timeline-point-gray {
  background-color: rgba(255, 255, 255, 0.75) !important;
  box-shadow: 0 0 0 0.1875rem rgba(255, 255, 255, 0.16);
}

.timeline .timeline-indicator-gray {
  border-color: rgba(255, 255, 255, 0.75) !important;
}
.timeline .timeline-indicator-gray i {
  color: rgba(255, 255, 255, 0.75) !important;
}

.blockUI.blockOverlay, .blockUI.blockMsg {
  z-index: 1091 !important;
  color: #fff !important;
}

[dir=rtl] #sortable-cards {
  flex-direction: row-reverse;
}
[dir=rtl] #image-list-1,
[dir=rtl] #image-list-2 {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.divider {
  display: block;
  text-align: center;
  margin: 1rem 0;
  overflow: hidden;
  white-space: nowrap;
}
.divider .divider-text {
  position: relative;
  display: inline-block;
  font-size: 0.8rem;
  padding: 0rem 1rem;
}
.divider .divider-text i {
  font-size: 1rem;
}
.divider .divider-text:before, .divider .divider-text:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 100vw;
  border-top: 1px solid #36445d;
}
.divider .divider-text:before {
  right: 100%;
}
.divider .divider-text:after {
  left: 100%;
}
.divider.text-start .divider-text {
  padding-left: 0;
}
.divider.text-end .divider-text {
  padding-right: 0;
}
.divider.text-start-center .divider-text {
  left: -25%;
}
.divider.text-end-center .divider-text {
  right: -25%;
}
.divider.divider-dotted .divider-text:before, .divider.divider-dotted .divider-text:after {
  border-style: dotted;
  border-width: 0 1px 1px;
  border-color: #36445d;
}
.divider.divider-dashed .divider-text:before, .divider.divider-dashed .divider-text:after {
  border-style: dashed;
  border-width: 0 1px 1px;
  border-color: #36445d;
}

[dir=rtl] .divider.text-start-center .divider-text {
  right: -25%;
  left: auto;
}
[dir=rtl] .divider.text-end-center .divider-text {
  left: -25%;
  right: auto;
}
[dir=rtl] .divider.text-start .divider-text {
  padding-right: 0;
  padding-left: 1rem;
}
[dir=rtl] .divider.text-end .divider-text {
  padding-left: 0;
  padding-right: 1rem;
}

.divider.divider.divider-secondary .divider-text:before, .divider.divider.divider-secondary .divider-text:after {
  border-color: #69809a;
}

.divider.divider.divider-success .divider-text:before, .divider.divider.divider-success .divider-text:after {
  border-color: #39da8a;
}

.divider.divider.divider-info .divider-text:before, .divider.divider.divider-info .divider-text:after {
  border-color: #00cfdd;
}

.divider.divider.divider-warning .divider-text:before, .divider.divider.divider-warning .divider-text:after {
  border-color: #fdac41;
}

.divider.divider.divider-danger .divider-text:before, .divider.divider.divider-danger .divider-text:after {
  border-color: #ff5b5c;
}

.divider.divider.divider-dark .divider-text:before, .divider.divider.divider-dark .divider-text:after {
  border-color: #717784;
}

.divider.divider.divider-gray .divider-text:before, .divider.divider.divider-gray .divider-text:after {
  border-color: rgba(255, 255, 255, 0.75);
}

.footer-link {
  display: inline-block;
}

.footer-light {
  color: #637ba9;
}
.footer-light .footer-text {
  color: #4e5155;
}
.footer-light .footer-link {
  color: #637ba9;
}
.footer-light .footer-link:hover, .footer-light .footer-link:focus {
  color: #4e5155;
}
.footer-light .footer-link.disabled {
  color: rgba(0, 0, 0, 0.2) !important;
}
.footer-light .show > .footer-link,
.footer-light .active > .footer-link,
.footer-light .footer-link.show,
.footer-light .footer-link.active {
  color: #4e5155;
}
.footer-light hr {
  border-color: rgba(0, 0, 0, 0.06);
}

.footer-dark {
  color: rgba(255, 255, 255, 0.8);
}
.footer-dark .footer-text {
  color: #fff;
}
.footer-dark .footer-link {
  color: rgba(255, 255, 255, 0.8);
}
.footer-dark .footer-link:hover, .footer-dark .footer-link:focus {
  color: #fff;
}
.footer-dark .footer-link.disabled {
  color: rgba(255, 255, 255, 0.4) !important;
}
.footer-dark .show > .footer-link,
.footer-dark .active > .footer-link,
.footer-dark .footer-link.show,
.footer-dark .footer-link.active {
  color: #fff;
}
.footer-dark hr {
  border-color: rgba(255, 255, 255, 0.2);
}

.bg-facebook {
  background-color: #3b5998 !important;
}

a.bg-facebook:hover, a.bg-facebook:focus {
  background-color: #385590 !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #3b5998;
}

.bg-label-facebook {
  background-color: #2b3751 !important;
  color: #3b5998 !important;
}

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-facebook:hover {
  color: #fff;
  background-color: #627aad;
  border-color: #627aad;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-facebook, .btn-facebook:focus, .btn-facebook.focus {
  color: #fff;
  background-color: #324c81;
  border-color: #324c81;
  box-shadow: none;
}
.btn-check:checked + .btn-facebook, .btn-check:active + .btn-facebook, .btn-facebook:active, .btn-facebook.active, .show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #324c81;
  border-color: #324c81;
}
.btn-check:checked + .btn-facebook:focus, .btn-check:active + .btn-facebook:focus, .btn-facebook:active:focus, .btn-facebook.active:focus, .show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-facebook.disabled, .btn-facebook:disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
  box-shadow: none !important;
}

.btn-label-facebook {
  color: #3b5998;
  border-color: transparent;
  background: #2b3751;
}
.btn-label-facebook:hover {
  border-color: transparent;
  background: #3b5998;
  color: #fff;
}
.btn-label-facebook:focus, .btn-label-facebook.focus {
  color: #fff;
  background: #324c81;
  box-shadow: none !important;
}
.btn-label-facebook:active, .btn-label-facebook.active, .show > .btn-label-facebook.dropdown-toggle {
  color: #fff;
  background-color: #324c81;
  border-color: transparent;
}
.btn-label-facebook:active:focus, .btn-label-facebook.active:focus, .show > .btn-label-facebook.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-facebook.disabled, .btn-label-facebook:disabled {
  color: #3b5998;
  border-color: transparent;
  background: #2b3751;
  box-shadow: none;
}

.btn-outline-facebook {
  color: #3b5998;
  border-color: #3b5998;
  background: transparent;
}
.btn-outline-facebook:hover {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-check:focus + .btn-outline-facebook, .btn-outline-facebook:focus {
  color: #fff;
  background-color: #324c81;
  border-color: #324c81;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-facebook, .btn-check:active + .btn-outline-facebook, .btn-outline-facebook:active, .btn-outline-facebook.active, .btn-outline-facebook.dropdown-toggle.show {
  color: #fff;
  background-color: #324c81;
  border-color: #324c81;
}
.btn-check:checked + .btn-outline-facebook:focus, .btn-check:active + .btn-outline-facebook:focus, .btn-outline-facebook:active:focus, .btn-outline-facebook.active:focus, .btn-outline-facebook.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-facebook.disabled, .btn-outline-facebook:disabled {
  color: #3b5998;
  background-color: transparent;
}

.btn-outline-facebook .badge {
  background: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-outline-facebook:hover .badge,
.btn-outline-facebook:focus:hover .badge,
.btn-outline-facebook:active .badge,
.btn-outline-facebook.active .badge,
.show > .btn-outline-facebook.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #3b5998;
}

.bg-twitter {
  background-color: #1da1f2 !important;
}

a.bg-twitter:hover, a.bg-twitter:focus {
  background-color: #1c99e6 !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #1da1f2;
}

.bg-label-twitter {
  background-color: #264360 !important;
  color: #1da1f2 !important;
}

.btn-twitter {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-twitter:hover {
  color: #fff;
  background-color: #4ab4f5;
  border-color: #4ab4f5;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-twitter, .btn-twitter:focus, .btn-twitter.focus {
  color: #fff;
  background-color: #1989ce;
  border-color: #1989ce;
  box-shadow: none;
}
.btn-check:checked + .btn-twitter, .btn-check:active + .btn-twitter, .btn-twitter:active, .btn-twitter.active, .show > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #1989ce;
  border-color: #1989ce;
}
.btn-check:checked + .btn-twitter:focus, .btn-check:active + .btn-twitter:focus, .btn-twitter:active:focus, .btn-twitter.active:focus, .show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-twitter.disabled, .btn-twitter:disabled {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
  box-shadow: none !important;
}

.btn-label-twitter {
  color: #1da1f2;
  border-color: transparent;
  background: #264360;
}
.btn-label-twitter:hover {
  border-color: transparent;
  background: #1da1f2;
  color: #fff;
}
.btn-label-twitter:focus, .btn-label-twitter.focus {
  color: #fff;
  background: #1989ce;
  box-shadow: none !important;
}
.btn-label-twitter:active, .btn-label-twitter.active, .show > .btn-label-twitter.dropdown-toggle {
  color: #fff;
  background-color: #1989ce;
  border-color: transparent;
}
.btn-label-twitter:active:focus, .btn-label-twitter.active:focus, .show > .btn-label-twitter.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-twitter.disabled, .btn-label-twitter:disabled {
  color: #1da1f2;
  border-color: transparent;
  background: #264360;
  box-shadow: none;
}

.btn-outline-twitter {
  color: #1da1f2;
  border-color: #1da1f2;
  background: transparent;
}
.btn-outline-twitter:hover {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.btn-check:focus + .btn-outline-twitter, .btn-outline-twitter:focus {
  color: #fff;
  background-color: #1989ce;
  border-color: #1989ce;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-twitter, .btn-check:active + .btn-outline-twitter, .btn-outline-twitter:active, .btn-outline-twitter.active, .btn-outline-twitter.dropdown-toggle.show {
  color: #fff;
  background-color: #1989ce;
  border-color: #1989ce;
}
.btn-check:checked + .btn-outline-twitter:focus, .btn-check:active + .btn-outline-twitter:focus, .btn-outline-twitter:active:focus, .btn-outline-twitter.active:focus, .btn-outline-twitter.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-twitter.disabled, .btn-outline-twitter:disabled {
  color: #1da1f2;
  background-color: transparent;
}

.btn-outline-twitter .badge {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-outline-twitter:hover .badge,
.btn-outline-twitter:focus:hover .badge,
.btn-outline-twitter:active .badge,
.btn-outline-twitter.active .badge,
.show > .btn-outline-twitter.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #1da1f2;
}

.bg-google-plus {
  background-color: #dd4b39 !important;
}

a.bg-google-plus:hover, a.bg-google-plus:focus {
  background-color: #d24736 !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #dd4b39;
}

.bg-label-google-plus {
  background-color: #453542 !important;
  color: #dd4b39 !important;
}

.btn-google-plus {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-google-plus:hover {
  color: #fff;
  background-color: #e46f61;
  border-color: #e46f61;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-google-plus, .btn-google-plus:focus, .btn-google-plus.focus {
  color: #fff;
  background-color: #bc4030;
  border-color: #bc4030;
  box-shadow: none;
}
.btn-check:checked + .btn-google-plus, .btn-check:active + .btn-google-plus, .btn-google-plus:active, .btn-google-plus.active, .show > .btn-google-plus.dropdown-toggle {
  color: #fff;
  background-color: #bc4030;
  border-color: #bc4030;
}
.btn-check:checked + .btn-google-plus:focus, .btn-check:active + .btn-google-plus:focus, .btn-google-plus:active:focus, .btn-google-plus.active:focus, .show > .btn-google-plus.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-google-plus.disabled, .btn-google-plus:disabled {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
  box-shadow: none !important;
}

.btn-label-google-plus {
  color: #dd4b39;
  border-color: transparent;
  background: #453542;
}
.btn-label-google-plus:hover {
  border-color: transparent;
  background: #dd4b39;
  color: #fff;
}
.btn-label-google-plus:focus, .btn-label-google-plus.focus {
  color: #fff;
  background: #bc4030;
  box-shadow: none !important;
}
.btn-label-google-plus:active, .btn-label-google-plus.active, .show > .btn-label-google-plus.dropdown-toggle {
  color: #fff;
  background-color: #bc4030;
  border-color: transparent;
}
.btn-label-google-plus:active:focus, .btn-label-google-plus.active:focus, .show > .btn-label-google-plus.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-google-plus.disabled, .btn-label-google-plus:disabled {
  color: #dd4b39;
  border-color: transparent;
  background: #453542;
  box-shadow: none;
}

.btn-outline-google-plus {
  color: #dd4b39;
  border-color: #dd4b39;
  background: transparent;
}
.btn-outline-google-plus:hover {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-check:focus + .btn-outline-google-plus, .btn-outline-google-plus:focus {
  color: #fff;
  background-color: #bc4030;
  border-color: #bc4030;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-google-plus, .btn-check:active + .btn-outline-google-plus, .btn-outline-google-plus:active, .btn-outline-google-plus.active, .btn-outline-google-plus.dropdown-toggle.show {
  color: #fff;
  background-color: #bc4030;
  border-color: #bc4030;
}
.btn-check:checked + .btn-outline-google-plus:focus, .btn-check:active + .btn-outline-google-plus:focus, .btn-outline-google-plus:active:focus, .btn-outline-google-plus.active:focus, .btn-outline-google-plus.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-google-plus.disabled, .btn-outline-google-plus:disabled {
  color: #dd4b39;
  background-color: transparent;
}

.btn-outline-google-plus .badge {
  background: #dd4b39;
  border-color: #dd4b39;
  color: #fff;
}

.btn-outline-google-plus:hover .badge,
.btn-outline-google-plus:focus:hover .badge,
.btn-outline-google-plus:active .badge,
.btn-outline-google-plus.active .badge,
.show > .btn-outline-google-plus.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #dd4b39;
}

.bg-instagram {
  background-color: #e1306c !important;
}

a.bg-instagram:hover, a.bg-instagram:focus {
  background-color: #d62e67 !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #e1306c;
}

.bg-label-instagram {
  background-color: #46314a !important;
  color: #e1306c !important;
}

.btn-instagram {
  color: #fff;
  background-color: #e1306c;
  border-color: #e1306c;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-instagram:hover {
  color: #fff;
  background-color: #e75989;
  border-color: #e75989;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-instagram, .btn-instagram:focus, .btn-instagram.focus {
  color: #fff;
  background-color: #bf295c;
  border-color: #bf295c;
  box-shadow: none;
}
.btn-check:checked + .btn-instagram, .btn-check:active + .btn-instagram, .btn-instagram:active, .btn-instagram.active, .show > .btn-instagram.dropdown-toggle {
  color: #fff;
  background-color: #bf295c;
  border-color: #bf295c;
}
.btn-check:checked + .btn-instagram:focus, .btn-check:active + .btn-instagram:focus, .btn-instagram:active:focus, .btn-instagram.active:focus, .show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-instagram.disabled, .btn-instagram:disabled {
  color: #fff;
  background-color: #e1306c;
  border-color: #e1306c;
  box-shadow: none !important;
}

.btn-label-instagram {
  color: #e1306c;
  border-color: transparent;
  background: #46314a;
}
.btn-label-instagram:hover {
  border-color: transparent;
  background: #e1306c;
  color: #fff;
}
.btn-label-instagram:focus, .btn-label-instagram.focus {
  color: #fff;
  background: #bf295c;
  box-shadow: none !important;
}
.btn-label-instagram:active, .btn-label-instagram.active, .show > .btn-label-instagram.dropdown-toggle {
  color: #fff;
  background-color: #bf295c;
  border-color: transparent;
}
.btn-label-instagram:active:focus, .btn-label-instagram.active:focus, .show > .btn-label-instagram.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-instagram.disabled, .btn-label-instagram:disabled {
  color: #e1306c;
  border-color: transparent;
  background: #46314a;
  box-shadow: none;
}

.btn-outline-instagram {
  color: #e1306c;
  border-color: #e1306c;
  background: transparent;
}
.btn-outline-instagram:hover {
  color: #fff;
  background-color: #e1306c;
  border-color: #e1306c;
}
.btn-check:focus + .btn-outline-instagram, .btn-outline-instagram:focus {
  color: #fff;
  background-color: #bf295c;
  border-color: #bf295c;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-instagram, .btn-check:active + .btn-outline-instagram, .btn-outline-instagram:active, .btn-outline-instagram.active, .btn-outline-instagram.dropdown-toggle.show {
  color: #fff;
  background-color: #bf295c;
  border-color: #bf295c;
}
.btn-check:checked + .btn-outline-instagram:focus, .btn-check:active + .btn-outline-instagram:focus, .btn-outline-instagram:active:focus, .btn-outline-instagram.active:focus, .btn-outline-instagram.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-instagram.disabled, .btn-outline-instagram:disabled {
  color: #e1306c;
  background-color: transparent;
}

.btn-outline-instagram .badge {
  background: #e1306c;
  border-color: #e1306c;
  color: #fff;
}

.btn-outline-instagram:hover .badge,
.btn-outline-instagram:focus:hover .badge,
.btn-outline-instagram:active .badge,
.btn-outline-instagram.active .badge,
.show > .btn-outline-instagram.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #e1306c;
}

.bg-linkedin {
  background-color: #0077b5 !important;
}

a.bg-linkedin:hover, a.bg-linkedin:focus {
  background-color: #0071ac !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #0077b5;
}

.bg-label-linkedin {
  background-color: #223c56 !important;
  color: #0077b5 !important;
}

.btn-linkedin {
  color: #fff;
  background-color: #0077b5;
  border-color: #0077b5;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-linkedin:hover {
  color: #fff;
  background-color: #3392c4;
  border-color: #3392c4;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-linkedin, .btn-linkedin:focus, .btn-linkedin.focus {
  color: #fff;
  background-color: #00659a;
  border-color: #00659a;
  box-shadow: none;
}
.btn-check:checked + .btn-linkedin, .btn-check:active + .btn-linkedin, .btn-linkedin:active, .btn-linkedin.active, .show > .btn-linkedin.dropdown-toggle {
  color: #fff;
  background-color: #00659a;
  border-color: #00659a;
}
.btn-check:checked + .btn-linkedin:focus, .btn-check:active + .btn-linkedin:focus, .btn-linkedin:active:focus, .btn-linkedin.active:focus, .show > .btn-linkedin.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-linkedin.disabled, .btn-linkedin:disabled {
  color: #fff;
  background-color: #0077b5;
  border-color: #0077b5;
  box-shadow: none !important;
}

.btn-label-linkedin {
  color: #0077b5;
  border-color: transparent;
  background: #223c56;
}
.btn-label-linkedin:hover {
  border-color: transparent;
  background: #0077b5;
  color: #fff;
}
.btn-label-linkedin:focus, .btn-label-linkedin.focus {
  color: #fff;
  background: #00659a;
  box-shadow: none !important;
}
.btn-label-linkedin:active, .btn-label-linkedin.active, .show > .btn-label-linkedin.dropdown-toggle {
  color: #fff;
  background-color: #00659a;
  border-color: transparent;
}
.btn-label-linkedin:active:focus, .btn-label-linkedin.active:focus, .show > .btn-label-linkedin.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-linkedin.disabled, .btn-label-linkedin:disabled {
  color: #0077b5;
  border-color: transparent;
  background: #223c56;
  box-shadow: none;
}

.btn-outline-linkedin {
  color: #0077b5;
  border-color: #0077b5;
  background: transparent;
}
.btn-outline-linkedin:hover {
  color: #fff;
  background-color: #0077b5;
  border-color: #0077b5;
}
.btn-check:focus + .btn-outline-linkedin, .btn-outline-linkedin:focus {
  color: #fff;
  background-color: #00659a;
  border-color: #00659a;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-linkedin, .btn-check:active + .btn-outline-linkedin, .btn-outline-linkedin:active, .btn-outline-linkedin.active, .btn-outline-linkedin.dropdown-toggle.show {
  color: #fff;
  background-color: #00659a;
  border-color: #00659a;
}
.btn-check:checked + .btn-outline-linkedin:focus, .btn-check:active + .btn-outline-linkedin:focus, .btn-outline-linkedin:active:focus, .btn-outline-linkedin.active:focus, .btn-outline-linkedin.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-linkedin.disabled, .btn-outline-linkedin:disabled {
  color: #0077b5;
  background-color: transparent;
}

.btn-outline-linkedin .badge {
  background: #0077b5;
  border-color: #0077b5;
  color: #fff;
}

.btn-outline-linkedin:hover .badge,
.btn-outline-linkedin:focus:hover .badge,
.btn-outline-linkedin:active .badge,
.btn-outline-linkedin.active .badge,
.show > .btn-outline-linkedin.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #0077b5;
}

.bg-github {
  background-color: #333 !important;
}

a.bg-github:hover, a.bg-github:focus {
  background-color: #303030 !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #333;
}

.bg-label-github {
  background-color: #2a3141 !important;
  color: #333 !important;
}

.btn-github {
  color: #fff;
  background-color: #333;
  border-color: #333;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-github:hover {
  color: #fff;
  background-color: #5c5c5c;
  border-color: #5c5c5c;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-github, .btn-github:focus, .btn-github.focus {
  color: #fff;
  background-color: #2b2b2b;
  border-color: #2b2b2b;
  box-shadow: none;
}
.btn-check:checked + .btn-github, .btn-check:active + .btn-github, .btn-github:active, .btn-github.active, .show > .btn-github.dropdown-toggle {
  color: #fff;
  background-color: #2b2b2b;
  border-color: #2b2b2b;
}
.btn-check:checked + .btn-github:focus, .btn-check:active + .btn-github:focus, .btn-github:active:focus, .btn-github.active:focus, .show > .btn-github.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-github.disabled, .btn-github:disabled {
  color: #fff;
  background-color: #333;
  border-color: #333;
  box-shadow: none !important;
}

.btn-label-github {
  color: #333;
  border-color: transparent;
  background: #2a3141;
}
.btn-label-github:hover {
  border-color: transparent;
  background: #333;
  color: #fff;
}
.btn-label-github:focus, .btn-label-github.focus {
  color: #fff;
  background: #2b2b2b;
  box-shadow: none !important;
}
.btn-label-github:active, .btn-label-github.active, .show > .btn-label-github.dropdown-toggle {
  color: #fff;
  background-color: #2b2b2b;
  border-color: transparent;
}
.btn-label-github:active:focus, .btn-label-github.active:focus, .show > .btn-label-github.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-github.disabled, .btn-label-github:disabled {
  color: #333;
  border-color: transparent;
  background: #2a3141;
  box-shadow: none;
}

.btn-outline-github {
  color: #333;
  border-color: #333;
  background: transparent;
}
.btn-outline-github:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-check:focus + .btn-outline-github, .btn-outline-github:focus {
  color: #fff;
  background-color: #2b2b2b;
  border-color: #2b2b2b;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-github, .btn-check:active + .btn-outline-github, .btn-outline-github:active, .btn-outline-github.active, .btn-outline-github.dropdown-toggle.show {
  color: #fff;
  background-color: #2b2b2b;
  border-color: #2b2b2b;
}
.btn-check:checked + .btn-outline-github:focus, .btn-check:active + .btn-outline-github:focus, .btn-outline-github:active:focus, .btn-outline-github.active:focus, .btn-outline-github.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-github.disabled, .btn-outline-github:disabled {
  color: #333;
  background-color: transparent;
}

.btn-outline-github .badge {
  background: #333;
  border-color: #333;
  color: #fff;
}

.btn-outline-github:hover .badge,
.btn-outline-github:focus:hover .badge,
.btn-outline-github:active .badge,
.btn-outline-github.active .badge,
.show > .btn-outline-github.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #333;
}

.bg-dribbble {
  background-color: #ea4c89 !important;
}

a.bg-dribbble:hover, a.bg-dribbble:focus {
  background-color: #de4882 !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #ea4c89;
}

.bg-label-dribbble {
  background-color: #47354f !important;
  color: #ea4c89 !important;
}

.btn-dribbble {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-dribbble:hover {
  color: #fff;
  background-color: #ee70a1;
  border-color: #ee70a1;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-dribbble, .btn-dribbble:focus, .btn-dribbble.focus {
  color: #fff;
  background-color: #c74174;
  border-color: #c74174;
  box-shadow: none;
}
.btn-check:checked + .btn-dribbble, .btn-check:active + .btn-dribbble, .btn-dribbble:active, .btn-dribbble.active, .show > .btn-dribbble.dropdown-toggle {
  color: #fff;
  background-color: #c74174;
  border-color: #c74174;
}
.btn-check:checked + .btn-dribbble:focus, .btn-check:active + .btn-dribbble:focus, .btn-dribbble:active:focus, .btn-dribbble.active:focus, .show > .btn-dribbble.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-dribbble.disabled, .btn-dribbble:disabled {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
  box-shadow: none !important;
}

.btn-label-dribbble {
  color: #ea4c89;
  border-color: transparent;
  background: #47354f;
}
.btn-label-dribbble:hover {
  border-color: transparent;
  background: #ea4c89;
  color: #fff;
}
.btn-label-dribbble:focus, .btn-label-dribbble.focus {
  color: #fff;
  background: #c74174;
  box-shadow: none !important;
}
.btn-label-dribbble:active, .btn-label-dribbble.active, .show > .btn-label-dribbble.dropdown-toggle {
  color: #fff;
  background-color: #c74174;
  border-color: transparent;
}
.btn-label-dribbble:active:focus, .btn-label-dribbble.active:focus, .show > .btn-label-dribbble.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-dribbble.disabled, .btn-label-dribbble:disabled {
  color: #ea4c89;
  border-color: transparent;
  background: #47354f;
  box-shadow: none;
}

.btn-outline-dribbble {
  color: #ea4c89;
  border-color: #ea4c89;
  background: transparent;
}
.btn-outline-dribbble:hover {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-check:focus + .btn-outline-dribbble, .btn-outline-dribbble:focus {
  color: #fff;
  background-color: #c74174;
  border-color: #c74174;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-dribbble, .btn-check:active + .btn-outline-dribbble, .btn-outline-dribbble:active, .btn-outline-dribbble.active, .btn-outline-dribbble.dropdown-toggle.show {
  color: #fff;
  background-color: #c74174;
  border-color: #c74174;
}
.btn-check:checked + .btn-outline-dribbble:focus, .btn-check:active + .btn-outline-dribbble:focus, .btn-outline-dribbble:active:focus, .btn-outline-dribbble.active:focus, .btn-outline-dribbble.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-dribbble.disabled, .btn-outline-dribbble:disabled {
  color: #ea4c89;
  background-color: transparent;
}

.btn-outline-dribbble .badge {
  background: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}

.btn-outline-dribbble:hover .badge,
.btn-outline-dribbble:focus:hover .badge,
.btn-outline-dribbble:active .badge,
.btn-outline-dribbble.active .badge,
.show > .btn-outline-dribbble.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #ea4c89;
}

.bg-pinterest {
  background-color: #cb2027 !important;
}

a.bg-pinterest:hover, a.bg-pinterest:focus {
  background-color: #c11e25 !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #cb2027;
}

.bg-label-pinterest {
  background-color: #422e3f !important;
  color: #cb2027 !important;
}

.btn-pinterest {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-pinterest:hover {
  color: #fff;
  background-color: #d54d52;
  border-color: #d54d52;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-pinterest, .btn-pinterest:focus, .btn-pinterest.focus {
  color: #fff;
  background-color: #ad1b21;
  border-color: #ad1b21;
  box-shadow: none;
}
.btn-check:checked + .btn-pinterest, .btn-check:active + .btn-pinterest, .btn-pinterest:active, .btn-pinterest.active, .show > .btn-pinterest.dropdown-toggle {
  color: #fff;
  background-color: #ad1b21;
  border-color: #ad1b21;
}
.btn-check:checked + .btn-pinterest:focus, .btn-check:active + .btn-pinterest:focus, .btn-pinterest:active:focus, .btn-pinterest.active:focus, .show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-pinterest.disabled, .btn-pinterest:disabled {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027;
  box-shadow: none !important;
}

.btn-label-pinterest {
  color: #cb2027;
  border-color: transparent;
  background: #422e3f;
}
.btn-label-pinterest:hover {
  border-color: transparent;
  background: #cb2027;
  color: #fff;
}
.btn-label-pinterest:focus, .btn-label-pinterest.focus {
  color: #fff;
  background: #ad1b21;
  box-shadow: none !important;
}
.btn-label-pinterest:active, .btn-label-pinterest.active, .show > .btn-label-pinterest.dropdown-toggle {
  color: #fff;
  background-color: #ad1b21;
  border-color: transparent;
}
.btn-label-pinterest:active:focus, .btn-label-pinterest.active:focus, .show > .btn-label-pinterest.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-pinterest.disabled, .btn-label-pinterest:disabled {
  color: #cb2027;
  border-color: transparent;
  background: #422e3f;
  box-shadow: none;
}

.btn-outline-pinterest {
  color: #cb2027;
  border-color: #cb2027;
  background: transparent;
}
.btn-outline-pinterest:hover {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027;
}
.btn-check:focus + .btn-outline-pinterest, .btn-outline-pinterest:focus {
  color: #fff;
  background-color: #ad1b21;
  border-color: #ad1b21;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-pinterest, .btn-check:active + .btn-outline-pinterest, .btn-outline-pinterest:active, .btn-outline-pinterest.active, .btn-outline-pinterest.dropdown-toggle.show {
  color: #fff;
  background-color: #ad1b21;
  border-color: #ad1b21;
}
.btn-check:checked + .btn-outline-pinterest:focus, .btn-check:active + .btn-outline-pinterest:focus, .btn-outline-pinterest:active:focus, .btn-outline-pinterest.active:focus, .btn-outline-pinterest.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-pinterest.disabled, .btn-outline-pinterest:disabled {
  color: #cb2027;
  background-color: transparent;
}

.btn-outline-pinterest .badge {
  background: #cb2027;
  border-color: #cb2027;
  color: #fff;
}

.btn-outline-pinterest:hover .badge,
.btn-outline-pinterest:focus:hover .badge,
.btn-outline-pinterest:active .badge,
.btn-outline-pinterest.active .badge,
.show > .btn-outline-pinterest.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #cb2027;
}

.bg-slack {
  background-color: #4a154b !important;
}

a.bg-slack:hover, a.bg-slack:focus {
  background-color: #461447 !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #4a154b;
}

.bg-label-slack {
  background-color: #2d2d45 !important;
  color: #4a154b !important;
}

.btn-slack {
  color: #fff;
  background-color: #4a154b;
  border-color: #4a154b;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-slack:hover {
  color: #fff;
  background-color: #6e446f;
  border-color: #6e446f;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-slack, .btn-slack:focus, .btn-slack.focus {
  color: #fff;
  background-color: #3f1240;
  border-color: #3f1240;
  box-shadow: none;
}
.btn-check:checked + .btn-slack, .btn-check:active + .btn-slack, .btn-slack:active, .btn-slack.active, .show > .btn-slack.dropdown-toggle {
  color: #fff;
  background-color: #3f1240;
  border-color: #3f1240;
}
.btn-check:checked + .btn-slack:focus, .btn-check:active + .btn-slack:focus, .btn-slack:active:focus, .btn-slack.active:focus, .show > .btn-slack.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-slack.disabled, .btn-slack:disabled {
  color: #fff;
  background-color: #4a154b;
  border-color: #4a154b;
  box-shadow: none !important;
}

.btn-label-slack {
  color: #4a154b;
  border-color: transparent;
  background: #2d2d45;
}
.btn-label-slack:hover {
  border-color: transparent;
  background: #4a154b;
  color: #fff;
}
.btn-label-slack:focus, .btn-label-slack.focus {
  color: #fff;
  background: #3f1240;
  box-shadow: none !important;
}
.btn-label-slack:active, .btn-label-slack.active, .show > .btn-label-slack.dropdown-toggle {
  color: #fff;
  background-color: #3f1240;
  border-color: transparent;
}
.btn-label-slack:active:focus, .btn-label-slack.active:focus, .show > .btn-label-slack.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-slack.disabled, .btn-label-slack:disabled {
  color: #4a154b;
  border-color: transparent;
  background: #2d2d45;
  box-shadow: none;
}

.btn-outline-slack {
  color: #4a154b;
  border-color: #4a154b;
  background: transparent;
}
.btn-outline-slack:hover {
  color: #fff;
  background-color: #4a154b;
  border-color: #4a154b;
}
.btn-check:focus + .btn-outline-slack, .btn-outline-slack:focus {
  color: #fff;
  background-color: #3f1240;
  border-color: #3f1240;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-slack, .btn-check:active + .btn-outline-slack, .btn-outline-slack:active, .btn-outline-slack.active, .btn-outline-slack.dropdown-toggle.show {
  color: #fff;
  background-color: #3f1240;
  border-color: #3f1240;
}
.btn-check:checked + .btn-outline-slack:focus, .btn-check:active + .btn-outline-slack:focus, .btn-outline-slack:active:focus, .btn-outline-slack.active:focus, .btn-outline-slack.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-slack.disabled, .btn-outline-slack:disabled {
  color: #4a154b;
  background-color: transparent;
}

.btn-outline-slack .badge {
  background: #4a154b;
  border-color: #4a154b;
  color: #fff;
}

.btn-outline-slack:hover .badge,
.btn-outline-slack:focus:hover .badge,
.btn-outline-slack:active .badge,
.btn-outline-slack.active .badge,
.show > .btn-outline-slack.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #4a154b;
}

.bg-reddit {
  background-color: #ff4500 !important;
}

a.bg-reddit:hover, a.bg-reddit:focus {
  background-color: #f24200 !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #ff4500;
}

.bg-label-reddit {
  background-color: #4a3439 !important;
  color: #ff4500 !important;
}

.btn-reddit {
  color: #fff;
  background-color: #ff4500;
  border-color: #ff4500;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-reddit:hover {
  color: #fff;
  background-color: #ff6a33;
  border-color: #ff6a33;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-reddit, .btn-reddit:focus, .btn-reddit.focus {
  color: #fff;
  background-color: #d93b00;
  border-color: #d93b00;
  box-shadow: none;
}
.btn-check:checked + .btn-reddit, .btn-check:active + .btn-reddit, .btn-reddit:active, .btn-reddit.active, .show > .btn-reddit.dropdown-toggle {
  color: #fff;
  background-color: #d93b00;
  border-color: #d93b00;
}
.btn-check:checked + .btn-reddit:focus, .btn-check:active + .btn-reddit:focus, .btn-reddit:active:focus, .btn-reddit.active:focus, .show > .btn-reddit.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-reddit.disabled, .btn-reddit:disabled {
  color: #fff;
  background-color: #ff4500;
  border-color: #ff4500;
  box-shadow: none !important;
}

.btn-label-reddit {
  color: #ff4500;
  border-color: transparent;
  background: #4a3439;
}
.btn-label-reddit:hover {
  border-color: transparent;
  background: #ff4500;
  color: #fff;
}
.btn-label-reddit:focus, .btn-label-reddit.focus {
  color: #fff;
  background: #d93b00;
  box-shadow: none !important;
}
.btn-label-reddit:active, .btn-label-reddit.active, .show > .btn-label-reddit.dropdown-toggle {
  color: #fff;
  background-color: #d93b00;
  border-color: transparent;
}
.btn-label-reddit:active:focus, .btn-label-reddit.active:focus, .show > .btn-label-reddit.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-reddit.disabled, .btn-label-reddit:disabled {
  color: #ff4500;
  border-color: transparent;
  background: #4a3439;
  box-shadow: none;
}

.btn-outline-reddit {
  color: #ff4500;
  border-color: #ff4500;
  background: transparent;
}
.btn-outline-reddit:hover {
  color: #fff;
  background-color: #ff4500;
  border-color: #ff4500;
}
.btn-check:focus + .btn-outline-reddit, .btn-outline-reddit:focus {
  color: #fff;
  background-color: #d93b00;
  border-color: #d93b00;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-reddit, .btn-check:active + .btn-outline-reddit, .btn-outline-reddit:active, .btn-outline-reddit.active, .btn-outline-reddit.dropdown-toggle.show {
  color: #fff;
  background-color: #d93b00;
  border-color: #d93b00;
}
.btn-check:checked + .btn-outline-reddit:focus, .btn-check:active + .btn-outline-reddit:focus, .btn-outline-reddit:active:focus, .btn-outline-reddit.active:focus, .btn-outline-reddit.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-reddit.disabled, .btn-outline-reddit:disabled {
  color: #ff4500;
  background-color: transparent;
}

.btn-outline-reddit .badge {
  background: #ff4500;
  border-color: #ff4500;
  color: #fff;
}

.btn-outline-reddit:hover .badge,
.btn-outline-reddit:focus:hover .badge,
.btn-outline-reddit:active .badge,
.btn-outline-reddit.active .badge,
.show > .btn-outline-reddit.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #ff4500;
}

.bg-youtube {
  background-color: #ff0000 !important;
}

a.bg-youtube:hover, a.bg-youtube:focus {
  background-color: #f20000 !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #ff0000;
}

.bg-label-youtube {
  background-color: #4a2939 !important;
  color: #ff0000 !important;
}

.btn-youtube {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-youtube:hover {
  color: #fff;
  background-color: #ff3333;
  border-color: #ff3333;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-youtube, .btn-youtube:focus, .btn-youtube.focus {
  color: #fff;
  background-color: #d90000;
  border-color: #d90000;
  box-shadow: none;
}
.btn-check:checked + .btn-youtube, .btn-check:active + .btn-youtube, .btn-youtube:active, .btn-youtube.active, .show > .btn-youtube.dropdown-toggle {
  color: #fff;
  background-color: #d90000;
  border-color: #d90000;
}
.btn-check:checked + .btn-youtube:focus, .btn-check:active + .btn-youtube:focus, .btn-youtube:active:focus, .btn-youtube.active:focus, .show > .btn-youtube.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-youtube.disabled, .btn-youtube:disabled {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
  box-shadow: none !important;
}

.btn-label-youtube {
  color: #ff0000;
  border-color: transparent;
  background: #4a2939;
}
.btn-label-youtube:hover {
  border-color: transparent;
  background: #ff0000;
  color: #fff;
}
.btn-label-youtube:focus, .btn-label-youtube.focus {
  color: #fff;
  background: #d90000;
  box-shadow: none !important;
}
.btn-label-youtube:active, .btn-label-youtube.active, .show > .btn-label-youtube.dropdown-toggle {
  color: #fff;
  background-color: #d90000;
  border-color: transparent;
}
.btn-label-youtube:active:focus, .btn-label-youtube.active:focus, .show > .btn-label-youtube.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-youtube.disabled, .btn-label-youtube:disabled {
  color: #ff0000;
  border-color: transparent;
  background: #4a2939;
  box-shadow: none;
}

.btn-outline-youtube {
  color: #ff0000;
  border-color: #ff0000;
  background: transparent;
}
.btn-outline-youtube:hover {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
}
.btn-check:focus + .btn-outline-youtube, .btn-outline-youtube:focus {
  color: #fff;
  background-color: #d90000;
  border-color: #d90000;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-youtube, .btn-check:active + .btn-outline-youtube, .btn-outline-youtube:active, .btn-outline-youtube.active, .btn-outline-youtube.dropdown-toggle.show {
  color: #fff;
  background-color: #d90000;
  border-color: #d90000;
}
.btn-check:checked + .btn-outline-youtube:focus, .btn-check:active + .btn-outline-youtube:focus, .btn-outline-youtube:active:focus, .btn-outline-youtube.active:focus, .btn-outline-youtube.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-youtube.disabled, .btn-outline-youtube:disabled {
  color: #ff0000;
  background-color: transparent;
}

.btn-outline-youtube .badge {
  background: #ff0000;
  border-color: #ff0000;
  color: #fff;
}

.btn-outline-youtube:hover .badge,
.btn-outline-youtube:focus:hover .badge,
.btn-outline-youtube:active .badge,
.btn-outline-youtube.active .badge,
.show > .btn-outline-youtube.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #ff0000;
}

.bg-vimeo {
  background-color: #1ab7ea !important;
}

a.bg-vimeo:hover, a.bg-vimeo:focus {
  background-color: #19aede !important;
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #1ab7ea;
}

.bg-label-vimeo {
  background-color: #26465f !important;
  color: #1ab7ea !important;
}

.btn-vimeo {
  color: #fff;
  background-color: #1ab7ea;
  border-color: #1ab7ea;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-vimeo:hover {
  color: #fff;
  background-color: #48c5ee;
  border-color: #48c5ee;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-vimeo, .btn-vimeo:focus, .btn-vimeo.focus {
  color: #fff;
  background-color: #169cc7;
  border-color: #169cc7;
  box-shadow: none;
}
.btn-check:checked + .btn-vimeo, .btn-check:active + .btn-vimeo, .btn-vimeo:active, .btn-vimeo.active, .show > .btn-vimeo.dropdown-toggle {
  color: #fff;
  background-color: #169cc7;
  border-color: #169cc7;
}
.btn-check:checked + .btn-vimeo:focus, .btn-check:active + .btn-vimeo:focus, .btn-vimeo:active:focus, .btn-vimeo.active:focus, .show > .btn-vimeo.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-vimeo.disabled, .btn-vimeo:disabled {
  color: #fff;
  background-color: #1ab7ea;
  border-color: #1ab7ea;
  box-shadow: none !important;
}

.btn-label-vimeo {
  color: #1ab7ea;
  border-color: transparent;
  background: #26465f;
}
.btn-label-vimeo:hover {
  border-color: transparent;
  background: #1ab7ea;
  color: #fff;
}
.btn-label-vimeo:focus, .btn-label-vimeo.focus {
  color: #fff;
  background: #169cc7;
  box-shadow: none !important;
}
.btn-label-vimeo:active, .btn-label-vimeo.active, .show > .btn-label-vimeo.dropdown-toggle {
  color: #fff;
  background-color: #169cc7;
  border-color: transparent;
}
.btn-label-vimeo:active:focus, .btn-label-vimeo.active:focus, .show > .btn-label-vimeo.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-vimeo.disabled, .btn-label-vimeo:disabled {
  color: #1ab7ea;
  border-color: transparent;
  background: #26465f;
  box-shadow: none;
}

.btn-outline-vimeo {
  color: #1ab7ea;
  border-color: #1ab7ea;
  background: transparent;
}
.btn-outline-vimeo:hover {
  color: #fff;
  background-color: #1ab7ea;
  border-color: #1ab7ea;
}
.btn-check:focus + .btn-outline-vimeo, .btn-outline-vimeo:focus {
  color: #fff;
  background-color: #169cc7;
  border-color: #169cc7;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-vimeo, .btn-check:active + .btn-outline-vimeo, .btn-outline-vimeo:active, .btn-outline-vimeo.active, .btn-outline-vimeo.dropdown-toggle.show {
  color: #fff;
  background-color: #169cc7;
  border-color: #169cc7;
}
.btn-check:checked + .btn-outline-vimeo:focus, .btn-check:active + .btn-outline-vimeo:focus, .btn-outline-vimeo:active:focus, .btn-outline-vimeo.active:focus, .btn-outline-vimeo.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-vimeo.disabled, .btn-outline-vimeo:disabled {
  color: #1ab7ea;
  background-color: transparent;
}

.btn-outline-vimeo .badge {
  background: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff;
}

.btn-outline-vimeo:hover .badge,
.btn-outline-vimeo:focus:hover .badge,
.btn-outline-vimeo:active .badge,
.btn-outline-vimeo.active .badge,
.show > .btn-outline-vimeo.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #1ab7ea;
}

.btn-white {
  color: #a1b0cb;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.btn-white:hover {
  color: #fff;
  background-color: #cccccc;
  border-color: #cccccc;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.25);
}
.btn-check:focus + .btn-white, .btn-white:focus, .btn-white.focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: none;
}
.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-white.disabled, .btn-white:disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none !important;
}

.btn-label-white {
  color: #a1b0cb;
  border-color: transparent;
  background: #4a5262;
}
.btn-label-white:hover {
  border-color: transparent;
  background: #fff;
  color: #a1b0cb;
}
.btn-label-white:focus, .btn-label-white.focus {
  color: #a1b0cb;
  background: white;
  box-shadow: none !important;
}
.btn-label-white:active, .btn-label-white.active, .show > .btn-label-white.dropdown-toggle {
  color: #a1b0cb;
  background-color: white;
  border-color: transparent;
}
.btn-label-white:active:focus, .btn-label-white.active:focus, .show > .btn-label-white.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-white.disabled, .btn-label-white:disabled {
  color: #a1b0cb;
  border-color: transparent;
  background: #4a5262;
  box-shadow: none;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
  background: transparent;
}
.btn-outline-white:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: none;
}
.btn-check:checked + .btn-outline-white, .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-outline-white:focus, .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white .badge {
  background: #fff;
  border-color: #fff;
  color: #000;
}

.btn-outline-white:hover .badge,
.btn-outline-white:focus:hover .badge,
.btn-outline-white:active .badge,
.btn-outline-white.active .badge,
.show > .btn-outline-white.dropdown-toggle .badge {
  background: #000;
  border-color: #000;
  color: #fff;
}

.form-check-black .form-check-input:focus {
  border-color: #000;
}
.form-check-black .form-check-input:checked {
  background-color: #000;
  border-color: #000;
}
.form-check-black .form-check-input[type=checkbox]:indeterminate {
  background-color: #000;
  border-color: #000;
}

.form-check-black.custom-option.checked {
  border: 1px solid #000;
}

.form-check-white .form-check-input:focus {
  border-color: #fff;
}
.form-check-white .form-check-input:checked {
  background-color: #fff;
  border-color: #fff;
}
.form-check-white .form-check-input[type=checkbox]:indeterminate {
  background-color: #fff;
  border-color: #fff;
}

.form-check-white.custom-option.checked {
  border: 1px solid #fff;
}

.form-check-silver .form-check-input:focus {
  border-color: #eee;
}
.form-check-silver .form-check-input:checked {
  background-color: #eee;
  border-color: #eee;
}
.form-check-silver .form-check-input[type=checkbox]:indeterminate {
  background-color: #eee;
  border-color: #eee;
}

.form-check-silver.custom-option.checked {
  border: 1px solid #eee;
}

.form-check-gray .form-check-input:focus {
  border-color: #777;
}
.form-check-gray .form-check-input:checked {
  background-color: #777;
  border-color: #777;
}
.form-check-gray .form-check-input[type=checkbox]:indeterminate {
  background-color: #777;
  border-color: #777;
}

.form-check-gray.custom-option.checked {
  border: 1px solid #777;
}

.form-check-gold .form-check-input:focus {
  border-color: #ffeb3b;
}
.form-check-gold .form-check-input:checked {
  background-color: #ffeb3b;
  border-color: #ffeb3b;
}
.form-check-gold .form-check-input[type=checkbox]:indeterminate {
  background-color: #ffeb3b;
  border-color: #ffeb3b;
}

.form-check-gold.custom-option.checked {
  border: 1px solid #ffeb3b;
}

.form-check-pink .form-check-input:focus {
  border-color: #e91e63;
}
.form-check-pink .form-check-input:checked {
  background-color: #e91e63;
  border-color: #e91e63;
}
.form-check-pink .form-check-input[type=checkbox]:indeterminate {
  background-color: #e91e63;
  border-color: #e91e63;
}

.form-check-pink.custom-option.checked {
  border: 1px solid #e91e63;
}

.form-check-red .form-check-input:focus {
  border-color: #f44336;
}
.form-check-red .form-check-input:checked {
  background-color: #f44336;
  border-color: #f44336;
}
.form-check-red .form-check-input[type=checkbox]:indeterminate {
  background-color: #f44336;
  border-color: #f44336;
}

.form-check-red.custom-option.checked {
  border: 1px solid #f44336;
}

.navbar.bg-secondary {
  background-color: #69809a !important;
  color: #e0e5ea;
}
.navbar.bg-secondary .navbar-brand,
.navbar.bg-secondary .navbar-brand a {
  color: #fff;
}
.navbar.bg-secondary .navbar-brand:hover, .navbar.bg-secondary .navbar-brand:focus,
.navbar.bg-secondary .navbar-brand a:hover,
.navbar.bg-secondary .navbar-brand a:focus {
  color: #fff;
}
.navbar.bg-secondary .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-secondary .navbar-search-wrapper .search-input {
  color: #e0e5ea;
}
.navbar.bg-secondary .search-input-wrapper .search-input,
.navbar.bg-secondary .search-input-wrapper .search-toggler {
  background-color: #69809a !important;
  color: #e0e5ea;
}
.navbar.bg-secondary .navbar-nav > .nav-link,
.navbar.bg-secondary .navbar-nav > .nav-item > .nav-link,
.navbar.bg-secondary .navbar-nav > .nav > .nav-item > .nav-link {
  color: #e0e5ea;
}
.navbar.bg-secondary .navbar-nav > .nav-link:hover, .navbar.bg-secondary .navbar-nav > .nav-link:focus,
.navbar.bg-secondary .navbar-nav > .nav-item > .nav-link:hover,
.navbar.bg-secondary .navbar-nav > .nav-item > .nav-link:focus,
.navbar.bg-secondary .navbar-nav > .nav > .nav-item > .nav-link:hover,
.navbar.bg-secondary .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #fff;
}
.navbar.bg-secondary .navbar-nav > .nav-link.disabled,
.navbar.bg-secondary .navbar-nav > .nav-item > .nav-link.disabled,
.navbar.bg-secondary .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #b0bdca !important;
}
.navbar.bg-secondary .navbar-nav .show > .nav-link,
.navbar.bg-secondary .navbar-nav .active > .nav-link,
.navbar.bg-secondary .navbar-nav .nav-link.show,
.navbar.bg-secondary .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-secondary .navbar-toggler {
  color: #e0e5ea;
  border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-secondary .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar.bg-secondary .navbar-text {
  color: #e0e5ea;
}
.navbar.bg-secondary .navbar-text a {
  color: #fff;
}
.navbar.bg-secondary .navbar-text a:hover, .navbar.bg-secondary .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-secondary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-success {
  background-color: #39da8a !important;
  color: #e2faee;
}
.navbar.bg-success .navbar-brand,
.navbar.bg-success .navbar-brand a {
  color: #fff;
}
.navbar.bg-success .navbar-brand:hover, .navbar.bg-success .navbar-brand:focus,
.navbar.bg-success .navbar-brand a:hover,
.navbar.bg-success .navbar-brand a:focus {
  color: #fff;
}
.navbar.bg-success .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-success .navbar-search-wrapper .search-input {
  color: #e2faee;
}
.navbar.bg-success .search-input-wrapper .search-input,
.navbar.bg-success .search-input-wrapper .search-toggler {
  background-color: #39da8a !important;
  color: #e2faee;
}
.navbar.bg-success .navbar-nav > .nav-link,
.navbar.bg-success .navbar-nav > .nav-item > .nav-link,
.navbar.bg-success .navbar-nav > .nav > .nav-item > .nav-link {
  color: #e2faee;
}
.navbar.bg-success .navbar-nav > .nav-link:hover, .navbar.bg-success .navbar-nav > .nav-link:focus,
.navbar.bg-success .navbar-nav > .nav-item > .nav-link:hover,
.navbar.bg-success .navbar-nav > .nav-item > .nav-link:focus,
.navbar.bg-success .navbar-nav > .nav > .nav-item > .nav-link:hover,
.navbar.bg-success .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #fff;
}
.navbar.bg-success .navbar-nav > .nav-link.disabled,
.navbar.bg-success .navbar-nav > .nav-item > .nav-link.disabled,
.navbar.bg-success .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #9eedc6 !important;
}
.navbar.bg-success .navbar-nav .show > .nav-link,
.navbar.bg-success .navbar-nav .active > .nav-link,
.navbar.bg-success .navbar-nav .nav-link.show,
.navbar.bg-success .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-success .navbar-toggler {
  color: #e2faee;
  border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-success .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar.bg-success .navbar-text {
  color: #e2faee;
}
.navbar.bg-success .navbar-text a {
  color: #fff;
}
.navbar.bg-success .navbar-text a:hover, .navbar.bg-success .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-success hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-info {
  background-color: #00cfdd !important;
  color: #d4f7f9;
}
.navbar.bg-info .navbar-brand,
.navbar.bg-info .navbar-brand a {
  color: #fff;
}
.navbar.bg-info .navbar-brand:hover, .navbar.bg-info .navbar-brand:focus,
.navbar.bg-info .navbar-brand a:hover,
.navbar.bg-info .navbar-brand a:focus {
  color: #fff;
}
.navbar.bg-info .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-info .navbar-search-wrapper .search-input {
  color: #d4f7f9;
}
.navbar.bg-info .search-input-wrapper .search-input,
.navbar.bg-info .search-input-wrapper .search-toggler {
  background-color: #00cfdd !important;
  color: #d4f7f9;
}
.navbar.bg-info .navbar-nav > .nav-link,
.navbar.bg-info .navbar-nav > .nav-item > .nav-link,
.navbar.bg-info .navbar-nav > .nav > .nav-item > .nav-link {
  color: #d4f7f9;
}
.navbar.bg-info .navbar-nav > .nav-link:hover, .navbar.bg-info .navbar-nav > .nav-link:focus,
.navbar.bg-info .navbar-nav > .nav-item > .nav-link:hover,
.navbar.bg-info .navbar-nav > .nav-item > .nav-link:focus,
.navbar.bg-info .navbar-nav > .nav > .nav-item > .nav-link:hover,
.navbar.bg-info .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #fff;
}
.navbar.bg-info .navbar-nav > .nav-link.disabled,
.navbar.bg-info .navbar-nav > .nav-item > .nav-link.disabled,
.navbar.bg-info .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #7fe7ee !important;
}
.navbar.bg-info .navbar-nav .show > .nav-link,
.navbar.bg-info .navbar-nav .active > .nav-link,
.navbar.bg-info .navbar-nav .nav-link.show,
.navbar.bg-info .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-info .navbar-toggler {
  color: #d4f7f9;
  border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-info .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar.bg-info .navbar-text {
  color: #d4f7f9;
}
.navbar.bg-info .navbar-text a {
  color: #fff;
}
.navbar.bg-info .navbar-text a:hover, .navbar.bg-info .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-info hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-warning {
  background-color: #fdac41 !important;
  color: #fff6ea;
}
.navbar.bg-warning .navbar-brand,
.navbar.bg-warning .navbar-brand a {
  color: #fff;
}
.navbar.bg-warning .navbar-brand:hover, .navbar.bg-warning .navbar-brand:focus,
.navbar.bg-warning .navbar-brand a:hover,
.navbar.bg-warning .navbar-brand a:focus {
  color: #fff;
}
.navbar.bg-warning .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-warning .navbar-search-wrapper .search-input {
  color: #fff6ea;
}
.navbar.bg-warning .search-input-wrapper .search-input,
.navbar.bg-warning .search-input-wrapper .search-toggler {
  background-color: #fdac41 !important;
  color: #fff6ea;
}
.navbar.bg-warning .navbar-nav > .nav-link,
.navbar.bg-warning .navbar-nav > .nav-item > .nav-link,
.navbar.bg-warning .navbar-nav > .nav > .nav-item > .nav-link {
  color: #fff6ea;
}
.navbar.bg-warning .navbar-nav > .nav-link:hover, .navbar.bg-warning .navbar-nav > .nav-link:focus,
.navbar.bg-warning .navbar-nav > .nav-item > .nav-link:hover,
.navbar.bg-warning .navbar-nav > .nav-item > .nav-link:focus,
.navbar.bg-warning .navbar-nav > .nav > .nav-item > .nav-link:hover,
.navbar.bg-warning .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #fff;
}
.navbar.bg-warning .navbar-nav > .nav-link.disabled,
.navbar.bg-warning .navbar-nav > .nav-item > .nav-link.disabled,
.navbar.bg-warning .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #fed8a6 !important;
}
.navbar.bg-warning .navbar-nav .show > .nav-link,
.navbar.bg-warning .navbar-nav .active > .nav-link,
.navbar.bg-warning .navbar-nav .nav-link.show,
.navbar.bg-warning .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-warning .navbar-toggler {
  color: #fff6ea;
  border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-warning .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar.bg-warning .navbar-text {
  color: #fff6ea;
}
.navbar.bg-warning .navbar-text a {
  color: #fff;
}
.navbar.bg-warning .navbar-text a:hover, .navbar.bg-warning .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-warning hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-danger {
  background-color: #ff5b5c !important;
  color: #ffe1e2;
}
.navbar.bg-danger .navbar-brand,
.navbar.bg-danger .navbar-brand a {
  color: #fff;
}
.navbar.bg-danger .navbar-brand:hover, .navbar.bg-danger .navbar-brand:focus,
.navbar.bg-danger .navbar-brand a:hover,
.navbar.bg-danger .navbar-brand a:focus {
  color: #fff;
}
.navbar.bg-danger .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-danger .navbar-search-wrapper .search-input {
  color: #ffe1e2;
}
.navbar.bg-danger .search-input-wrapper .search-input,
.navbar.bg-danger .search-input-wrapper .search-toggler {
  background-color: #ff5b5c !important;
  color: #ffe1e2;
}
.navbar.bg-danger .navbar-nav > .nav-link,
.navbar.bg-danger .navbar-nav > .nav-item > .nav-link,
.navbar.bg-danger .navbar-nav > .nav > .nav-item > .nav-link {
  color: #ffe1e2;
}
.navbar.bg-danger .navbar-nav > .nav-link:hover, .navbar.bg-danger .navbar-nav > .nav-link:focus,
.navbar.bg-danger .navbar-nav > .nav-item > .nav-link:hover,
.navbar.bg-danger .navbar-nav > .nav-item > .nav-link:focus,
.navbar.bg-danger .navbar-nav > .nav > .nav-item > .nav-link:hover,
.navbar.bg-danger .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #fff;
}
.navbar.bg-danger .navbar-nav > .nav-link.disabled,
.navbar.bg-danger .navbar-nav > .nav-item > .nav-link.disabled,
.navbar.bg-danger .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #ffabac !important;
}
.navbar.bg-danger .navbar-nav .show > .nav-link,
.navbar.bg-danger .navbar-nav .active > .nav-link,
.navbar.bg-danger .navbar-nav .nav-link.show,
.navbar.bg-danger .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-danger .navbar-toggler {
  color: #ffe1e2;
  border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-danger .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar.bg-danger .navbar-text {
  color: #ffe1e2;
}
.navbar.bg-danger .navbar-text a {
  color: #fff;
}
.navbar.bg-danger .navbar-text a:hover, .navbar.bg-danger .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-danger hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-dark {
  background-color: #717784 !important;
  color: #e1e2e5;
}
.navbar.bg-dark .navbar-brand,
.navbar.bg-dark .navbar-brand a {
  color: #fff;
}
.navbar.bg-dark .navbar-brand:hover, .navbar.bg-dark .navbar-brand:focus,
.navbar.bg-dark .navbar-brand a:hover,
.navbar.bg-dark .navbar-brand a:focus {
  color: #fff;
}
.navbar.bg-dark .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-dark .navbar-search-wrapper .search-input {
  color: #e1e2e5;
}
.navbar.bg-dark .search-input-wrapper .search-input,
.navbar.bg-dark .search-input-wrapper .search-toggler {
  background-color: #717784 !important;
  color: #e1e2e5;
}
.navbar.bg-dark .navbar-nav > .nav-link,
.navbar.bg-dark .navbar-nav > .nav-item > .nav-link,
.navbar.bg-dark .navbar-nav > .nav > .nav-item > .nav-link {
  color: #e1e2e5;
}
.navbar.bg-dark .navbar-nav > .nav-link:hover, .navbar.bg-dark .navbar-nav > .nav-link:focus,
.navbar.bg-dark .navbar-nav > .nav-item > .nav-link:hover,
.navbar.bg-dark .navbar-nav > .nav-item > .nav-link:focus,
.navbar.bg-dark .navbar-nav > .nav > .nav-item > .nav-link:hover,
.navbar.bg-dark .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #fff;
}
.navbar.bg-dark .navbar-nav > .nav-link.disabled,
.navbar.bg-dark .navbar-nav > .nav-item > .nav-link.disabled,
.navbar.bg-dark .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #b4b7be !important;
}
.navbar.bg-dark .navbar-nav .show > .nav-link,
.navbar.bg-dark .navbar-nav .active > .nav-link,
.navbar.bg-dark .navbar-nav .nav-link.show,
.navbar.bg-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-dark .navbar-toggler {
  color: #e1e2e5;
  border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar.bg-dark .navbar-text {
  color: #e1e2e5;
}
.navbar.bg-dark .navbar-text a {
  color: #fff;
}
.navbar.bg-dark .navbar-text a:hover, .navbar.bg-dark .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-dark hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-gray {
  background-color: white !important;
  color: #666666;
}
.navbar.bg-gray .navbar-brand,
.navbar.bg-gray .navbar-brand a {
  color: #000;
}
.navbar.bg-gray .navbar-brand:hover, .navbar.bg-gray .navbar-brand:focus,
.navbar.bg-gray .navbar-brand a:hover,
.navbar.bg-gray .navbar-brand a:focus {
  color: #000;
}
.navbar.bg-gray .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-gray .navbar-search-wrapper .search-input {
  color: #666666;
}
.navbar.bg-gray .search-input-wrapper .search-input,
.navbar.bg-gray .search-input-wrapper .search-toggler {
  background-color: rgba(255, 255, 255, 0.75) !important;
  color: #666666;
}
.navbar.bg-gray .navbar-nav > .nav-link,
.navbar.bg-gray .navbar-nav > .nav-item > .nav-link,
.navbar.bg-gray .navbar-nav > .nav > .nav-item > .nav-link {
  color: #666666;
}
.navbar.bg-gray .navbar-nav > .nav-link:hover, .navbar.bg-gray .navbar-nav > .nav-link:focus,
.navbar.bg-gray .navbar-nav > .nav-item > .nav-link:hover,
.navbar.bg-gray .navbar-nav > .nav-item > .nav-link:focus,
.navbar.bg-gray .navbar-nav > .nav > .nav-item > .nav-link:hover,
.navbar.bg-gray .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #000;
}
.navbar.bg-gray .navbar-nav > .nav-link.disabled,
.navbar.bg-gray .navbar-nav > .nav-item > .nav-link.disabled,
.navbar.bg-gray .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #a3a3a3 !important;
}
.navbar.bg-gray .navbar-nav .show > .nav-link,
.navbar.bg-gray .navbar-nav .active > .nav-link,
.navbar.bg-gray .navbar-nav .nav-link.show,
.navbar.bg-gray .navbar-nav .nav-link.active {
  color: #000;
}
.navbar.bg-gray .navbar-toggler {
  color: #666666;
  border-color: rgba(0, 0, 0, 0.075);
}
.navbar.bg-gray .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#637ba9' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar.bg-gray .navbar-text {
  color: #666666;
}
.navbar.bg-gray .navbar-text a {
  color: #000;
}
.navbar.bg-gray .navbar-text a:hover, .navbar.bg-gray .navbar-text a:focus {
  color: #000;
}
.navbar.bg-gray hr {
  border-color: rgba(0, 0, 0, 0.075);
}

.navbar.bg-white {
  background-color: #fff !important;
  color: #a6a6a6;
}
.navbar.bg-white .navbar-brand,
.navbar.bg-white .navbar-brand a {
  color: #4e5155;
}
.navbar.bg-white .navbar-brand:hover, .navbar.bg-white .navbar-brand:focus,
.navbar.bg-white .navbar-brand a:hover,
.navbar.bg-white .navbar-brand a:focus {
  color: #4e5155;
}
.navbar.bg-white .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-white .navbar-search-wrapper .search-input {
  color: #a6a6a6;
}
.navbar.bg-white .search-input-wrapper .search-input,
.navbar.bg-white .search-input-wrapper .search-toggler {
  background-color: #fff !important;
  color: #a6a6a6;
}
.navbar.bg-white .navbar-nav > .nav-link,
.navbar.bg-white .navbar-nav > .nav-item > .nav-link,
.navbar.bg-white .navbar-nav > .nav > .nav-item > .nav-link {
  color: #a6a6a6;
}
.navbar.bg-white .navbar-nav > .nav-link:hover, .navbar.bg-white .navbar-nav > .nav-link:focus,
.navbar.bg-white .navbar-nav > .nav-item > .nav-link:hover,
.navbar.bg-white .navbar-nav > .nav-item > .nav-link:focus,
.navbar.bg-white .navbar-nav > .nav > .nav-item > .nav-link:hover,
.navbar.bg-white .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #4e5155;
}
.navbar.bg-white .navbar-nav > .nav-link.disabled,
.navbar.bg-white .navbar-nav > .nav-item > .nav-link.disabled,
.navbar.bg-white .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #cacaca !important;
}
.navbar.bg-white .navbar-nav .show > .nav-link,
.navbar.bg-white .navbar-nav .active > .nav-link,
.navbar.bg-white .navbar-nav .nav-link.show,
.navbar.bg-white .navbar-nav .nav-link.active {
  color: #4e5155;
}
.navbar.bg-white .navbar-toggler {
  color: #a6a6a6;
  border-color: rgba(78, 81, 85, 0.075);
}
.navbar.bg-white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#637ba9' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar.bg-white .navbar-text {
  color: #a6a6a6;
}
.navbar.bg-white .navbar-text a {
  color: #4e5155;
}
.navbar.bg-white .navbar-text a:hover, .navbar.bg-white .navbar-text a:focus {
  color: #4e5155;
}
.navbar.bg-white hr {
  border-color: rgba(78, 81, 85, 0.075);
}

.navbar.bg-light {
  background-color: #434c5f !important;
  color: #a1b0cb;
}
.navbar.bg-light .navbar-brand,
.navbar.bg-light .navbar-brand a {
  color: #d8deea;
}
.navbar.bg-light .navbar-brand:hover, .navbar.bg-light .navbar-brand:focus,
.navbar.bg-light .navbar-brand a:hover,
.navbar.bg-light .navbar-brand a:focus {
  color: #d8deea;
}
.navbar.bg-light .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-light .navbar-search-wrapper .search-input {
  color: #a1b0cb;
}
.navbar.bg-light .search-input-wrapper .search-input,
.navbar.bg-light .search-input-wrapper .search-toggler {
  background-color: #434c5f !important;
  color: #a1b0cb;
}
.navbar.bg-light .navbar-nav > .nav-link,
.navbar.bg-light .navbar-nav > .nav-item > .nav-link,
.navbar.bg-light .navbar-nav > .nav > .nav-item > .nav-link {
  color: #a1b0cb;
}
.navbar.bg-light .navbar-nav > .nav-link:hover, .navbar.bg-light .navbar-nav > .nav-link:focus,
.navbar.bg-light .navbar-nav > .nav-item > .nav-link:hover,
.navbar.bg-light .navbar-nav > .nav-item > .nav-link:focus,
.navbar.bg-light .navbar-nav > .nav > .nav-item > .nav-link:hover,
.navbar.bg-light .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #d8deea;
}
.navbar.bg-light .navbar-nav > .nav-link.disabled,
.navbar.bg-light .navbar-nav > .nav-item > .nav-link.disabled,
.navbar.bg-light .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #7b88a0 !important;
}
.navbar.bg-light .navbar-nav .show > .nav-link,
.navbar.bg-light .navbar-nav .active > .nav-link,
.navbar.bg-light .navbar-nav .nav-link.show,
.navbar.bg-light .navbar-nav .nav-link.active {
  color: #d8deea;
}
.navbar.bg-light .navbar-toggler {
  color: #a1b0cb;
  border-color: rgba(216, 222, 234, 0.15);
}
.navbar.bg-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#637ba9' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar.bg-light .navbar-text {
  color: #a1b0cb;
}
.navbar.bg-light .navbar-text a {
  color: #d8deea;
}
.navbar.bg-light .navbar-text a:hover, .navbar.bg-light .navbar-text a:focus {
  color: #d8deea;
}
.navbar.bg-light hr {
  border-color: rgba(216, 222, 234, 0.15);
}

.navbar.bg-lighter {
  background-color: #687fac !important;
  color: #a1b0cb;
}
.navbar.bg-lighter .navbar-brand,
.navbar.bg-lighter .navbar-brand a {
  color: #d8deea;
}
.navbar.bg-lighter .navbar-brand:hover, .navbar.bg-lighter .navbar-brand:focus,
.navbar.bg-lighter .navbar-brand a:hover,
.navbar.bg-lighter .navbar-brand a:focus {
  color: #d8deea;
}
.navbar.bg-lighter .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-lighter .navbar-search-wrapper .search-input {
  color: #a1b0cb;
}
.navbar.bg-lighter .search-input-wrapper .search-input,
.navbar.bg-lighter .search-input-wrapper .search-toggler {
  background-color: #687fac !important;
  color: #a1b0cb;
}
.navbar.bg-lighter .navbar-nav > .nav-link,
.navbar.bg-lighter .navbar-nav > .nav-item > .nav-link,
.navbar.bg-lighter .navbar-nav > .nav > .nav-item > .nav-link {
  color: #a1b0cb;
}
.navbar.bg-lighter .navbar-nav > .nav-link:hover, .navbar.bg-lighter .navbar-nav > .nav-link:focus,
.navbar.bg-lighter .navbar-nav > .nav-item > .nav-link:hover,
.navbar.bg-lighter .navbar-nav > .nav-item > .nav-link:focus,
.navbar.bg-lighter .navbar-nav > .nav > .nav-item > .nav-link:hover,
.navbar.bg-lighter .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #d8deea;
}
.navbar.bg-lighter .navbar-nav > .nav-link.disabled,
.navbar.bg-lighter .navbar-nav > .nav-item > .nav-link.disabled,
.navbar.bg-lighter .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #8a9cbf !important;
}
.navbar.bg-lighter .navbar-nav .show > .nav-link,
.navbar.bg-lighter .navbar-nav .active > .nav-link,
.navbar.bg-lighter .navbar-nav .nav-link.show,
.navbar.bg-lighter .navbar-nav .nav-link.active {
  color: #d8deea;
}
.navbar.bg-lighter .navbar-toggler {
  color: #a1b0cb;
  border-color: rgba(216, 222, 234, 0.15);
}
.navbar.bg-lighter .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#637ba9' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar.bg-lighter .navbar-text {
  color: #a1b0cb;
}
.navbar.bg-lighter .navbar-text a {
  color: #d8deea;
}
.navbar.bg-lighter .navbar-text a:hover, .navbar.bg-lighter .navbar-text a:focus {
  color: #d8deea;
}
.navbar.bg-lighter hr {
  border-color: rgba(216, 222, 234, 0.15);
}

.footer.bg-secondary {
  background-color: #69809a !important;
  color: #e0e5ea;
}
.footer.bg-secondary .footer-link {
  color: #e0e5ea;
}
.footer.bg-secondary .footer-link:hover, .footer.bg-secondary .footer-link:focus {
  color: #fff;
}
.footer.bg-secondary .footer-link.disabled {
  color: #b0bdca !important;
}
.footer.bg-secondary .footer-text {
  color: #fff;
}
.footer.bg-secondary .show > .footer-link,
.footer.bg-secondary .active > .footer-link,
.footer.bg-secondary .footer-link.show,
.footer.bg-secondary .footer-link.active {
  color: #fff;
}
.footer.bg-secondary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-success {
  background-color: #39da8a !important;
  color: #e2faee;
}
.footer.bg-success .footer-link {
  color: #e2faee;
}
.footer.bg-success .footer-link:hover, .footer.bg-success .footer-link:focus {
  color: #fff;
}
.footer.bg-success .footer-link.disabled {
  color: #9eedc6 !important;
}
.footer.bg-success .footer-text {
  color: #fff;
}
.footer.bg-success .show > .footer-link,
.footer.bg-success .active > .footer-link,
.footer.bg-success .footer-link.show,
.footer.bg-success .footer-link.active {
  color: #fff;
}
.footer.bg-success hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-info {
  background-color: #00cfdd !important;
  color: #d4f7f9;
}
.footer.bg-info .footer-link {
  color: #d4f7f9;
}
.footer.bg-info .footer-link:hover, .footer.bg-info .footer-link:focus {
  color: #fff;
}
.footer.bg-info .footer-link.disabled {
  color: #7fe7ee !important;
}
.footer.bg-info .footer-text {
  color: #fff;
}
.footer.bg-info .show > .footer-link,
.footer.bg-info .active > .footer-link,
.footer.bg-info .footer-link.show,
.footer.bg-info .footer-link.active {
  color: #fff;
}
.footer.bg-info hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-warning {
  background-color: #fdac41 !important;
  color: #fff6ea;
}
.footer.bg-warning .footer-link {
  color: #fff6ea;
}
.footer.bg-warning .footer-link:hover, .footer.bg-warning .footer-link:focus {
  color: #fff;
}
.footer.bg-warning .footer-link.disabled {
  color: #fed8a6 !important;
}
.footer.bg-warning .footer-text {
  color: #fff;
}
.footer.bg-warning .show > .footer-link,
.footer.bg-warning .active > .footer-link,
.footer.bg-warning .footer-link.show,
.footer.bg-warning .footer-link.active {
  color: #fff;
}
.footer.bg-warning hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-danger {
  background-color: #ff5b5c !important;
  color: #ffe1e2;
}
.footer.bg-danger .footer-link {
  color: #ffe1e2;
}
.footer.bg-danger .footer-link:hover, .footer.bg-danger .footer-link:focus {
  color: #fff;
}
.footer.bg-danger .footer-link.disabled {
  color: #ffabac !important;
}
.footer.bg-danger .footer-text {
  color: #fff;
}
.footer.bg-danger .show > .footer-link,
.footer.bg-danger .active > .footer-link,
.footer.bg-danger .footer-link.show,
.footer.bg-danger .footer-link.active {
  color: #fff;
}
.footer.bg-danger hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-dark {
  background-color: #717784 !important;
  color: #e1e2e5;
}
.footer.bg-dark .footer-link {
  color: #e1e2e5;
}
.footer.bg-dark .footer-link:hover, .footer.bg-dark .footer-link:focus {
  color: #fff;
}
.footer.bg-dark .footer-link.disabled {
  color: #b4b7be !important;
}
.footer.bg-dark .footer-text {
  color: #fff;
}
.footer.bg-dark .show > .footer-link,
.footer.bg-dark .active > .footer-link,
.footer.bg-dark .footer-link.show,
.footer.bg-dark .footer-link.active {
  color: #fff;
}
.footer.bg-dark hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-gray {
  background-color: white !important;
  color: #666666;
}
.footer.bg-gray .footer-link {
  color: #666666;
}
.footer.bg-gray .footer-link:hover, .footer.bg-gray .footer-link:focus {
  color: #000;
}
.footer.bg-gray .footer-link.disabled {
  color: #a3a3a3 !important;
}
.footer.bg-gray .footer-text {
  color: #000;
}
.footer.bg-gray .show > .footer-link,
.footer.bg-gray .active > .footer-link,
.footer.bg-gray .footer-link.show,
.footer.bg-gray .footer-link.active {
  color: #000;
}
.footer.bg-gray hr {
  border-color: rgba(0, 0, 0, 0.075);
}

.footer.bg-white {
  background-color: #fff !important;
  color: #a6a6a6;
}
.footer.bg-white .footer-link {
  color: #a6a6a6;
}
.footer.bg-white .footer-link:hover, .footer.bg-white .footer-link:focus {
  color: #4e5155;
}
.footer.bg-white .footer-link.disabled {
  color: #cacaca !important;
}
.footer.bg-white .footer-text {
  color: #4e5155;
}
.footer.bg-white .show > .footer-link,
.footer.bg-white .active > .footer-link,
.footer.bg-white .footer-link.show,
.footer.bg-white .footer-link.active {
  color: #4e5155;
}
.footer.bg-white hr {
  border-color: rgba(78, 81, 85, 0.075);
}

.footer.bg-light {
  background-color: #434c5f !important;
  color: #a1b0cb;
}
.footer.bg-light .footer-link {
  color: #a1b0cb;
}
.footer.bg-light .footer-link:hover, .footer.bg-light .footer-link:focus {
  color: #d8deea;
}
.footer.bg-light .footer-link.disabled {
  color: #7b88a0 !important;
}
.footer.bg-light .footer-text {
  color: #d8deea;
}
.footer.bg-light .show > .footer-link,
.footer.bg-light .active > .footer-link,
.footer.bg-light .footer-link.show,
.footer.bg-light .footer-link.active {
  color: #d8deea;
}
.footer.bg-light hr {
  border-color: rgba(216, 222, 234, 0.15);
}

.footer.bg-lighter {
  background-color: #687fac !important;
  color: #a1b0cb;
}
.footer.bg-lighter .footer-link {
  color: #a1b0cb;
}
.footer.bg-lighter .footer-link:hover, .footer.bg-lighter .footer-link:focus {
  color: #d8deea;
}
.footer.bg-lighter .footer-link.disabled {
  color: #8a9cbf !important;
}
.footer.bg-lighter .footer-text {
  color: #d8deea;
}
.footer.bg-lighter .show > .footer-link,
.footer.bg-lighter .active > .footer-link,
.footer.bg-lighter .footer-link.show,
.footer.bg-lighter .footer-link.active {
  color: #d8deea;
}
.footer.bg-lighter hr {
  border-color: rgba(216, 222, 234, 0.15);
}
